import { DeviceService } from './../../../../../services/device.service';
import { LivekitService } from './../../../../../livekit/services/livekit.service';
import { PanelManagerService } from "src/app/core/services/panel-manager.service";
import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ViewChild,
  Input,
  ElementRef,
} from "@angular/core";
import { RoomService } from "src/app/core/services/room.service";
import { ScrollPanel } from "primeng/scrollpanel";
import { IndexedDBService } from "src/app/core/services/indexed-db.service";

@Component({
  selector: "app-room-chat-microphone-settings",
  templateUrl: "./microphone-settings.component.html",
  styleUrls: ["./microphone-settings.component.scss"],
})
export class RoomChatButtonsMicrophoneSettingsComponent
  implements OnInit, AfterViewInit
{
  @Input() isDisplay: boolean = false;

  @ViewChild("micSettingsControl") micSettingsControl: ScrollPanel;
  @ViewChild("close", { static: false }) close: ElementRef;

  // isMicEnabled: boolean = true
  constructor(
    public roomService: RoomService,
    private indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public livekitService:LivekitService,
    public deviceService:DeviceService
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.livekitService.handleDevicesChanged()
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
     if (this.close && this.close.nativeElement.contains(event.target)) {
      this.panelManagerService.showMicSettings = false;
    } else if (this.micSettingsControl && this.micSettingsControl.el.nativeElement.contains(event.target)) {
      this.panelManagerService.showMicSettings = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }


  toggleMuteAudios(){
    if (!this.roomService.allParticipantAreMuted) {
      this.roomService.muteAllAudioElements();
    }
    else{
      this.roomService.unmuteAllAudioElements();
    }
  }

  toggleMyAudioMute(){
    if (!this.livekitService.allSenderAudioTrackAreMuted) {
      if (this.livekitService.isShareMusic) {
        this.livekitService.muteShareAudio();
      }
      this.livekitService.room.localParticipant.setMicrophoneEnabled(false);
      this.livekitService.allSenderAudioTrackAreMuted = true;
      this.livekitService.isMicEnabled = false
    }
    else{
      if (this.livekitService.isShareMusic) {
        this.livekitService.unmuteShareAudio()
      }
      this.livekitService.room.localParticipant.setMicrophoneEnabled(true);
      this.livekitService.allSenderAudioTrackAreMuted = false;
      this.livekitService.isMicEnabled = true
    }
  }
  toggleMicForMuteUnMute(){
   let isMicEnabled = this.livekitService.room.localParticipant.isMicrophoneEnabled
    if (isMicEnabled) {
      this.livekitService.room.localParticipant.setMicrophoneEnabled(false);
      this.livekitService.isMicEnabled = false
    }
    else{
      this.livekitService.room.localParticipant.setMicrophoneEnabled(true);
      this.livekitService.isMicEnabled = true
    }
  }
}
