export const environment = {
  production: true,
  appVersion: '2.49.10-69afdb4b',
  checkVersion: true,
  builtAppHash: '69afdb4b6b21bba74e10880bc74c5eb09695d3bc',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://test.zumti.com/',
  apiUrl: 'https://test-be.zumti.com/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '572001134774-bveghvbh9jda001oge723ujj45jbrihq.apps.googleusercontent.com',
  livekitUrl:   'wss://live.zumti.com',
  customerName: 'zumticom',
  s3AccountUrl: 'https://zumticom.s3.eu-central-1.amazonaws.com/v2/',
};