<main-menu-bar-panel
    [style.display]="!(deviceService.isMobile && panelManagerService.roomPageDisplay) ? 'inherit' : 'none'">
</main-menu-bar-panel>

<div class="content-div" #contentDiv>

    
    <div *ngIf="deviceService.isMobile" class="h-100">
        <p-sidebar [(visible)]="panelManagerService.mainMenuVisible" position="left" [showCloseIcon]="false"
            [style]="{width:'75%', 'max-width':'300px', 'top': '40px', padding: '0', height: '100%'}"
            [baseZIndex]="10000" [blockScroll]="true">
            <div>
                <app-main-menu class="main-menu h-100"></app-main-menu>
            </div>
        </p-sidebar>

        <div
            [style.display]="panelManagerService.currentMobileActivePanelId === (constants.mainSwiperTag +''+ mainPanels.RoomList)?'block':'none'">
            <app-room-list class="room-list h-100 w-100"></app-room-list>
        </div>

        <div
            [style.display]="panelManagerService.currentMobileActivePanelId === (constants.mainSwiperTag +''+ mainPanels.FriendList)?'block':'none'">
            <app-friend-list class="friend-list h-100"
                *ngIf="(panelManagerService.loadedFriends | async)"></app-friend-list>
        </div>

        <div *ngIf="panelManagerService.roomOpened"
            [style.display]="panelManagerService.currentMobileActivePanelId === (constants.mainSwiperTag +''+ mainPanels.RoomPage)?'block':'none'">
            <app-room-chat class="room-page h-100"
                [roomInfo]="roomService.currentRoom"></app-room-chat>

            <p-sidebar [(visible)]="panelManagerService.showedRoomUserList" position="bottom" [baseZIndex]="10000"
                [showCloseIcon]="false" [blockScroll]="true">
                <div class="user-list" [ngStyle]="roomService.userListBackground">
                    <div class="user-list-top">
                        <div class="user-type middle" [ngClass]="roomService.selectedUserListType === 1 ? 'active' : '' " (click)="listOnlineUsers()">
                            online
                        </div>  
                        <div class="user-type left" [ngClass]="roomService.selectedUserListType === 0 ? 'active' : '' " (click)="listVIPUsers()">
                            vip
                        </div>
                        <div class="user-type right" [ngClass]="{'active': roomService.selectedUserListType === 2, 'pulse': roomService.isThereHandRequest}" (click)="listHandRequest()">
                            talep
                        </div>   
                    </div>

                    <div class="user-list-content">
                        <app-room-user-list [roomUserList]="roomService.roomUserList"></app-room-user-list>
                        <div>
                            <div class="pagination-elements">
                                <div tooltipPosition="bottom" pTooltip="{{ 'İlk Liste' | translate }}" class="page-element w-action" 
                                [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"  
                                (click)="firstPage()">
                                    <span>First</span>
                                </div>
                                <div class="page-element w-action" tooltipPosition="bottom" pTooltip="{{ 'Bir Önceki Liste' | translate }}" 
                                [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"  
                                >
                                    <fa-icon class="prev" (click)="previousPageUserList()" [icon]="faChevronLeft"></fa-icon>
                                </div>
                                <div class="page-element index"  [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}" >
                                    <span>{{roomService.pageIndex}}</span>
                                </div>
                                <div class="page-element w-action" tooltipPosition="bottom" pTooltip="{{ 'Bir Sonraki Liste' | translate }}"
                                [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"  
                                >
                                    <fa-icon class="prev" (click)="nextPageUserList()" [icon]="faChevronRight"></fa-icon>
                                </div>
                                <div class="page-element w-action" tooltipPosition="bottom" pTooltip="{{ 'Son Liste' | translate }}" (click)="lastPage()"
                                [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"  
                                > 
                                    <span>Last</span>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </p-sidebar>
        </div>

        <p-sidebar [(visible)]="panelManagerService.showedFriendList" position="bottom" [baseZIndex]="10000"
            [showCloseIcon]="false" [blockScroll]="true">
            <app-friend-list ></app-friend-list>
        </p-sidebar>

        <p-sidebar [(visible)]="panelManagerService.showedIncommingAnotherUserMessagePanel" position="bottom" [baseZIndex]="10000"
        [showCloseIcon]="false" [blockScroll]="true">
        <app-incoming-messages-list ></app-incoming-messages-list>
        </p-sidebar>


        <p-sidebar [(visible)]="panelManagerService.adminOpened" position="bottom" [baseZIndex]="10000"
            [showCloseIcon]="false" [blockScroll]="true">
            <div *ngIf="panelManagerService.adminModuleLoaded" class="admin">
                <ng-container *ngComponentOutlet="panelManagerService.adminComponent">
                </ng-container>
            </div>
        </p-sidebar>

        <p-sidebar [(visible)]="panelManagerService.roomRecordingPanelDisplay" position="bottom" [baseZIndex]="10000"
        [showCloseIcon]="false" [blockScroll]="true">
        <div class="admin">
            <app-room-records></app-room-records>
        </div>
    </p-sidebar>

        <ng-template app-room-camera-host></ng-template>
        <ng-template app-room-screen-share-host></ng-template>


        <p-sidebar [(visible)]="panelManagerService.showedP2p" position="bottom" [baseZIndex]="10000"
            [showCloseIcon]="false" [blockScroll]="true">
            <ng-template app-p2p-chat-host></ng-template>
        </p-sidebar>
        <!-- <ng-template app-p2p-chat-host></ng-template> -->
    </div>


    <div *ngIf="!deviceService.isMobile">
        <app-room *ngIf="panelManagerService.roomOpened" [room]="roomService?.currentRoom"
            [style.display]="((panelManagerService.activeMainPanel === mainPanels.RoomPage || panelManagerService.roomPageDisplay===true) && !panelManagerService.isHiddenRoom) ? 'block' : 'none'"
            (messageEvent)="roomPageEvent($event)" (click)="panelManagerService.focusPanel(mainPanels.RoomPage)"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.RoomPage? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
        </app-room>

        <app-room-list class="room-list h-100"
            [style.display]="panelManagerService.activeMainPanel===mainPanels.RoomList? 'block':'none'"
            (click)="panelManagerService.focusPanel(mainPanels.RoomList)"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.RoomList? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
        </app-room-list>

        <app-friend-list class="friend-list h-100" *ngIf="(panelManagerService.loadedFriends | async)"
            [style.display]="panelManagerService.activeMainPanel===mainPanels.FriendList? 'block':'none'"
            (click)="panelManagerService.focusPanel(mainPanels.FriendList)"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.FriendList? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
        </app-friend-list>

        <app-main-menu class="main-menu h-100"
            [style.display]="panelManagerService.activeMainPanel===mainPanels.MainMenu? 'block':'none'"
            (click)="panelManagerService.focusPanel(mainPanels.MainMenu)"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.MainMenu? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
        </app-main-menu>

        <div *ngIf="panelManagerService.adminModuleLoaded" class="admin" [inBounds]="false" [ngDraggable]="false"
            [style.display]="panelManagerService.adminPageDisplay===true? 'block':'none'"
            [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [ngResizable]="true"
            (click)="panelManagerService.focusPanel(mainPanels.Admin)" [rzMinWidth]="460"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.Admin? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
            <ng-container *ngComponentOutlet="panelManagerService.adminComponent">
            </ng-container>
        </div>
        
        <div *ngIf="panelManagerService.roomRecordingPanelDisplay" class="admin" [inBounds]="false" [ngDraggable]="false"
            [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [ngResizable]="true"
             [rzMinWidth]="460"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.Admin? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
            <app-room-records></app-room-records>
        </div>
        <!-- 
        <app-admin class="admin" *ngIf="panelManagerService.adminOpened"
            [style.display]="panelManagerService.adminPageDisplay===true? 'block':'none'"
            (click)="panelManagerService.focusPanel(mainPanels.Admin)"
            [style.z-index]="panelManagerService.focusedPanel===mainPanels.Admin? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
        </app-admin>  -->

        <ng-template app-p2p-chat-host></ng-template>

        <ng-template app-room-camera-host></ng-template> 
        <ng-template app-room-screen-share-host></ng-template>
    </div>
    <div style="display: none;" id="participants-area"></div>
</div>


<app-room-password-entry *ngIf="panelManagerService.roomPasswordPanelIsOpened">
</app-room-password-entry>

<app-room-second-nick-entry *ngIf="panelManagerService.roomSecondNickPanelIsOpened" (isSuccess)="isSuccess($event)">
</app-room-second-nick-entry>


<app-add-new-friend *ngIf="panelManagerService.addFriendPopupDisplay"></app-add-new-friend>

<app-show-blocked-friends *ngIf="panelManagerService.showBlockedFriendsPopupDisplay"></app-show-blocked-friends>

<app-change-font *ngIf="panelManagerService.changeFontPopupDisplay"></app-change-font>

<app-update-state-message *ngIf="panelManagerService.updateStateMessagePopupDisplay">
</app-update-state-message>

<app-update-user-name *ngIf="panelManagerService.updateUserNamePopupDisplay">
</app-update-user-name>

<app-control-of-recording *ngIf="panelManagerService.controlOfRecordingPopupDisplay">
</app-control-of-recording>

<app-send-folder-to-room *ngIf="panelManagerService.sendFolderToRoomPopupDisplay"></app-send-folder-to-room>
<app-send-docs-to-friend *ngIf="panelManagerService.sendFolderToFriendPopupDisplay" ></app-send-docs-to-friend>

<app-guest-user-warning *ngIf="panelManagerService.guestWarningPopupDisplay">
</app-guest-user-warning>

<app-phone-calling *ngIf="panelManagerService.phoneCallingPopupDisplay">
</app-phone-calling>

<app-account-deletion-request *ngIf="panelManagerService.accountDeletionPopupDisplay">
</app-account-deletion-request>

<app-room-user-list-ban *ngIf="panelManagerService.banPanelPopupDisplay"></app-room-user-list-ban>

<app-user-card *ngIf="panelManagerService.roomUserCardPopupDisplay"></app-user-card>
<app-room-info-card *ngIf="panelManagerService.roomInfoCardPopupDisplay"></app-room-info-card>

<app-p2p-chat-header-update-wallpaper *ngIf="panelManagerService.updateWallpaperPopupDisplay">
</app-p2p-chat-header-update-wallpaper>

<p-dialog [(visible)]="panelManagerService.profilePhotoPopupDisplay"
    *ngIf="panelManagerService.profilePhotoPopupDisplay" [modal]="true" [dismissableMask]="true"
    [baseZIndex]="100000000" [showHeader]="false" contentStyleClass="profile-photo-popup">
    <img [src]="indexedDBService.friendProfilePicture" />
</p-dialog>

<p-dialog class="message-image-display" [(visible)]="panelManagerService.roomSharedImagePopupDisplay"
    *ngIf="panelManagerService.roomSharedImagePopupDisplay" [modal]="true" [dismissableMask]="true"
    [baseZIndex]="100000000" [showHeader]="false" contentStyleClass="image-popup">
    <div class="image-container">
        <img [src]="indexedDBService.roomSharedImage" class="centered-image" />
    </div>
</p-dialog>

<div class="custom-dialog-ai" [ngDraggable]="true" [inBounds]="true" [bounds]="aiImageBounds" *ngIf="panelManagerService.roomAIImagePopupDisplay">
    <div class="robot-container">
        <button class="close-btn" (click)="hideAIAnimationImage()">&times;</button>
        <div class="draggable-area">
            <img id="robot" src="../../../../assets/images/ai-robot.png" alt="Robot" class="robot-image">
            <div id="eye-left" class="eye"></div>
            <div id="eye-right" class="eye"></div>
            <div id="mouth-panel" class="mouth"></div>
        </div>
    </div>
</div>

<div class="custom-dialog-ai-remaining-time" [ngDraggable]="true" [inBounds]="true" [bounds]="aiImageBounds" *ngIf="panelManagerService.roomAIChatRemainingTimePopupDisplay">
    <div class="remaining-time">
        <div class="draggable-area">
            <span *ngIf="roomService.remainingHours > 0">
                {{ roomService.remainingHours }}:{{ roomService.remainingMinutes < 10 ? '0' : '' }}{{ roomService.remainingMinutes }}:{{ roomService.remainingSeconds < 10 ? '0' : '' }}{{ roomService.remainingSeconds }}
              </span>
              <span *ngIf="roomService.remainingHours === 0">
                {{ roomService.remainingMinutes }}:{{ roomService.remainingSeconds < 10 ? '0' : '' }}{{ roomService.remainingSeconds }}
              </span>
        </div>
      </div>
</div>



<app-room-microphone-queue-accepting *ngIf="panelManagerService.roomMicrophoneAcceptPopupDisplay">
</app-room-microphone-queue-accepting>

<!-- <p-toast *ngIf="!deviceService.isMobile" position="bottom-right" key="online-message"></p-toast> -->
<!-- <p-toast *ngIf="deviceService.isMobile" position="top-center" key="online-message"></p-toast> -->
<!-- <p-toast position="top-center" key="connection-error"></p-toast>
<p-toast position="top-center" key="connection-success"></p-toast> -->

<app-image-cropper *ngIf="panelManagerService.imageCropperPopupDisplay"></app-image-cropper>
<app-room-list-back-image-cropper *ngIf="panelManagerService.roomLobiImageCropperPopupDisplay"></app-room-list-back-image-cropper>
<app-room-ai-image-cropper *ngIf="panelManagerService.roomAIImageCropperPopupDisplay"></app-room-ai-image-cropper>
<app-template-room-list-back-image-cropper  *ngIf="panelManagerService.roomAITemplateLobiImageCropperPopupDisplay"></app-template-room-list-back-image-cropper>
<app-template-owner-image-cropper *ngIf="panelManagerService.roomAITemplateOwnerImageCropperPopupDisplay"></app-template-owner-image-cropper>
<app-image-capture *ngIf="panelManagerService.imageCapturePopupDisplay"></app-image-capture>

<app-select-avatar *ngIf="panelManagerService.selectAvatarPopupDisplay"></app-select-avatar>

<app-room-chat-buttons-youtube class="youtube-panel" [ngStyle]="{'left': deviceService.isMobile ? youtubeWidth : ''}"
    *ngIf="panelManagerService.youtubePlayerPopupDisplay"></app-room-chat-buttons-youtube>

<app-invite-to-room *ngIf="panelManagerService.inviteToRoomPopupDisplay"></app-invite-to-room>

<app-permissions *ngIf="panelManagerService.permissionsDisplay" style="width: 100%;"></app-permissions>

<!-- <div *ngIf="roomService.connectionProblem" class="connection-problem">
    <span [innerHTML]="'internetconnectionproblemonroom' | translate"></span>
</div> -->

<router-outlet></router-outlet>


