import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UtilService} from './util.service';
import {Subject, Subscription} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {ServiceInformation} from './serviceinformation.service';
import {MessagePanelService} from './messagePanel.service';
import {ChatMessageService} from './chatMessage.service';
import {LocalizationService} from './localization.service';
import {DynamicMethodsSendService} from './dynamic-methods-send.service';
import {IndexedDBService} from './indexed-db.service';
import {catchError, map} from 'rxjs/operators';

import {
    BeforeEnterRoom,
    CurrentRoom,
    FavoriteRoom,
    MicrophoneJson,
    RecordRoomVideoType,
    Room,
    RoomCamera,
    RoomCameraCount,
    RoomCount,
    RoomInfo,
    RoomInfoEnum,
    RoomJson,
    RoomJsonData,
    RoomListType,
    RoomOnlineCount,
    RoomRecord,
    RoomSimpleInfo,
    UserListType
} from '../models/room';
import {
    BanInfo,
    BanMuteInfo,
    BanMuteKaraListeType,
    CheckBanResponse,
    RoomBanListInfo,
    RoomMuteListInfo,
    SiteBanListInfo
} from '../models/ban-mute-info';
import {
    BanOrMute,
    BanPlaces,
    BanTypes,
    BulkMessageType,
    ChatType,
    ContactType,
    MainPanels,
    MessageBubbleType,
    MessageSendingType,
    MessageType,
    MicrophoneState,
    WhoEnterRoomTypes
} from '../models/enums';
import {RoomUser, RoomUserContextMenuMode, UserListData, VipUserData} from '../models/room-user';
import {Constants, MessageSendingTypeImgUrl} from '../models/constants';
import {ChatService} from './chat.service';
import {ChatPanelProps} from '../models/chat-panel-props';
import {PanelManagerService} from './panel-manager.service';
import {StateIdList, UserStateType} from '../models/state';
import {TranslateService} from '@ngx-translate/core';
import {ChatMessage, FileMessageData, FileMessageUploadResponse} from '../models/chat-message';
import {DecodeJWT, UserFullInfo} from '../models/user';
import {RoomUserListService} from './room-user-list-service';
import {ConfirmationService} from 'primeng/api';
import {Title} from '@angular/platform-browser';
import {NatsService} from '../nats/services/nats.service';
import {OperationMessage} from '../models/operation-message';
import {environment} from '../../../environments/environment';
import {Nicktype} from '../models/nick-type';
import {LocalStorageService} from './local-storage.service';
import {Centrifuge} from 'centrifuge';
import {LivekitService} from '../livekit/services/livekit.service';
import jwt_decode from "jwt-decode";
import {EnvironmentVariableService} from './environment-variable.service';
import {ConnectionState, Track} from 'livekit-client';
import {DynamicMethodsService} from './dynamic-methods.service';
import {AckPolicy, connect, Events, JetStreamClient, JSONCodec, nanos, NatsConnection, StringCodec} from 'nats.ws';
import { DeviceService } from './device.service';
import { EnvironmentVariable } from '../models/environmentVariables';
import { RoleAccessService } from './role-access.service';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})

export class RoomService extends ServiceInformation implements OnDestroy {

    public subject: Subject<any>;
    private keepAfterNavigationChange = false;
    doc:string = ""
    userListBackground: object;
    jc = JSONCodec();
    sc = StringCodec();

    roomInfoChangedSub: Subject<any> = new Subject();
    roomInfoChanged = this.roomInfoChangedSub.asObservable();

    roomUserListChangedSource: Subject<any> = new Subject();
    roomUserListChanged = this.roomUserListChangedSource.asObservable();

    roomFileMessageReceivedSource:Subject<FileMessageData> = new Subject();
    roomFileMessageReceived = this.roomFileMessageReceivedSource.asObservable();

    micUserListLoadedSource: Subject<any> = new Subject();
    micUserListLoaded = this.micUserListLoadedSource.asObservable();

    micUserListRemovedSource: Subject<any> = new Subject();
    micUserListRemoved = this.micUserListRemovedSource.asObservable();

    micUserAddedSource: Subject<any> = new Subject();
    micUserAdded = this.micUserAddedSource.asObservable();

    vipUserListLoadedSource: Subject<any> = new Subject();
    vipUserListLoaded = this.vipUserListLoadedSource.asObservable();

    onlineUserListLoadedSource: Subject<any> = new Subject();
    onlineUserListLoaded = this.onlineUserListLoadedSource.asObservable();

    handUserListLoadedSource: Subject<any> = new Subject();
    handUserListLoaded = this.handUserListLoadedSource.asObservable();

    listOldMessagesSource: Subject<any> = new Subject();
    listOldMessages = this.listOldMessagesSource.asObservable();

    roomServerInfoDic = {}; // key:serverInfoId  value: RoomServerInfo
    roomServerInfoRoomRelation = {};// key: roomId  value: serverInfoId

    aiMessageSetToBubbleSource: Subject<ChatMessage> = new Subject();
    aiMessageSetToBubble = this.aiMessageSetToBubbleSource.asObservable();

    latexRenderedScrollTriggerSource : Subject<any> = new Subject();
    latexRenderedScrollTrigger = this.latexRenderedScrollTriggerSource.asObservable()

    oldmicrophoneJsonStruct: MicrophoneJson = new MicrophoneJson();

    isHighQualityMic = true;
    lastAudioDeviceIndex = 0;

    autoLogin: boolean = false;

    environmentVariable: EnvironmentVariable
    microphoneStateChangedSource: Subject<MicrophoneState> = new Subject();
    microphoneStateChanged = this.microphoneStateChangedSource.asObservable();

    roomCameraCountUpdatedSource: Subject<RoomCameraCount> = new Subject();
    roomCameraCountUpdated = this.roomCameraCountUpdatedSource.asObservable();

    roomAllMessagesRemovedSource: Subject<any> = new Subject();
    roomAllMessagesRemoved = this.roomAllMessagesRemovedSource.asObservable();

    favoriteRoomUpdatedSource: Subject<[number, string]> = new Subject();
    favoriteRoomUpdated = this.favoriteRoomUpdatedSource.asObservable();

    roomInfoUpdatedSource: Subject<CurrentRoom> = new Subject();
    roomInfoUpdated = this.roomInfoUpdatedSource.asObservable();

    roomUserListClearedSource: Subject<CurrentRoom> = new Subject();
    roomUserListCleared = this.roomUserListClearedSource.asObservable();

    roomOwnerChangedSource: Subject<number> = new Subject();
    roomOwnerChanged = this.roomOwnerChangedSource.asObservable();

    roomListDataClearedSource: Subject<any> = new Subject();
    roomListDataCleared = this.roomListDataClearedSource.asObservable();

    // TODO:CHECK_REMOVE
    changeRoomIsInActive: boolean = true;
    lastEnterRoomTime: Date = this.utilService.newDate();

    autoEnterWithSecondNick: boolean = false;

    beforeEnterRoomData: RoomInfo;
    viewingRoomInfo: RoomInfo = new RoomInfo();
    roomListRefreshInterval;
    heartbeatInterval;

    currentRoom: CurrentRoom = new CurrentRoom();
    favoriteRooms: Array<FavoriteRoom>;


    audioReceiverConferenceMuted = false;


    selectedRoomUser?: RoomUser;
    selectedRoomInfo?:RoomSimpleInfo;
    roomUserList: Array<RoomUser> = [];

    vipUserList:Array<RoomUser> = [];
    onlineUserList:Array<RoomUser> = [];
    handUserList:Array<RoomUser> = [];
    micUserList:Array<RoomUser> = [];


    roomCameraUserList: Array<RoomCamera> = [];
    cameraButtonClicked: boolean;

    chatPanelProperties = null;

    currentMessageSendingType = MessageSendingType.Normal;
    currentMessageChatType = ChatType.SingleChat;

    allParticipantAreMuted: boolean = false;
    messageSendingTypeImgUrl = MessageSendingTypeImgUrl.Normal;

    //CODE_TO_LIVE
    roomPasswordPanelResponseSubscription: Subscription;
    roomSecondNickPanelResponseSubscription: Subscription;


    roomCameraWatcherAddedSource: Subject<number> = new Subject();
    roomCameraWatcherAdded = this.roomCameraWatcherAddedSource.asObservable();

    roomCameraWatcherRemovedSource: Subject<number> = new Subject();
    roomCameraWatcherRemoved = this.roomCameraWatcherRemovedSource.asObservable();

    selectedSendingMessageTypeSource: Subject<any> = new Subject();
    selectedSendingMessageType = this.selectedSendingMessageTypeSource.asObservable();

    viewRoomUserListDoneSource: Subject<boolean> = new Subject();
    viewRoomUserListDone = this.viewRoomUserListDoneSource.asObservable();

    takenInviteToRoomSource: Subject<Array<any>> = new Subject();
    takenInviteToRoom = this.takenInviteToRoomSource.asObservable();

    roomDeletedSource: Subject<number> = new Subject();
    roomDeleted = this.roomDeletedSource.asObservable();

    joinRoomSource: Subject<Array<RoomCamera>> = new Subject();
    joinRoom = this.joinRoomSource.asObservable();

    environmentVariableLoadedSource: Subject<Array<any>> = new Subject();
    environmentVariableLoaded = this.environmentVariableLoadedSource.asObservable();

    micAccessButtonDisabled: boolean = false;

    connectionProblem: boolean = false;
    isMyCamOpened = false;
    messageCount: number = 0;
    roomUserContextMenuMode: RoomUserContextMenuMode;

    isRoomRecordingStarted: boolean = false;
    adminPanelTabs;
    roomFocused = false;

    micInviteKey: any = '';

    clickSubjectSource = new Subject<Room>();
    clickSubject = this.clickSubjectSource.asObservable();

    roomUserListForViewTheRoom: Array<RoomUser> = [];
    roomMicUserList: Array<any> = [];

    currentMessageType = MessageType.Text;

    fromClickEvent: boolean = false;

    currentMicrophoneJsonStruct: MicrophoneJson = new MicrophoneJson();
    envVariable: Subscription

    recordName: string = ""
    recordId: string = ""

    videoInfo: RecordRoomVideoType
    audioQualityFromRoomInfo: number

    pageSize:number=50
    pageIndex:number=1

    roomUserCount:number = 0
    vipUserCount:number = 0 
    requestUserCount:number = 0 


    isClickedPaginationElement:boolean = false

    isThereOnMic: boolean = false;
    rowNumberForUserOnMic : number = 0;

    selectedUserListType:UserListType = UserListType.ONLINE
    isThereHandRequest: boolean = false
    isEmptyHandRequestUserList: boolean = false
    isEmptyVipUserList: boolean = false
    isEmptyOnlineUserList: boolean = false

    meInRoom:RoomUser = new RoomUser()
    isUserListDataLoaded: boolean = false
    isFirstJoiningMessageReceived: boolean = false
    isCurentUserJoinedMessageReceived: boolean = false
    pageSizeForRoomList:number = 48
    pageIndexForRoomList:number = 1
    totalCountOfRooms:number = 0
    allPageCountForRoomList:number = 0
    isClickedRoomPaginationElement:boolean = false

    onlineUserCount: number = 0
    selectedRoomListType:RoomListType  = RoomListType.STATIC
    isNewRoomCreatedDynMessageReceived: boolean = false

    userAIChatRemainingTime: number = 0
    remainingHours : number;
    remainingMinutes: number;
    remainingSeconds: number;
    countdownInterval;
    categoryOrClassList = [];

    ttsModelList = [];
    ttsLanguageList = [];
    ttsVoiceList = [];
    sttModelList = [];
    sttLanguageList = [];
    aiModelList = [];

    constructor(
        private http: HttpClient,
        public utilService: UtilService,
        public messagePanelService: MessagePanelService,
        protected indexedDBService: IndexedDBService,
        private chatMessageService: ChatMessageService,
        private localizationService: LocalizationService,
        private dynamicMethodsSendService: DynamicMethodsSendService,
        private chatService: ChatService,
        private panelManagerService: PanelManagerService,
        private translateService: TranslateService,
        private roomUserListService: RoomUserListService,
        private confirmationService: ConfirmationService,
        private titleService: Title,
        private natsService: NatsService,
        private storageService: LocalStorageService,
        public livekitService: LivekitService,
        public environmentVariableService: EnvironmentVariableService,
        private dynamicMethodsService: DynamicMethodsService,
        public deviceService:DeviceService,
        public roleAccessService:RoleAccessService,
        public languageService:LanguageService
    ) {
        super(messagePanelService, utilService, indexedDBService);


        window.addEventListener("dragover", function (e) {
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e.preventDefault();
        }, false);

        this.envVariable = this.environmentVariableService.sentEnvironmentVariable.subscribe(res => {
            this.environmentVariable = res
            this.chatService.isAiActive = res.IsAiActive
            if (res.IsAiActive) {
                this.setAllModelsToListObject()
            }
            this.selectInitialRoomListType()
            this.setDefaultLanguageFromEnvironmentVariable()
        })

        if (!this.roomListRefreshInterval)
            this.roomListRefreshInterval = setInterval(() => {
                if (this.panelManagerService.activeMainPanel == MainPanels.RoomList &&
                    !this.isClickedRoomPaginationElement && !this.isNewRoomCreatedDynMessageReceived
                ) {
                    if (this.selectedRoomListType == RoomListType.STATIC) {
                        this.updateRoomsOnlineCountInfo()
                    }
                }
            }, 20000);

        if (!this.heartbeatInterval)
            this.heartbeatInterval = setInterval(() => {
                if (this.panelManagerService.activeMainPanel == MainPanels.None) {
                    return
                }
                if (!this.isUserIdMatchInDecodedToken()) {
                    this.invalidateAuthenticationAndSignOut();
                }
                this.callHeartBeat()
            }, 5000)

        this.setBrowserTitleChangeInterval();
    }

    //CODE_TO_LIVE
    setAllModelsToListObject() {
        this.convertCategoriesToObjects()
        this.convertTTSModelsToObjects()
        this.convertTTSLanguagesToObjects()
        this.convertTTSVoicesToObjects()
        this.convertSTTModelsToObjects()
        this.convertSTTLanguagesToObjects()
        this.convertAIModelsToObjects()
    }
    
    convertCategoriesToObjects() {
        if (this.environmentVariable.CATEGORIES_OR_CLASSES) {
            this.categoryOrClassList = this.environmentVariable.CATEGORIES_OR_CLASSES.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }
    convertTTSModelsToObjects() {
        if (this.environmentVariable.TTSModels) {
            this.ttsModelList = this.environmentVariable.TTSModels.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }

    convertTTSLanguagesToObjects() {
        if (this.environmentVariable.TTSLanguages) {
            this.ttsLanguageList = this.environmentVariable.TTSLanguages.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }

    convertTTSVoicesToObjects() {
        if (this.environmentVariable.TTSVoices) {
            this.ttsVoiceList = this.environmentVariable.TTSVoices.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }

    convertSTTModelsToObjects() {
        if (this.environmentVariable.STTModels) {
            this.sttModelList = this.environmentVariable.STTModels.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }

    convertSTTLanguagesToObjects() {
        if (this.environmentVariable.STTLanguages) {
            this.sttLanguageList = this.environmentVariable.STTLanguages.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }

    convertAIModelsToObjects() {
        if (this.environmentVariable.AIModels) {
            this.aiModelList = this.environmentVariable.AIModels.split(',').map(item => {
                return { label: item.trim(), value: item.trim() };
            });
        }
    }

    selectInitialRoomListType() {
        if(this.environmentVariable.IsAiActive){
            this.selectedRoomListType = RoomListType.CATEGORYDYNAMICROBOTS
        }
        else{
            this.selectedRoomListType = RoomListType.STATIC
        }

        this.environmentVariableLoadedSource.next()
    }
    setDefaultLanguageFromEnvironmentVariable() {
        let language = this.languageService.getCurrentLanguage();
        if (!language) {
            let defaultLanguage = this.environmentVariable.DefaultLanguage
            if (defaultLanguage != '') {
                this.languageService.selectedLanguage = this.languageService.languages.find(element => element.label === defaultLanguage)?.value;
                this.languageService.changeLanguage(this.languageService.languages.find(element => element.value === this.languageService.selectedLanguage))
            }
            else{
                this.languageService.selectedLanguage = this.languageService.languages.find(element => element.key === 'tr')?.value;
            }
        } else {
            this.languageService.selectedLanguage = this.languageService.languages.find(element => element.key === language.key)?.value;
        }
    }
    isUserAtMainMic(): boolean {
        return this.oldmicrophoneJsonStruct.M === this.indexedDBService.userFullInfo.KullaniciId.toString();
    }

    isUserAtOtherMic(): boolean {
        return this.oldmicrophoneJsonStruct.M2.includes(this.indexedDBService.userFullInfo.KullaniciId.toString());
    }

    isUserAtHands(): boolean {
        return this.oldmicrophoneJsonStruct.H.includes(this.indexedDBService.userFullInfo.KullaniciId.toString());
    }

    getRoomInfo(roomId: number): Promise<RoomInfo> {
        var endpoint = `${environment.apiUrl}api/room/info/${roomId}`
        return this.http.get<RoomInfo>(endpoint).toPromise();
    }

    getRoomPassword(roomId: number): Promise<any> {
        var endpoint = `${environment.apiUrl}api/admin/room/password/${roomId}`
        return this.http.get<RoomInfo>(endpoint).toPromise();
    }

    decodeJWT(): DecodeJWT {
        return jwt_decode(this.storageService.storageGetItem('token'));
    }

    reconnectRoom(newRoomId: number) {
        if (this.isUserAtMainMic() || this.isUserAtOtherMic()) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Mikrofonu sonlandırmadan tekrar bağlanamazsınız', 2000);
            return;
        }
        if (this.isUserAtHands()) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Elini indirmeden tekrar bağlanamazsınız!', 2000);
            return;
        }
        // room operation
        this.getRoomInfo(newRoomId)
            .then((roomInfo: RoomInfo) => {
                this.audioQualityFromRoomInfo = roomInfo.AUDIO_QUALITY;
                this.beforeEnterRoomData = Object.assign({}, roomInfo);
            })
            .then(() => {
                return this.whoCanEnterThisRoomControlNew();
            })
            .then(() => {
                if (this.indexedDBService.userFullInfo.RutbeDerece != -1) {
                    return this.checkBanNew(newRoomId);
                }
            })
            .then(() => {
                if (this.beforeEnterRoomData.HASPASSWORD) {
                    return this.roomPassControlNew()
                }
            })
            .then(() => {
                return this.roomSecondNickControlNew();
            })
            .then(() => {
                this.panelManagerService.mainLoading = true;
                if (this.currentRoom.Info && this.currentRoom.Info !== null && this.currentRoom.Info.ID !== 0) {
                    if (this.indexedDBService.userFullInfo.RutbeDerece != -1) {
                        return this.stopCam(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, true);
                    }
                }
            })
            .then(() => {
                this.panelManagerService.mainLoading = true;
                if (this.currentRoom.Info && this.currentRoom.Info !== null && this.currentRoom.Info.ID !== 0) {
                    if (this.indexedDBService.userFullInfo.RutbeDerece != -1) {
                        return this.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, true);
                    }
                }
            })
            .then(() => {
                this.finishAllConference();
            })
            //enter room operations
            .then(() => {
                this.panelManagerService.closeRoomPage();
                // clear current room
                this.currentRoom = new CurrentRoom();
                this.currentRoom.OwnerInfo = new UserFullInfo();

                // set new values
                this.currentRoom.Info = Object.assign({}, this.beforeEnterRoomData);
                this.currentRoom.OwnerInfo.KullaniciId = this.currentRoom.Info.OWNER;
                this.currentRoom.OwnerInfo.KullaniciAdi = this.currentRoom.Info.OwnerName;
                this.currentRoom.OwnerInfo.ProfilResmiId = this.currentRoom.Info.ProfilResmiId;
                this.currentRoom.OwnerInfo.RutbeDerece = this.currentRoom.Info.RutbeDerece;

                //TODO: figure out; what microphone must be
                this.oldmicrophoneJsonStruct = <MicrophoneJson>{
                    M: "",
                    S: null,
                    M2: [],
                    H: [],
                    I: 0,
                    T: "",
                    R: newRoomId.toString()
                };
                this.micAccessButtonDisabled = true;
                this.setValuesAsDefault();
                this.panelManagerService.openRoomPage();
                this.initchatPanelProperties();
                this.roomAllMessagesRemovedSource.next();
                this.setRoomBackgroundSettings();
                this.setEditorAsDefault();
                return this.callEnterRoom(this.indexedDBService.userFullInfo.KullaniciId.toString(), newRoomId.toString())
            })
            .then(() => {
                if (this.livekitService.room.state === ConnectionState.Disconnected) {
                    this.livekitService.initConnection()
                }
            })
            .then(() => {
                 this.callGetRoomUserByUserId()
            })
            .then(() => {
                this.getMicInfoJsonFormServerAndDoWork();
                this.callGetOnlineUserList(1,true); //ilk 30 online kullanıcı
            })
            .catch(error => {
                console.log(`ERROR ON ENTER ROOM - ERROR : ${error}`);
            }).finally(() => {
            this.micAccessButtonDisabled = false;
            this.beforeEnterRoomData = new RoomInfo();
            this.panelManagerService.mainLoading = false;
        })
    }
    enterRoomNew(newRoomId: number,password?:string) {
        if (!this.indexedDBService.isAuthenticated()) {
            this.invalidateAuthenticationAndSignOut();
            return
        }
        if (!this.isUserIdMatchInDecodedToken()) {
            this.signOut();
            return
        }
        if (this.currentRoom.Info?.ID === newRoomId) {
            this.panelManagerService.openRoomPage();
            return;
        }
        if (this.isUserAtMainMic() || this.isUserAtOtherMic()) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Mikrofonu sonlandırmadan odadan çıkamazsınız!', 2000);
            return;
        }
        if (this.isUserAtHands()) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Elini indirmeden odadan çıkamazsınız!', 2000);
            return;
        }
        this.activateMicState();
        this.getRoomInfo(newRoomId)
            .then((roomInfo: RoomInfo) => {
                this.audioQualityFromRoomInfo = roomInfo.AUDIO_QUALITY;
                this.beforeEnterRoomData = Object.assign({}, roomInfo);
                return this.whoCanEnterThisRoomControlNew();
            })
            .then(() => {
                if (this.indexedDBService.userFullInfo.RutbeDerece !== -1) {
                    return this.checkBanNew(newRoomId);
                }
            })
            .then(() => {
                if (this.beforeEnterRoomData.HASPASSWORD && !password) {
                    return this.roomPassControlNew();
                }
            })
            .then(() => {
                return this.roomSecondNickControlNew();
            })
            .then(() => {
                this.panelManagerService.mainLoading = true;
                if (this.currentRoom.Info && this.currentRoom.Info.ID !== 0) {
                    if (this.indexedDBService.userFullInfo.RutbeDerece !== -1) {
                        this.stopCam(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, true);
                        this.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, true);
                    }
                }
            })
            .then(() => {
                this.finishAllConference();
                return this.callEnterRoom(this.indexedDBService.userFullInfo.KullaniciId.toString(), newRoomId.toString());
            })
            .then(() => {
                this.setRoomValuesAfterSuccessfulEntry(newRoomId);
            })
            .then(() => {
                if (this.livekitService.room) {
                    this.disconnectActiveRoomAndConnectNewRoom();
                } else {
                    this.livekitService.initConnection();
                }
            })
            .then(() => {
                this.callGetRoomUserByUserId();
            })
            .then(() => {
                this.isUserListDataLoaded = true;
                this.getMicInfoJsonFormServerAndDoWork();
                this.callGetOnlineUserList(1, true);
            })
            .catch(error => {
                if (error.status === 400 && error.error === 'you have to buy time to join this room') {
                    this.messagePanelService.ShowPopupMessageWithLocalization('purchase-ai-time-limit', 4000);
                } else {
                    this.messagePanelService.ShowPopupMessageWithLocalization('Odaya giriş sırasında bir hata oluştu!', 3000);
                }
            })
            .finally(() => {
                this.micAccessButtonDisabled = false;
                this.beforeEnterRoomData = new RoomInfo();
                this.panelManagerService.mainLoading = false;
                setTimeout(() => {
                    this.listOldMessagesSource.next();
                }); 
            });
    }
    
    
    setRoomValuesAfterSuccessfulEntry(newRoomId: number) {
        this.panelManagerService.closeRoomPage();
        
        this.currentRoom = new CurrentRoom();
        this.currentRoom.OwnerInfo = new UserFullInfo();

        this.currentRoom.Info = Object.assign({}, this.beforeEnterRoomData);
        this.currentRoom.OwnerInfo.KullaniciId = this.currentRoom.Info.OWNER;
        this.currentRoom.OwnerInfo.KullaniciAdi = this.currentRoom.Info.OwnerName;
        this.currentRoom.OwnerInfo.ProfilResmiId = this.currentRoom.Info.ProfilResmiId;
        this.currentRoom.OwnerInfo.RutbeDerece = this.currentRoom.Info.RutbeDerece;

        this.oldmicrophoneJsonStruct = <MicrophoneJson>{
            M: "",
            S: null,
            M2: [],
            H: [],
            I: 0,
            T: "",
            R: newRoomId.toString()
        };
    
        this.micAccessButtonDisabled = true;
        this.setValuesAsDefault();
        this.panelManagerService.openRoomPage();
        this.initchatPanelProperties();
        this.roomAllMessagesRemovedSource.next();
        this.setRoomBackgroundSettings();
        this.setEditorAsDefault();
    }
    
    enterDynamicRoom(newRoomId: number,password?:string) {
        if (!this.indexedDBService.isAuthenticated()) {
            this.invalidateAuthenticationAndSignOut();
            return
        }
        if (!this.isUserIdMatchInDecodedToken()) {
            this.signOut();
            return
        }
        if (this.currentRoom.Info?.ID === newRoomId) {
            this.panelManagerService.openRoomPage();
            return;
        }
        this.activateMicState();
        this.getRoomInfo(newRoomId)
            .then((roomInfo: RoomInfo) => {
                this.audioQualityFromRoomInfo = roomInfo.AUDIO_QUALITY;
                this.beforeEnterRoomData = Object.assign({}, roomInfo);
                return this.whoCanEnterThisRoomControlNew();
            })
            .then(() => {
                if (this.indexedDBService.userFullInfo.RutbeDerece !== -1) {
                    return this.checkBanNew(newRoomId);
                }
            })
            .then(() => {
                if (this.beforeEnterRoomData.HASPASSWORD && !password) {
                    return this.roomPassControlNew();
                }
            })
            .then(() => {
                return this.roomSecondNickControlNew();
            })
            .then(() => {
                this.panelManagerService.mainLoading = true;
                if (this.currentRoom.Info && this.currentRoom.Info.ID !== 0) {
                    if (this.indexedDBService.userFullInfo.RutbeDerece !== -1) {
                        this.stopCam(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, true);
                        this.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, true);
                    }
                }
            })
            .then(() => {
                this.finishAllConference();
                return this.callEnterRoom(this.indexedDBService.userFullInfo.KullaniciId.toString(), newRoomId.toString());
            })
            .then(() => {
                this.setRoomValuesAfterSuccessfulEntry(newRoomId);
            })
            .then(() => {
                if (this.livekitService.room) {
                    this.disconnectActiveRoomAndConnectNewRoom();
                } else {
                    this.livekitService.initConnectionForDynamicRoom();
                }
            })
            .then(() => {
                this.callGetRoomUserByUserId();
            })
            .then(() => {
                this.isUserListDataLoaded = true;
                this.getMicInfoJsonFormServerAndDoWork();
                this.callGetOnlineUserList(1, true);
            })
            .catch(error => {
                if (error.status === 400 && error.error === 'you have to buy time to join this room') {
                    this.messagePanelService.ShowPopupMessageWithLocalization('purchase-ai-time-limit', 4000);
                } else {
                    this.messagePanelService.ShowPopupMessageWithLocalization('Odaya giriş sırasında bir hata oluştu!', 3000);
                }
            })
            .finally(() => {
                this.micAccessButtonDisabled = false;
                this.beforeEnterRoomData = new RoomInfo();
                this.panelManagerService.mainLoading = false;
                setTimeout(() => {
                    this.listOldMessagesSource.next();
                }); 
            });
    }
    isUserIdMatchInDecodedToken() {
        if ((this.panelManagerService.newUserRegisterPopupDisplay || this.panelManagerService.newUserForDynamicRoomRegisterPopupDisplay) 
            || this.panelManagerService.aiUserNameEntryLoading) {
            return true
        }
        else{
            var decodedToken = this.decodeJWT()
            if (decodedToken.userID != this.indexedDBService.userFullInfo.KullaniciId || decodedToken.userName != this.indexedDBService.userFullInfo.KullaniciAdi) {
                return false
            } else
                return true
        }
    }

    disconnectActiveRoomAndConnectNewRoom() {
        this.allParticipantAreMuted = false;
        this.livekitService.allSenderAudioTrackAreMuted = false;
        this.livekitService.isMicEnabled = false;
        return this.livekitService.room.disconnect().then(() => {
            this.livekitService.initConnection();
        }).catch(error => {
            console.error("Error during disconnect and reconnect:", error);
        });
    }

    whoCanEnterThisRoomControlNew(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.beforeEnterRoomData.KIMLER_GIREBILIR === "u" && this.indexedDBService.userFullInfo.RutbeId !== Constants.UYERUTBEID) {
                this.messagePanelService.ShowPopupMessageWithLocalization("OnlyMemberEnter", 2000);
                reject("OnlyMemberEnter");
                return;
            } else if (this.beforeEnterRoomData.KIMLER_GIREBILIR === "y" && this.indexedDBService.userFullInfo.RutbeId === Constants.UYERUTBEID) {
                this.messagePanelService.ShowPopupMessageWithLocalization("OnlyAuthorityEnter", 2000);
                reject("OnlyAuthorityEnter");
                return;
            }
            if (!this.beforeEnterRoomData.GUEST_JOIN && this.indexedDBService.userFullInfo.RutbeDerece === -1) {
                this.messagePanelService.ShowPopupMessageWithLocalization("room-open-for-guest", 2000);
                reject("room-open-for-guest");
                return;
            }
            resolve(true);
        });
    }


    checkBanNew(roomId: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.callCheckBanNew(this.indexedDBService.userFullInfo.KullaniciId, roomId)
                .then((response: CheckBanResponse) => {
                    if (response.Success == false && response.BanType === 1) { //SITE BAN
                        this.messagePanelService.ShowPopupMessageWithLocalization(this.localizationService.tagStart + "BannedAllRoom" + this.localizationService.tagEnd, 3000);
                        reject("BannedAllRoom");
                        return
                    } else if (response.Success == false && response.BanType === 2) { // ODA BAN
                        this.messagePanelService.ShowPopupMessageWithLocalization(this.localizationService.tagStart + "BannedThisRoom" + this.localizationService.tagEnd, 3000);
                        reject("BannedThisRoom");
                        return
                    }
                    resolve(true);
                    return
                })
        });
    }

    callCheckBanNew(userId: number, roomId: number): Promise<CheckBanResponse> {
        let body = {
            'UserId': userId,
            'OdaId': roomId
        }
        const endpoint = `${environment.apiUrl}api/ban/check/oda`;
        return this.http.post<CheckBanResponse>(endpoint, body).toPromise();
    }

    roomDocsUpload(body:FormData): Promise<FileMessageUploadResponse> {
        const endpoint = this.ServiceUrl + "api/chat/save/file";
        const options = {};
        return this.http.post<FileMessageUploadResponse>(endpoint, body, options)
          .pipe(catchError(error => this.handleError(error))).toPromise();
      }

    roomPassControlNew(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.panelManagerService.roomPasswordPanelIsOpened = true;
            if (this.roomPasswordPanelResponseSubscription)
                this.roomPasswordPanelResponseSubscription.unsubscribe();
            this.roomPasswordPanelResponseSubscription = this.panelManagerService.roomPasswordPanelResponse
                .subscribe(
                    (response: boolean) => {
                        if (response)
                            resolve();
                        else
                            reject();
                    }
                );
        });
    }

    roomSecondNickControlNew(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.indexedDBService.userFullInfo.RutbeFullInfo.IKINCI_RUMUZ === false) {
                resolve();
                return;
            }

            if (this.autoEnterWithSecondNick === true) {
                resolve();
                return;
            }

            this.panelManagerService.roomSecondNickPanelIsOpened = true;

            if (this.roomSecondNickPanelResponseSubscription)
                this.roomSecondNickPanelResponseSubscription.unsubscribe();

            this.roomSecondNickPanelResponseSubscription = this.panelManagerService.roomSecondNickPanelResponse.subscribe(
                (secondNick: string) => {
                    if (secondNick !== null) {
                        this.autoEnterWithSecondNick = true;
                        resolve();
                    } else {
                        reject();
                    }
                }
            );
        });
    }


    ngOnDestroy() {
        this.keepAfterNavigationChange = false;
        this.roomServerInfoDic = {}; // key:serverInfoId  value: RoomServerInfo
        this.roomServerInfoRoomRelation = {};// key: roomId  value: serverInfoId
        this.oldmicrophoneJsonStruct = new MicrophoneJson();
        this.isHighQualityMic = true;
        this.lastAudioDeviceIndex = 0;
        this.changeRoomIsInActive = true;
        this.lastEnterRoomTime = this.utilService.newDate();
        this.autoEnterWithSecondNick = false;
        this.beforeEnterRoomData = new RoomInfo();
        this.roomListRefreshInterval = undefined;
        this.currentRoom = <CurrentRoom>{
            Info: null,
            OwnerInfo: null,
            OnlineCount: 0
        }
        this.favoriteRooms = [];
        this.selectedRoomUser = undefined;
        this.selectedRoomInfo = undefined;
        this.roomUserList = [];
        this.pageIndex = 1;
        this.chatPanelProperties = null;
        this.isMyCamOpened = false;
        this.roomUserContextMenuMode = undefined;
        this.adminPanelTabs = undefined;
        this.connectionProblem = false;
        this.micAccessButtonDisabled = false;
        this.roomUserListForViewTheRoom = [];
        this.roomMicUserList = [];
        this.viewingRoomInfo = new RoomInfo();
        this.currentMessageChatType = ChatType.SingleChat;
        this.messageSendingTypeImgUrl = MessageSendingTypeImgUrl.Normal;
        this.currentMessageSendingType = MessageSendingType.Normal;
        this.roomUserListService.userViewInRoom = {};
        this.fromClickEvent = false;
        this.micInviteKey = '';

        if (this.heartbeatInterval != undefined) {
            clearInterval(this.heartbeatInterval)
            this.heartbeatInterval = undefined;
        }
        if (this.roomPasswordPanelResponseSubscription) {
            this.roomPasswordPanelResponseSubscription.unsubscribe();
        }
        if (this.roomSecondNickPanelResponseSubscription) {
            this.roomSecondNickPanelResponseSubscription.unsubscribe();
        }

    }

    setValuesAsDefault() {
        this.roomUserListForViewTheRoom = [];
        this.viewingRoomInfo = new RoomInfo();
        this.currentMessageChatType = ChatType.SingleChat;
        this.messageSendingTypeImgUrl = MessageSendingTypeImgUrl.Normal;
        this.currentMessageSendingType = MessageSendingType.Normal;
        this.roomUserListService.userViewInRoom = {};
        this.roomUserList = [];
        this.pageIndex = 1;
        this.panelManagerService.viewTheRoomPopupDisplay = false;
        this.panelManagerService.youtubePlayerPopupDisplay = false;
        this.fromClickEvent = false;
        //user-list-elements
        this.micUserList = [];
        this.handUserList = [];
        this.onlineUserList = [];
        this.vipUserList = [];
        this.roomCameraUserList = [];
        this.pageIndex = 1;
        this.selectedUserListType = UserListType.ONLINE
        this.roomUserCount = 0;
        this.vipUserCount = 0;
        this.requestUserCount = 0;
        this.isClickedPaginationElement = false
        this.isUserListDataLoaded = false
        this.isFirstJoiningMessageReceived = false
        this.isCurentUserJoinedMessageReceived = false
        this.panelManagerService.roomAIImagePopupDisplay = false;
        this.livekitService.isRoomHasAIAgent = false;
        this.panelManagerService.roomAIChatRemainingTimePopupDisplay = false
    }


    getMicServiceUrl() {
        return environment.apiUrl;
    }


    setBrowserTitleChangeInterval() {
        setInterval(() => {
            if (this.titleService.getTitle() === this.environmentVariable.UygulamaAdi && this.messageCount > 0) {
                this.titleService.setTitle('(' + this.messageCount + ') ' + this.translateService.instant('NewRoomMessage'));

            } else {
                this.titleService.setTitle(this.environmentVariable.UygulamaAdi);

            }
        }, 1000);
    }

    callErrorTracker(errorID: number, errorMessage: string) {
        this.errorTracker({
            "userID": this.indexedDBService.userFullInfo.KullaniciId,
            "roomID": this.currentRoom?.Info?.ID,
            "error": errorMessage,
            "errorID": errorID
        }).toPromise();
    }


    getRoomInfoJson(roomId: number, useCache: boolean = true): Promise<any> {
        const endpoint = environment.apiUrl + "api/room/info/" + roomId;

        return this.getJson(endpoint, useCache)
            .then((roominfo) => roominfo)
            .catch(err => {
                console.log(err);
            });
    }

    controlBeforeEnterRoom(roomId: number, autoLogin: boolean, isView: boolean = false, reLogin: boolean = false): Promise<void> {

        return new Promise((resolve, reject) => {
            try {
                setTimeout(() => {
                    this.changeRoomIsInActive = true;
                }, 1000);
                if (!autoLogin &&
                    (
                        this.lastEnterRoomTime > this.utilService.addSecondToDate(this.utilService.newDate(), -2) ||
                        this.changeRoomIsInActive === false
                    )
                ) {
                    this.messagePanelService.ShowPopupMessageWithLocalization("RoomChangeLimit", 2000);
                    reject();
                    return;
                }
                this.changeRoomIsInActive = false;
                this.lastEnterRoomTime = this.utilService.newDate();

                this.getRoomInfoJson(roomId, true)
                    .then((roominfo) => {
                        return this.getBeforeEnterRoomData(roominfo, isView);
                        // this.setCurrentRoomServerInfoFromResponseJson(beforeEnterRoom.RoomServerInfo, roomId); //TODO İSTE
                    })
                    .then((roomInfo: RoomInfo) => {
                        this.beforeEnterRoomData = roomInfo;
                    })
                    .then(() => {
                        return this.whoCanEnterThisRoomControl();
                    })
                    .then(() => {
                        return this.banControlBeforeEnterRoom(roomId);
                    })
                    .then(() => {
                        return this.roomPassControl();
                    })
                    .then(() => {
                        return this.roomSecondNickControl();
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error)
                        reject();
                    });
            } catch (error) {
                console.log(error);
                reject();
            }
        });
    }

    getBeforeEnterRoomData(roominfo: RoomInfo, isView: boolean = false): Promise<RoomInfo> {
        return new Promise((resolve, reject) => {
            try {
                let beforeEnterRoomData = new RoomInfo();
                this.viewingRoomInfo = new RoomInfo();
                beforeEnterRoomData.ID = this.utilService.getIntFromJsonData(roominfo[RoomInfoEnum.ID]);
                beforeEnterRoomData.NAME = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.NAME]);
                beforeEnterRoomData.ODAMESAJ = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.ODAMESAJ]);
                beforeEnterRoomData.TOPIC = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.TOPIC]);
                beforeEnterRoomData.BACK_IMAGE_ID = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.BACK_IMAGE_ID]);
                beforeEnterRoomData.BUTTON_BACK_IMAGE_ID = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.BUTTON_BACK_IMAGE_ID]);
                beforeEnterRoomData.BUTTON_FONT = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.BUTTON_FONT]);
                beforeEnterRoomData.BUTTON_FONTCOLOR = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.BUTTON_FONTCOLOR]);
                beforeEnterRoomData.KIMLER_GIREBILIR = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.KIMLER_GIREBILIR]);
                beforeEnterRoomData.LIMIT = this.utilService.getIntFromJsonData(roominfo[RoomInfoEnum.LIMIT]);
                beforeEnterRoomData.MICLIMIT = this.utilService.getIntFromJsonData(roominfo[RoomInfoEnum.MICLIMIT]);
                beforeEnterRoomData.RENK_CHAT_1 = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.RENK_CHAT_1]);
                beforeEnterRoomData.RENK_CHAT_2 = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.RENK_CHAT_2]);
                beforeEnterRoomData.RENK_USER_1 = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.RENK_USER_1]);
                beforeEnterRoomData.RENK_USER_2 = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.RENK_USER_2]);
                beforeEnterRoomData.SESSIZ = this.utilService.getBoolFromJsonData(roominfo[RoomInfoEnum.SESSIZ]);
                beforeEnterRoomData.YAZILARI_SIL = this.utilService.getBoolFromJsonData(roominfo[RoomInfoEnum.YAZILARI_SIL]);
                beforeEnterRoomData.SIFRE = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.SIFRE]);
                beforeEnterRoomData.OWNER = this.utilService.getIntFromJsonData(roominfo[RoomInfoEnum.OWNER]);
                beforeEnterRoomData.BackImageType = this.utilService.getStringFromJsonData(roominfo[RoomInfoEnum.BACK_IMAGE_TYPE]);
                beforeEnterRoomData.HASPASSWORD = roominfo[RoomInfoEnum.HAS_PASSWORD]

                if (isView) {
                    this.roomUserListForViewTheRoom = [];
                    this.viewingRoomInfo = beforeEnterRoomData;
                }
                return resolve(beforeEnterRoomData);
            } catch (error) {
                return reject();
            }
        });
    }

    getJson(endpoint: string, useCahce: boolean = true): Promise<any> {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        if (useCahce === false) {
            options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                })
            };
        }

        return this.http.get(endpoint, options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
            .then(data => {
                if (this.utilService.isNullOrEmtpyString(data))
                    throw "error"
                else
                    return data;
            });
    }


    whoCanEnterThisRoomControl(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                if (this.beforeEnterRoomData.KIMLER_GIREBILIR === "u") {
                    if (this.indexedDBService.userFullInfo.RutbeId !== Constants.UYERUTBEID) {
                        this.messagePanelService.ShowPopupMessageWithLocalization("OnlyMemberEnter", 2000);
                        reject();
                        return;
                    } else
                        resolve();
                } else if (this.beforeEnterRoomData.KIMLER_GIREBILIR === "y") {
                    if (this.indexedDBService.userFullInfo.RutbeId === Constants.UYERUTBEID) {
                        this.messagePanelService.ShowPopupMessageWithLocalization("OnlyAuthorityEnter", 2000);
                        reject();
                        return;
                    } else
                        resolve();
                } else
                    resolve();
            } catch (error) {
                console.log(error);
                reject();
            }
        });
    }

    roomPassControl(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                if (!this.beforeEnterRoomData.HASPASSWORD) {
                    resolve();
                    return;
                }

                this.panelManagerService.roomPasswordPanelIsOpened = true;
                if (this.roomPasswordPanelResponseSubscription)
                    this.roomPasswordPanelResponseSubscription.unsubscribe();
                this.roomPasswordPanelResponseSubscription = this.panelManagerService.roomPasswordPanelResponse
                    .subscribe(
                        (response: boolean) => {
                            if (response)
                                resolve();
                            else
                                reject();
                        }
                    );

            } catch (error) {
                console.log(error);
                // reject();
            }
        });
    }

    roomSecondNickControl(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                if (this.indexedDBService.userFullInfo.RutbeFullInfo.IKINCI_RUMUZ === false) {
                    resolve();
                    return;
                }

                if (this.autoEnterWithSecondNick === true) {
                    resolve();
                    return;
                }


                this.panelManagerService.roomSecondNickPanelIsOpened = true;

                if (this.roomSecondNickPanelResponseSubscription)
                    this.roomSecondNickPanelResponseSubscription.unsubscribe();

                this.roomSecondNickPanelResponseSubscription = this.panelManagerService.roomSecondNickPanelResponse.subscribe(
                    (secondNick: string) => {
                        if (secondNick !== null) {
                            this.autoEnterWithSecondNick = true;
                            resolve();
                        } else {
                            reject();
                        }
                    }
                );

            } catch (error) {
                console.log(error);
                reject();
            }
        });
    }

    banControlBeforeEnterRoom(roomId): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.callCheckBan(this.indexedDBService.userFullInfo.KullaniciId, roomId)
                    .subscribe((checkBan: CheckBanResponse) => {
                        if (checkBan.Success) {
                            return resolve();
                        } else {
                            if (checkBan.BanType === 1) { //SITE BAN
                                this.messagePanelService.ShowPopupMessageWithLocalization(this.localizationService.tagStart + "BannedAllRoom" + this.localizationService.tagEnd, 3000);
                            }

                            if (checkBan.BanType === 2) { // ODA BAN
                                this.messagePanelService.ShowPopupMessageWithLocalization(this.localizationService.tagStart + "BannedThisRoom" + this.localizationService.tagEnd, 3000);
                            }
                            return reject();
                        }
                    })
            } catch (error) {
                console.log(error);
                reject();
            }
        });
    }

    enterRoomAfterControl(roomId, relogin: boolean = false): Promise<void> {
        try { //ERRORTRACKER
            if (this.currentRoom.Info !== null && this.currentRoom.Info !== undefined) {
                return this.leaveFromRoomActions(roomId).then(() => {
                    return this.livekitService.disconnectRoom()
                }).then(() => {
                    return Promise.resolve(this.enterRoomAfterControlComplete(roomId, relogin));
                })
                    .catch(error => {
                        this.callErrorTracker(22, error); //ERRORTRACKER
                        return Promise.resolve();
                    });
            } else {
                return Promise.resolve(this.enterRoomAfterControlComplete(roomId, relogin));
            }
        } catch (err) {
            this.callErrorTracker(23, err.message);
            return Promise.resolve();
        }

    }


    stopMicrophoneAndCam() {
        try { //ERRORTRACKER
            if (this.currentRoom != undefined && this.currentRoom != null && this.currentRoom.Info != undefined && this.currentRoom.Info != null && this.currentRoom.Info.ID != null && this.currentRoom.Info.ID != 0) {
                return
            }
            this.stopMicrophoneRequest().then(() => {
                this.livekitService.isOnMic = false
                this.livekitService.allSenderAudioTrackAreMuted = true
                this.livekitService.isMicEnabled = false;
            })
                .then(() => {
                    if (this.livekitService.isOnScreenSharing) {
                        this.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info?.ID, false, false);
                    }
                })
                .then(() => {
                    if (this.livekitService.isOnSharingTabMusic) {
                        this.livekitService.toggleShareScreenAudio(false);
                    }
                })
                .then(() => {
                    if (this.livekitService.isCamOpen) {
                        this.finishCamForReceivedCommand(this.currentRoom.Info?.ID);
                    }
                })
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(4, err.message);
        }
    }


    leaveFromRoomActions(newRoomId): Promise<any> {
        try { //ERRORTRACKER
            //TODO micte miyim diye bak, öyleyse sonlandırmadan odadan çıkartma..
            if (!this.autoLogin &&
                (
                    this.oldmicrophoneJsonStruct.M === this.indexedDBService.userFullInfo.KullaniciId.toString()
                    ||
                    (
                        !this.utilService.isNullOrUndefined(this.oldmicrophoneJsonStruct.M2) &&
                        this.oldmicrophoneJsonStruct.M2.find(userid => userid === this.indexedDBService.userFullInfo.KullaniciId.toString())
                    )
                )
            ) {
                // this.messagePanelService.ShowPopupMessageWithLocalization('Mikrofonu sonlandırmadan odadan çıkamazsınız', 2000);
                return Promise.reject('Mikrofonu sonlandırmadan odadan çıkamazsınız');
            }
            if (this.isRoomRecordingStarted) {
                return Promise.reject('Kaydı durdurmadan odadan çıkamazsınız.');
            }


            //this.stopAllCams();
            // this.stopMicrophoneAndCam();

            if (newRoomId === null) {
                newRoomId = this.currentRoom.Info?.ID
                this.removeRoomContentDiv();
            }
            this.roomUserListClearedSource.next();


            return this.afterExitRoomOperations(newRoomId)
                .then(() => {
                    this.callLeaveRoom(this.indexedDBService.userFullInfo.KullaniciId.toString(), newRoomId.toString())
                });

        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(1, err.message);
            return this.afterExitRoomOperations(newRoomId)
                .then(() => {
                    this.callLeaveRoom(this.indexedDBService.userFullInfo.KullaniciId.toString(), newRoomId.toString())
                });
        }
    }

    leaveFromRoomAfterAIChatLimitFinished(){
        try {
            let newRoomId
            if (newRoomId === null) {
                newRoomId = this.currentRoom.Info?.ID
                this.removeRoomContentDiv();
            }
            this.afterExitRoomOperations(newRoomId)
            this.roomUserListClearedSource.next();
            this.removeRoomContentDiv();
            this.livekitService.disconnectRoom();
            this.oldmicrophoneJsonStruct = <MicrophoneJson>{
                M: "",
                S: null,
                M2: [],
                H: [],
                I: 0,
                T: "",
                R: ""
            };
            this.setValuesAsDefault();
            this.messagePanelService.ShowPopupMessageWithLocalization('ai-chat-limit-finished', 4000);

        } catch (err) {
            console.log(err.message)
        }
    }

    closeRoomAIPanel(){
        this.panelManagerService.roomAIImagePopupDisplay = false;
        this.panelManagerService.roomAIChatRemainingTimePopupDisplay = false;
        clearInterval(this.countdownInterval);
    }

    connectionProblemDetected(){
        this.messagePanelService.ShowPopupMessageWithLocalization('connection-problem-detected', 2000);
    }
    
    reconnectedSuccessfully(){
        this.messagePanelService.ShowPopupMessageWithLocalization('reconnected-successfully', 2000);
    }

    activateMicState() {
        this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsAudioIsMutedPrivateKey, this.indexedDBService.userFullInfo.KullaniciAdi)
            .then(muted => {
                this.audioReceiverConferenceMuted = muted;
            })
    }

    muteAllAudioElements() {
        this.livekitService.room.remoteParticipants.forEach((participant) => {
            const micPublication = participant.getTrackPublication(Track.Source.Microphone);
            const screenShareAudioPublication = participant.getTrackPublication(Track.Source.ScreenShareAudio);
    
            if (micPublication) {
                micPublication.setEnabled(false);
            }
    
            if (screenShareAudioPublication) {
                screenShareAudioPublication.setEnabled(false);
            }
        });
    
        this.allParticipantAreMuted = true;
    }
    

    unmuteAllAudioElements() {
        this.livekitService.room.remoteParticipants.forEach(participant => {
            const micPublication = participant.getTrackPublication(Track.Source.Microphone);
            const screenShareAudioPublication = participant.getTrackPublication(Track.Source.ScreenShareAudio);
            if (micPublication) {
                micPublication.setEnabled(true);
            }
            if (screenShareAudioPublication) {
                screenShareAudioPublication.setEnabled(true);
            }
        });
        this.allParticipantAreMuted = false
    }

    enterRoomAfterControlComplete(roomId, relogin: boolean = false) {
        try {
            this.panelManagerService.openRoomPage();
            //if (!relogin) {
            this.setValuesAsDefault();
            this.initchatPanelProperties();
            this.roomAllMessagesRemovedSource.next();
            this.setRoomBackgroundSettings();
            this.currentRoom = new CurrentRoom();
            this.currentRoom.OwnerInfo = new UserFullInfo();
            this.currentRoom.Info = Object.assign({}, this.beforeEnterRoomData);
            // }

            this.oldmicrophoneJsonStruct = <MicrophoneJson>{
                M: "",
                S: null,
                M2: [],
                H: [],
                I: 0,
                T: "",
                R: this.currentRoom.Info?.ID.toString()
            };
            this.getMicInfoJsonFormServerAndDoWork();

            this.startReceiverAudio(this.currentRoom.Info?.ID ? this.currentRoom.Info?.ID : roomId);
            this.micAccessButtonDisabled = true;
            this.panelManagerService.roomUserListLoading = true;

            let tryCount = 0;
            this.getRoomUserList(false, this.beforeEnterRoomData.ID)
                .then(() => {
                    this.micAccessButtonDisabled = false;

                    // var roomUser = this.roomUserList.find(user => user.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId);
                    var roomUser = this.getRoomUserFromActiveListByUserId(this.indexedDBService.userFullInfo.KullaniciId);
                    if ((roomUser == undefined) && tryCount <= 3) {
                        throw new Error("re query list")
                    } else {
                        this.roomUserListService.roomUserUpdatedSource.next(roomUser);
                    }
                })
                .then(() => {
                    //this.addRemoveUsersInQueueToRoom();
                    if (this.indexedDBService.userFullInfo.StateId !== StateIdList.Cevrimdisi) {
                        this.currentMessageChatType = ChatType.RoomActionMessage;
                        this.currentMessageSendingType = MessageSendingType.Normal;
                        this.messageSendingTypeImgUrl = MessageSendingTypeImgUrl.Normal;
                        this.currentMessageType = MessageType.Text;
                        return this.sendMessageToRoom(this.utilService.getKeyWithTag('EnteredRoom'))
                    }
                    return;
                })
                .then(() => {
                    this.setEditorAsDefault();
                })
                .then(() => {
                    this.autoLogin = false;
                })
                .catch(error => {
                    tryCount++
                    if (error.message == "re query list" && tryCount <= 3) {
                        console.log("ERROR ON GETLIST COUNTER: ", tryCount)
                        setTimeout(() => {
                            this.getRoomUserList(false, this.beforeEnterRoomData.ID)
                        }, 500)
                    }

                    this.callErrorTracker(667, error);  //ERRORTRACKER
                });

            if (this.beforeEnterRoomData.OWNER !== -1 && this.beforeEnterRoomData.OWNER !== 0 && !this.autoLogin) {
                this.getRoomOwnerInfo()
                    .then((roomOwnerInfo: UserFullInfo) => {
                        this.currentRoom.OwnerInfo = Object.assign({}, roomOwnerInfo);
                    })
                    .catch(error => {
                        console.log(error)
                        this.callErrorTracker(27, error); //ERRORTRACKER
                    })
            }
        } catch (err) {
            console.log(err)
            this.callErrorTracker(24, err.message);  //ERRORTRACKER
        }
    }

    setRoomBackgroundSettings() {
        try { //ERRORTRACKER
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.BACK_IMAGE_ID))
                this.beforeEnterRoomData.BACK_IMAGE_ID = '00000000-0000-0000-0000-000000000000';
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.BUTTON_BACK_IMAGE_ID))
                this.beforeEnterRoomData.BUTTON_BACK_IMAGE_ID = '00000000-0000-0000-0000-000000000000';
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.BUTTON_FONTCOLOR))
                this.beforeEnterRoomData.BUTTON_FONTCOLOR = '#c6e4ec';
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.RENK_CHAT_1))
                this.beforeEnterRoomData.RENK_CHAT_1 = '#c6e4ec';
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.RENK_CHAT_2))
                this.beforeEnterRoomData.RENK_CHAT_2 = '#c6e4ec';
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.RENK_USER_1))
                this.beforeEnterRoomData.RENK_USER_1 = '#c6e4ec';
            if (this.utilService.isNullOrEmtpyObject(this.beforeEnterRoomData.RENK_USER_2))
                this.beforeEnterRoomData.RENK_USER_2 = '#c6e4ec';
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(26, err.message);
        }

    }

    getRoomUserList(isView: boolean = false, roomID?: number): Promise<void> {
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":this.pageIndex
          }
        return this.getRoomOnlineUserlist(body)
            .then((userList: UserListData) => {
                userList.data.forEach(user => {
                    this.getCurrentRoomUserInfos(user, isView)
                });
                return userList;
            })
            .then(promises => {
                if (this.utilService.isNullOrUndefined(promises))
                    return;
                return Promise.all(promises.data);
            })
            .then(() => {
                if (!isView) {
                    this.refreshMicIconsWithJsonValue();
                    this.refreshHandListWithJsonValue();
                    this.refreshGuestMicrophoneListWithJsonValue();
                    this.roomUserListChangedSource.next();
                }
                // resolve();
                return
            })
            .then(() => {
                // this.getUserCamera()
            }).then(() => {
            })
            .catch(error => {
                console.log(error);
                // reject();
            })
            .finally(() => {
                this.panelManagerService.roomUserListLoading = false;
            })
    }

    getRoomUserListForView(roomID?: number): Promise<any> {
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":this.pageIndex
          }
        return this.getRoomOnlineUserlistForViewing(body,roomID)
            .then((userList: UserListData) => {
                userList.data.forEach(user => {
                    this.getCurrentRoomUserInfosForView(user)
                });
                return userList;
            })
            .then(promises => {
                if (this.utilService.isNullOrUndefined(promises))
                    return;
                return Promise.all(promises.data);
            }).catch(error => {
                // reject();
            })
            .finally(() => {
                this.panelManagerService.roomUserListLoading = false;
            })
    }

    callGetMicUserList(){
        this.getRoomMicUserlist()
       .then((response: Array<RoomUser>) => {
               this.micUserList = response
               if (response.length > 0) {
                   this.micUserList.forEach((user) => { //gerek olmayabilir
                        this.setRoomUserInfos(user)
                   })
               }
               this.micUserListLoadedSource.next()
               //kamera ve ekran paylaşımı öğeleri burada başlatılabilir
               this.createUserCamAndScreenShareList()
       })
    }

    callGetVipUserList(pageIndex?: number){
        //mobilde user list tabı açıkken o zaman istek yapsın. 
        if(this.deviceService.isMobile && !this.panelManagerService.showedRoomUserList){
            return
        }
        //pageIndex 1 e eşitlenebilir
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":pageIndex ? pageIndex : this.pageIndex
          }
             this.getRoomVipUserlist(body)
            .then((response: VipUserData) => {
                if (this.utilService.isNullOrEmtpyObject(response.data) === false) {
                    this.vipUserList = response.data
                    this.vipUserCount = response.totalCount
                    if (response.data.length > 0) {
                        this.vipUserList.forEach((user) => {
                             this.setRoomUserInfos(user)
                        })
                    }
                    if (pageIndex) { //from pagination
                        this.pageIndex = pageIndex
                        this.isClickedPaginationElement = false
                    }
                }
                else{
                    this.vipUserList = []
                }
                this.selectedUserListType = UserListType.VIP;
                this.vipUserListLoadedSource.next()
            })
    }

    callGetOnlineUserList(pageIndex?:number,firstEntering?:boolean){
        //pageIndex 1 e eşitlenebilir,
        if(this.deviceService.isMobile && !this.panelManagerService.showedRoomUserList){
            return
        }
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":pageIndex ? pageIndex : this.pageIndex
          }
        this.getRoomOnlineUserlist(body)
       .then((response: UserListData) => {
           if (this.utilService.isNullOrEmtpyObject(response) === false) {
               this.onlineUserList = response.data
               this.onlineUserList.forEach((user) => {
                    this.setRoomUserInfos(user)
               })
            if (firstEntering) {
                this.roomUserCount = response.count
            }   
            if (pageIndex) { //from pagination
                this.pageIndex = pageIndex
                this.isClickedPaginationElement = false
            }
           }
           else{
            this.onlineUserList = []
           }
           this.selectedUserListType = UserListType.ONLINE;
           this.onlineUserListLoadedSource.next()   
       })
    }

    checkHandUserList(){
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":this.pageIndex
        }
        this.getRoomHandRequstUserlist(body)
       .then((response: UserListData) => {
        if (this.utilService.isNullOrEmtpyObject(response.data) === false) {
            this.isThereHandRequest = true
        }
       })
    }

    callGetHandRequestUserList(pageIndex?:number){
        if(this.deviceService.isMobile && !this.panelManagerService.showedRoomUserList){
            return
        }
        //pageIndex 1 e eşitlenebilir
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":pageIndex ? pageIndex : this.pageIndex
        }
        this.getRoomHandRequstUserlist(body)
       .then((response: UserListData) => {
        if (this.utilService.isNullOrEmtpyObject(response) === false) {
            this.handUserList = response.data
            this.requestUserCount=response.count
            if (response.data.length > 0) {
                this.handUserList.forEach((user) => {
                     this.setHandRoomUserInfos(user)
                })
            }
            if (this.isEmptyHandRequestUserList) {
                this.isEmptyHandRequestUserList = false
            }
            if (pageIndex) {
                this.pageIndex = pageIndex
                this.isClickedPaginationElement = false
            }
        }
        else{
            this.handUserList = []
        }
        this.selectedUserListType = UserListType.REQUEST;
        this.handUserListLoadedSource.next()
           
       })
    }

    callGetRoomUserByUserId(){
        let myId = this.indexedDBService.userFullInfo.KullaniciId
        this.getRoomUserByUserId(myId).then((response: RoomUser) => {
            if (this.utilService.isNullOrEmtpyObject(response) === false) {
                this.meInRoom = response
                this.getRoomUserInfos(this.meInRoom)
            }
        })
    }

    isUserhasMuteInThisRoomOrSite(){
        return this.meInRoom.MuteOda || this.meInRoom.MuteSite
    }

    getRoomUserByUserId(userId: number):Promise<RoomUser>{
        let body = {
            "userId":userId
        }
        const endpoint = environment.apiUrl + "api/room/users/" +  this.currentRoom.Info?.ID
        return this.http.post<RoomUser>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    callCreateDynamicRoomFromAITemplate(body): Promise<any> {
        return new Promise((resolve, reject) => {
          this.createDynamicRoom(body)
            .subscribe((response) => {
              if (response.Success == false) {
                this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
                reject()
                return;
              }
              resolve(response);
            }),
            error => {
              console.log(error);
              reject();
            };
        })
    }

    createDynamicRoom(body):Observable<any>{
        const endpoint = environment.apiUrl + "api/admin/room/template/create/room" 
        return this.http.post<RoomUser>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
    }
    //for user in another room
    getRoomUserDataByUserId(userId: number):Promise<RoomUser[]>{
        const endpoint = environment.apiUrl + "api/users/info/" +  userId
        return this.http.get<RoomUser[]>(endpoint,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomOnlineUserlist(body):Promise<UserListData>{
        const endpoint = environment.apiUrl + "api/room/users/info/" +  this.currentRoom.Info?.ID;
        return this.http.post<UserListData>(endpoint,body, this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomMicUserlist():Promise<RoomUser[]>{
        const endpoint = environment.apiUrl + "api/room/users/microphone/" +  this.currentRoom.Info?.ID;
        return this.http.get<RoomUser[]>(endpoint, this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomVipUserlist(body):Promise<VipUserData>{
        const endpoint = environment.apiUrl + "api/room/users/vip/" +  this.currentRoom.Info?.ID;
        return this.http.post<VipUserData>(endpoint,body, this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomHandRequstUserlist(body):Promise<UserListData>{
        const endpoint = environment.apiUrl + "api/room/users/hands/" +  this.currentRoom.Info?.ID;
        return this.http.post<UserListData>(endpoint, body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }
    getRoomOnlineUserlistForViewing(body,roomId:number):Promise<UserListData>{
        const endpoint = environment.apiUrl + "api/room/users/info/" +  roomId;
        return this.http.post<UserListData>(endpoint,body, this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    setCurrentRoomUserInfos(user: RoomUser): RoomUser {
        let roomUser: RoomUser = new RoomUser();
        roomUser = user;

        roomUser.HandUp = false;
        roomUser.OnMainMic = false;
        roomUser.OnSecondMic = false;

        this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
        return roomUser;
    }

    setRoomUserInfos(user: RoomUser): RoomUser {
        let roomUser: RoomUser = new RoomUser();
        roomUser = user;
        this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
        return roomUser;
    }

    setHandRoomUserInfos(user: RoomUser): RoomUser {
        let roomUser: RoomUser = new RoomUser();
        roomUser = user;
        roomUser.HandUp = false;
        if (this.oldmicrophoneJsonStruct.H) {
            if (this.oldmicrophoneJsonStruct.H.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.HandUp = true;
            }
        }
        this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
        return roomUser;
    }

    getRoomUserInfos(user: RoomUser): RoomUser {
        let roomUser: RoomUser = new RoomUser();

        roomUser = user;
        roomUser.HandUp = false;
        roomUser.OnMainMic = false;
        roomUser.OnSecondMic = false;

        if (this.oldmicrophoneJsonStruct.H) {
            if (this.oldmicrophoneJsonStruct.H.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.HandUp = true;
            }
        }


        if (this.oldmicrophoneJsonStruct.M === roomUser.KullaniciId.toString()) {
            roomUser.OnMainMic = true;
        }

        if (this.oldmicrophoneJsonStruct.M2) {
            if (this.oldmicrophoneJsonStruct.M2.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.OnSecondMic = true;
            }
        }
        this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
        return roomUser;
    }

    getCurrentRoomUserInfos(user: RoomUser, isView: boolean = false): RoomUser {
        let roomUser: RoomUser = new RoomUser();

        roomUser = user;
        roomUser.HandUp = false;
        roomUser.OnMainMic = false;
        roomUser.OnSecondMic = false;

        if (this.oldmicrophoneJsonStruct.H) {
            if (this.oldmicrophoneJsonStruct.H.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.HandUp = true;
            }
        }


        if (this.oldmicrophoneJsonStruct.M === roomUser.KullaniciId.toString()) {
            roomUser.OnMainMic = true;
        }

        if (this.oldmicrophoneJsonStruct.M2) {
            if (this.oldmicrophoneJsonStruct.M2.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.OnSecondMic = true;
            }
        }
        if (isView) {
            this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
            this.roomUserListForViewTheRoom.push(roomUser);
            return;
        }

        // listeye eklenecek kullanıcı listede var mı yok mu diye indexi alınır..
        const roomUserIndex = this.roomUserList.findIndex(f => f.KullaniciId == user.KullaniciId)

        // kullanıcı roomuserlistte yoksa eklenir..
        if (roomUserIndex === -1) {
            this.roomUserList.push(roomUser);
        } else {
            // kullanıcı roomuserlistte varsa bilgisi update edilir..
            let tempRoomUserList = [...this.roomUserList];
            tempRoomUserList[roomUserIndex] = roomUser;
            this.roomUserList = tempRoomUserList;
        }
        this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
        return roomUser;
    }

    getCurrentRoomUserInfosForView(user: RoomUser): RoomUser {
        let roomUser: RoomUser = new RoomUser();
        roomUser = user;
        roomUser.HandUp = false;
        roomUser.OnMainMic = false;
        roomUser.OnSecondMic = false;

        if (this.oldmicrophoneJsonStruct.H) {
            if (this.oldmicrophoneJsonStruct.H.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.HandUp = true;
            }
        }
        if (this.oldmicrophoneJsonStruct.M === roomUser.KullaniciId.toString()) {
            roomUser.OnMainMic = true;
        }
        if (this.oldmicrophoneJsonStruct.M2) {
            if (this.oldmicrophoneJsonStruct.M2.find(f => f === roomUser.KullaniciId.toString())) {
                roomUser.OnSecondMic = true;
            }
        }

        this.roomUserListService.getUserNameForShow(roomUser?.KullaniciId, roomUser?.IkinciKullaniciAdi, roomUser?.KullaniciAdi, roomUser?.RutbeAdi, roomUser?.RutbeDerece);
        this.roomUserListForViewTheRoom.push(roomUser);
        return roomUser;
    }

    getRoomOwnerInfo(): Promise<UserFullInfo> {
        return new Promise((resolve, reject) => {
            let owner = this.indexedDBService.RoomUserListFullInfos.find(f => f.KullaniciId === this.beforeEnterRoomData.OWNER)

            if (owner)
                return resolve(owner);
            else {
                let endpoint = `${environment.apiUrl}api/user/other/info/${this.beforeEnterRoomData.OWNER}`

                return this.http.get(endpoint)
                    .pipe(catchError(error => this.handleError(error)))
                    .toPromise()
                    .then((data: UserFullInfo) => {
                        return resolve(data)
                    });

            }
        })
    }

    sendChatPublishMessage(chatMessage: ChatMessage) {
        try { //ERRORTRACKER
            if (this.utilService.isNullOrUndefined(chatMessage.ChatType))
                chatMessage.ChatType = chatMessage["Chattype"];

            chatMessage.Time = new Date();
            chatMessage.MessageId = this.utilService.guid(); //this.chatService.getMessageTimeString(chatMessage.Time);
            //  this.indexedDBService.setChatMessageLocalDbId(chatMessage);
            this.chatService.textChatOnReceiveRoom(chatMessage);
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(63, err.message);
        }

    }

    closeP2PChatPanel(userid: number) {
        if (!this.indexedDBService.isMyFriend(userid) && this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === userid)) {
            let username: string = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === userid).user.KullaniciAdi;
            this.messagePanelService.ShowPopupMessageWithLocalization('[' + username + '] ' + this.utilService.getKeyWithTag('cantSendMessage'), 0, true);
            this.panelManagerService.removeP2PChatPanel(userid);
            // this.chatMessageService.sendUserSettingMessage(
            //   userid,
            //   [this.dynamicMethodsSendService.CloseP2PChatPanel(this.indexedDBService.userFullInfo.KullaniciId)]
            // );

            let operationMessageForCloseP2PChatPanel = this.dynamicMethodsService.prepareUserSettingMessage(
                userid,
                [this.dynamicMethodsSendService.CloseP2PChatPanel(this.indexedDBService.userFullInfo.KullaniciId)])
            this.dynamicMethodsService.publishDynCloseP2PChatPanel(this.indexedDBService.userFullInfo.KullaniciId, userid, operationMessageForCloseP2PChatPanel);
        }
    }


    getOldHandUserIdList() {
        if (this.oldmicrophoneJsonStruct !== null &&
            this.oldmicrophoneJsonStruct !== undefined &&
            this.oldmicrophoneJsonStruct.H !== null &&
            this.oldmicrophoneJsonStruct.H !== undefined &&
            this.oldmicrophoneJsonStruct.H.length > 0
        )
            return this.oldmicrophoneJsonStruct.H.map(function (h) {
                return parseInt(h);
            });

        return [];
    }

    initchatPanelProperties() {
        try { //ERRORTRACKER
            if (this.chatPanelProperties === null || this.chatPanelProperties === undefined) {
                this.chatPanelProperties = <ChatPanelProps>{
                    Channelname: this.utilService.getUserChannelName(this.indexedDBService.userFullInfo.KullaniciId),
                    UserId: this.indexedDBService.userFullInfo.KullaniciId,
                    UserName: this.indexedDBService.userFullInfo.KullaniciAdi,
                    UserRubeDerece: this.indexedDBService.userFullInfo.RutbeDerece,
                    UserPhotoId: this.indexedDBService.userFullInfo.ProfilResmiId,
                    ChannelSessionId: "",
                    ContactType: ContactType.Chat,
                    MessageSendingType: this.currentMessageSendingType,
                    ChatType: ChatType.SingleChat,
                    IsTopluMesaj: false
                };
            }
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(25, err.message);
        }

    }

    callLeaveRoom(userId: string, roomId: string): Promise<boolean> {
        var data = {
            RoomId: roomId,
            UserId: userId,
        }
        var url = `${environment.apiUrl}api/room/leave`
        return this.http.post<boolean>(url, data).toPromise();
    }

    callEnterRoom(userId: string, roomId: string): Promise<boolean> {
        var data = {
            RoomId: roomId,
            UserId: userId,
        }
        var url = `${environment.apiUrl}api/room/enter`
        return this.http.post<boolean>(url, data).toPromise();
    }

    afterExitRoomOperations(oldRoomId): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                return this.operationsOfBeforeExitRoomComplete(oldRoomId, resolve);
            } catch (error) {
                this.callErrorTracker(10, error); //ERRORTRACKER
                return this.operationsOfBeforeExitRoomComplete(oldRoomId, resolve);
            }

            // Hata çıkarsa tekrar incelenecek
            // try {
            //   return this.operationsOfBeforeExitRoom({
            //     "ClientInfo": null,
            //     "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            //   })
            //     .toPromise()
            //     .then(() => {
            //       return this.operationsOfBeforeExitRoomComplete(oldRoomId, resolve);
            //     })
            //     .catch(error => {
            //       this.callErrorTracker(10, error); //ERRORTRACKER
            //       return this.operationsOfBeforeExitRoomComplete(oldRoomId, resolve);
            //     });
            // }
            // catch (err) {
            //   this.panelManagerService.mainLoading = false;
            //   this.callErrorTracker(11, err.message); //ERRORTRACKER
            //   console.log(err);
            //   return this.operationsOfBeforeExitRoomComplete(oldRoomId, resolve);
            // }
        });

    }

    operationsOfBeforeExitRoomComplete(oldRoomId, resolve) {
        try {
            this.finishAllConference();
            if (resolve) {
                setTimeout(() => {
                    return resolve();
                });
            }
        } catch (err) {
            console.log(err);
            this.panelManagerService.mainLoading = false;
            if (resolve) {
                // wait for leave room operations done
                setTimeout(() => {
                    return resolve();
                });
            }
            this.callErrorTracker(12, err.message);  //ERRORTRACKER
        }
    }

    updateRoomsOnlineCountInfo(){
        this.getRoomUserCount().then((response: RoomCount[]) => {
            if (this.utilService.isNullOrEmtpyObject(response)==false) {
                response.forEach((roomCount: RoomCount) => {
                    const roomToUpdate = this.indexedDBService.Rooms.find((room: Room) => room.I === roomCount.room_id);
                    if (roomToUpdate && roomCount.online_count !== undefined) {
                        roomToUpdate.O = roomCount.online_count;
                    }
                });
                this.callGetRoomsAllOnlineUserCount();
            }
        });
    }
    callHistoryDynamicRoomList(){
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":1
        }
        this.getDynamicRoomListByPagination(body).then((roomJson: RoomJson)=>{
            if (this.utilService.isNullOrEmtpyObject(roomJson.data.R) == false) {
                this.indexedDBService.Rooms = roomJson.data.R
                this.setFavoriteRooms()
                this.roomInfoChangedSub.next(true);
                this.callGetRoomsAllOnlineUserCount();
                this.selectedRoomListType = RoomListType.HISTORYDYNAMIC

                this.totalCountOfRooms = roomJson.count
                if (this.totalCountOfRooms % this.pageSizeForRoomList == 0) {
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList)
                }
                else{
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList) + 1
                }
              
            }
            else{
                this.selectedRoomListType = RoomListType.HISTORYDYNAMIC
                this.isClickedRoomPaginationElement = false
            }
        })
    }

    callCategoryDynamicRoomList(){
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":1
        }
        this.getCategoryDynamicRoomListByPagination(body).then((roomJson: RoomJson)=>{
            if (this.utilService.isNullOrEmtpyObject(roomJson.data.R) == false) {
                this.indexedDBService.Rooms = roomJson.data.R
                this.setFavoriteRooms()
                this.roomInfoChangedSub.next(true);
                this.callGetRoomsAllOnlineUserCount();
                this.selectedRoomListType = RoomListType.CATEGORYDYNAMICROBOTS

                this.totalCountOfRooms = roomJson.count
                if (this.totalCountOfRooms % this.pageSizeForRoomList == 0) {
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList)
                }
                else{
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList) + 1
                }
              
            }
            else{
                this.selectedRoomListType = RoomListType.CATEGORYDYNAMICROBOTS
                this.isClickedRoomPaginationElement = false
            }
        })
    }

    expandRoomlist(pageIndex: number) {
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":pageIndex ? pageIndex : this.pageIndexForRoomList
        }
        this.isClickedRoomPaginationElement = true
        this.getRoomListByPagination(body).then((roomJson: RoomJson)=>{
            if (this.utilService.isNullOrEmtpyObject(roomJson.data.R) == false) {
                this.indexedDBService.Rooms = roomJson.data.R
                this.setFavoriteRooms()
                this.roomInfoChangedSub.next(true);
                this.callGetRoomsAllOnlineUserCount();

                this.totalCountOfRooms = roomJson.count
                if (pageIndex) {
                    this.pageIndexForRoomList = pageIndex
                    this.isClickedRoomPaginationElement = false
                }
                if (this.totalCountOfRooms % this.pageSizeForRoomList == 0) {
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList)
                }
                else{
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList) + 1
                }
              
            }
            else{
                this.isClickedRoomPaginationElement = false
            }
        })
    }

    expandDynamicRoomlist(pageIndex: number) {
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":pageIndex ? pageIndex : this.pageIndexForRoomList
        }
        this.isClickedRoomPaginationElement = true
        this.getDynamicRoomListByPagination(body).then((roomJson: RoomJson)=>{
            if (this.utilService.isNullOrEmtpyObject(roomJson.data.R) == false) {
                this.indexedDBService.Rooms = roomJson.data.R
                this.setFavoriteRooms()
                this.roomInfoChangedSub.next(true);
                this.callGetRoomsAllOnlineUserCount();

                this.totalCountOfRooms = roomJson.count
                if (pageIndex) {
                    this.pageIndexForRoomList = pageIndex
                    this.isClickedRoomPaginationElement = false
                }
                if (this.totalCountOfRooms % this.pageSizeForRoomList == 0) {
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList)
                }
                else{
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList) + 1
                }
            }
            else{
                this.isClickedRoomPaginationElement = false
            }
        })
    }

    expandCategoryDynamicRoomlist(pageIndex: number) {
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":pageIndex ? pageIndex : this.pageIndexForRoomList
        }
        this.isClickedRoomPaginationElement = true
        this.getCategoryDynamicRoomListByPagination(body).then((roomJson: RoomJson)=>{
            if (this.utilService.isNullOrEmtpyObject(roomJson.data.R) == false) {
                this.indexedDBService.Rooms = roomJson.data.R
                this.setFavoriteRooms()
                this.roomInfoChangedSub.next(true);
                this.callGetRoomsAllOnlineUserCount();

                this.totalCountOfRooms = roomJson.count
                if (pageIndex) {
                    this.pageIndexForRoomList = pageIndex
                    this.isClickedRoomPaginationElement = false
                }
                if (this.totalCountOfRooms % this.pageSizeForRoomList == 0) {
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList)
                }
                else{
                    this.allPageCountForRoomList =Math.floor(this.totalCountOfRooms / this.pageSizeForRoomList) + 1
                }
            }
            else{
                this.isClickedRoomPaginationElement = false
            }
        })
    }

    closeRoomPanel() {
        this.closeRoomAIPanel();
        this.leaveFromRoomActions(null).then(() => {
          return this.livekitService.disconnectRoom()
        })
          .catch(error => {
            this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
          })
      }
	  

      loadStaticRoomList() {
        try {
            this.downloadRoomListJsonFileFromServer()
                .then((roomJson: RoomJson) => {
                    let roomList = roomJson.data.R as Array<Room>;
                    this.indexedDBService.Rooms = roomList;
                    this.totalCountOfRooms = roomJson.count;
                    this.selectedRoomListType = RoomListType.STATIC;
                    this.allPageCountForRoomList = Math.ceil(this.totalCountOfRooms / this.pageSizeForRoomList);
                     this.roomInfoChangedSub.next(true);
                    this.setFavoriteRooms();
                })
                .then(() => {
                    this.updateRoomsOnlineCountInfo();
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }
    
    updateRoomList(roomList: Array<Room>) {
        if (roomList.length > 0) {
            roomList.forEach(room => {
                let oldRoom = this.indexedDBService.Rooms.find(f => f.I === room.I);
                if (oldRoom) {
                    Object.assign(oldRoom, room);
                }
            });
        }
        this.roomInfoChangedSub.next(true);
    }

    setFavoriteRooms() {
        if (this.favoriteRooms) {
            this.favoriteRooms.forEach(favRoom => {
                var room = this.indexedDBService.Rooms.find(r => r.I === favRoom.OdaId)
                if (!this.utilService.isNullOrUndefined(room)) {
                    room.F = true;
                } else {
                    this.favoriteRooms = this.favoriteRooms.filter(f => f.OdaId !== favRoom.OdaId);
                }
            })
        }
    }

    setRoomOrderList(roomList?) {
        var rooms: Array<Room> = new Array<Room>();

        if (this.indexedDBService.RoomOrderList.length > 0) {
            this.indexedDBService.RoomOrderList.forEach(roomOrder => {
                rooms.push(roomList.find(r => r.I === roomOrder))
            })

            this.indexedDBService.Rooms = rooms;

        } else {
            this.indexedDBService.Rooms = roomList;
        }
    }

    saveSecondNick(UserId: number, SecondNick: string) {
        let data = {
            'ClientInfo': this.Get_ClientInfo(),
            'UserId': UserId,
            'SecondNick': SecondNick
        };

        let serviceurl = this.ServiceUrl + 'api/user/secondnick';
        return this.http.post<any>(serviceurl, data, this.options)
            .toPromise()
            .then(json => {
                return json;
            })
            .catch(error => {

            });
    }

    public getDefaultFavoriteRooms(): Observable<Array<FavoriteRoom>> {
        const endpoint = this.ServiceUrl + 'GetDefaultFavoriteRooms';
        return this.http.post<Array<FavoriteRoom>>(endpoint, this.Get_ClientInfo(), this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public getBeforeEnterRoomResponse(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'GetBeforeEnterRoomResponse';

        return this.http.post<any>(endpoint, body, this.options)
            .pipe(
                map(res => this.getBeforeEnterRoomResponseLocalData(res)),
                catchError(error => this.handleError(error))
            );
    }

    getBeforeEnterRoomResponseLocalData(responseString): BeforeEnterRoom {

        try {

            if (this.utilService.isNullOrUndefined(responseString))
                return null;

            let beforeEnterRoom = new BeforeEnterRoom();

            let responseJson = JSON.parse(responseString)[0];

            if (responseJson.Result === false)
                return null;

            beforeEnterRoom.RoomInfo = JSON.parse(responseJson.OdaInfo)[0];

            if (this.utilService.isNullOrEmtpyObject(beforeEnterRoom.RoomInfo) === true) {
                return null;
            }

            beforeEnterRoom.RoomInfo.BITIS = new Date(beforeEnterRoom.RoomInfo.BITIS);


            if (this.utilService.isNullOrUndefined(responseJson.OdaOwnerInfo) === false)
                beforeEnterRoom.RoomOwnerInfo = JSON.parse(responseJson.OdaOwnerInfo)[0];

            if (this.utilService.isNullOrUndefined(responseJson.OdaServerInfo) === false)
                beforeEnterRoom.RoomServerInfo = JSON.parse(responseJson.OdaServerInfo)[0];

            beforeEnterRoom.SecondNick = responseJson.IkinciRumuz;
            beforeEnterRoom.RoomOnlineCount = responseJson.RoomOnlineCount;


            if (this.utilService.isNullOrUndefined(responseJson.BanMuteInfo) === false) {

                let banMuteInfoByServer = JSON.parse(responseJson.BanMuteInfo)[0];
                let banMuteInfo = new BanMuteInfo();

                banMuteInfo.HasSiteMute = banMuteInfoByServer.HasSiteMute;
                banMuteInfo.IsOdaBanEnterAllow = banMuteInfoByServer.IsOdaBanGirisleriAktif;
                banMuteInfo.IsSiteBanEnterAllow = banMuteInfoByServer.IsSiteBanGirisleriAktif;

                banMuteInfo.RoomBanList = this.setRoomBanListInfoByServerJson(banMuteInfoByServer.OdaBanList);
                banMuteInfo.SiteBan = this.setSiteBanInfoByServerJson(banMuteInfoByServer.SiteBanList);
                banMuteInfo.RoomMuteList = this.setRoomMuteListInfoByServerJson(banMuteInfoByServer.OdaMuteList);


                beforeEnterRoom.BanMuteInfo = banMuteInfo;
            }

            return beforeEnterRoom;
        } catch (error) {
            console.log(error);
            return null;
        }


    }

    setRoomBanListInfoByServerJson(roomBanListJson: string): { [key: number]: BanInfo } {
        try {
            if (this.utilService.IsNullOrWhitespace(roomBanListJson) === true)
                return;

            var roomBanListByServer: Array<RoomBanListInfo> = JSON.parse(roomBanListJson);

            let roomBanList: { [key: number]: BanInfo } = {};

            roomBanListByServer.forEach(roomBan => {
                roomBanList[roomBan.R] = new BanInfo();
                roomBanList[roomBan.R].StartDate = this.utilService.turkishFullDateStringToJsDate(roomBan.SD);
                roomBanList[roomBan.R].EndDate = this.utilService.turkishFullDateStringToJsDate(roomBan.ED);
                roomBanList[roomBan.R].RoomId = roomBan.R;
            });

            return roomBanList;
        } catch (error) {
            console.log(error)
        }
    }

    setSiteBanInfoByServerJson(siteBanJson: string): BanInfo {

        try {
            if (this.utilService.IsNullOrWhitespace(siteBanJson) === true)
                return;

            var siteBanListByServer: SiteBanListInfo = JSON.parse(siteBanJson)[0];

            let siteBan: BanInfo = new BanInfo();

            siteBan.StartDate = this.utilService.turkishFullDateStringToJsDate(siteBanListByServer.SD);
            siteBan.EndDate = this.utilService.turkishFullDateStringToJsDate(siteBanListByServer.ED);
            siteBan.RoomId = null;

            return siteBan;
        } catch (error) {
            console.log(error)
        }
    }

    setRoomMuteListInfoByServerJson(roomMuteListJson: string): { [key: number]: BanInfo } {
        try {

            if (this.utilService.IsNullOrWhitespace(roomMuteListJson) === true)
                return;


            var roomMuteListByServer: Array<RoomMuteListInfo> = JSON.parse(roomMuteListJson);

            let roomMuteList: { [key: number]: BanInfo } = {};

            roomMuteListByServer.forEach(roomMute => {
                roomMuteList[roomMute.R] = new BanInfo();
                roomMuteList[roomMute.R].StartDate = this.utilService.turkishFullDateStringToJsDate(roomMute.SD);
                roomMuteList[roomMute.R].RoomId = roomMute.R;
            });


            return roomMuteList;

        } catch (error) {
            console.log(error)
        }
    }


    //#region rest service operations

    //SIP ENDPOINTS AREA

    public createSIPParticipant(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/admin/sip/create/participant';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    //END SIP ENDPOINTS AREA

    public getAllDatasNeedsToBeUpdatedAfterEnterRoom(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'GetAllDatasNeedsToBeUpdatedAfterEnterRoom';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public operationsOfBeforeExitRoom(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'OperationsOfBeforeExitRoom';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    //Recording areas
    public startRecording(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/room/start/record';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public stopRecording(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/room/stop/record';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public listRecording(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/room/list/record';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    // end recording
    public stopMicrophone(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/mic/stop';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public joinMicrophone(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/mic/join';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public inviteToJoinMicrophone(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/mic/invite';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public adminHandDown(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/hand/down/admin';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public saveRoomCam(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/cam/start';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public saveRoomScreenShare(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/screenshare/start';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public stopCamera(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/cam/stop';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public stopUserScreenShare(body) {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/screenshare/stop';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error))).toPromise();
    }

    public startMainMicrophone(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/mic/start/main';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public handUp(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/hand/up';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public handDown(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/hand/down';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public getAnimations(): Observable<any> {
        const endpoint = this.ServiceUrl + 'GetAnimations';
        return this.http.post<any>(endpoint, this.Get_ClientInfo(), this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public adminStopUserMicrophone(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/mic/stop/admin';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public adminStopSipUserMicrophone(body): Observable<any> {
        const endpoint = environment.apiUrl + 'api/admin/sip/user/drop/mic';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public saveCamWathcerOnService(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/cam/watch';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public getRoomCameraLimitByUser(body): Observable<any> {

        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/cam/limit';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public getCamInfo(body): Observable<any> {

        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/cam/info';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    callCheckBan(userId: number, roomId: number): Observable<CheckBanResponse> {
        let body = {
            'UserId': userId,
            'OdaId': roomId
        }
        return this.checkBan(body);
    }

    public checkBan(body): Observable<CheckBanResponse> {
        const endpoint = this.ServiceUrl + 'api/ban/check/oda';
        return this.http.post<CheckBanResponse>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public controlRoomBulkMessageLimit(body): Observable<any> {
        /*
   return {
     "ClientInfo": this.Get_ClientInfo(),
     "UserId": userId
  };
  */

        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'admin/message/general/save';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public getGenerelMessageCount(): Observable<any> {
        const endpoint = this.ServiceUrl + 'admin/message/general/count';
        return this.http.post<any>(endpoint, this.Get_ClientInfo(), this.options)
            .pipe(catchError(error => this.handleError(error)));
    }


    public saveConferance(body): Observable<any> {
        /*   return {
      "ClientInfo": _service.Get_ClientInfo(),
      "Conferance": conferance,
      "DeleteHand": deleteHand
   }*/

        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'SaveConferance';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public saveMultipleConferance(body): Observable<any> {
        /*   return {
       "ClientInfo": _service.Get_ClientInfo(),
      "Conferances": conferances,
      "GroupId": groupId
   }*/

        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'SaveMultipleConferance';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public getRoomById(roomId): Observable<any> {

        let body = {
            "ClientInfo": this.Get_ClientInfo(),
            "RoomId": roomId
        }
        const endpoint = this.ServiceUrl + 'api/room/byid';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public errorTracker(body): Observable<any> {
        const endpoint = `${environment.apiUrl}api/error`;
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    //#endregion

    getRoomPassToken(RoomId: number, Pass: string) {
        let data = {
            'ClientInfo': this.Get_ClientInfo(),
            'RoomId': RoomId,
            'Pass': Pass
        };

        let serviceurl = `${this.ServiceUrl}api/room/pass/check`
        return this.http.post<any>(serviceurl, data, this.options)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }

    controlRoomPathParam(RoomId: number, Pass: string) {
        let data = {
            'RoomId': RoomId,
            'Pass': Pass
        };

        let serviceurl = `${this.ServiceUrl}api/check/room/password`
        return this.http.post<any>(serviceurl, data, this.options)
            .toPromise()
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
    }

    downloadRoomListJsonFileFromServer() {
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":this.pageIndexForRoomList
        }
        const endpoint = `${environment.apiUrl}api/room/list`
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0'
            })
        };


        return this.http.post(endpoint,body, options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
            .then(data => {
                if (this.utilService.isNullOrEmtpyString(data))
                    throw "error"
                else
                    return data;
            });
    }

    searchRoomByNameOrId(searchText:string):Promise<RoomJsonData>{
        let body = {
            "searchText":searchText
        }
        const endpoint = `${environment.apiUrl}api/room/search`
        return this.http.post<RoomJsonData>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    callGetRoomsAllOnlineUserCount(){
        this.getRoomsAllOnlineUserCount().then((data:any)=>{
            this.onlineUserCount = data[0].count
        })
    }

    getRoomListByPagination(body):Promise<RoomJson>{
        const endpoint = `${environment.apiUrl}api/room/list`
        return this.http.post<RoomJson>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }
    getDynamicRoomListByPagination(body):Promise<RoomJson>{
        const endpoint = `${environment.apiUrl}api/dynroom/list`
        return this.http.post<RoomJson>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getCategoryDynamicRoomListByPagination(body):Promise<RoomJson>{
        const endpoint = `${environment.apiUrl}api/dynroom/categoryRoomList`
        return this.http.post<RoomJson>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomsAllOnlineUserCount():Promise<any>{
        const endpoint = `${environment.apiUrl}api/all/room/user/count`
        return this.http.get<any>(endpoint,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomUserCount():Promise<RoomCount[]>{
        let body = {
            "pageSize":this.pageSizeForRoomList,
            "pageIndex":this.pageIndexForRoomList
        }
        const endpoint = environment.apiUrl + "api/room/user/count" 
        return this.http.post<RoomCount[]>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }
    
    getFavoriteRooms(username: string = "") {
        return this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsFavoriteRoomsPrivateKey, username)
            .then((favoriteRooms: Array<FavoriteRoom>) => {
                if (this.utilService.isNullOrUndefined(favoriteRooms) && this.indexedDBService.settingsFavoriteRoomsDefaultValue.length === 0) {
                    return this.getDefaultFavoriteRooms()
                        .toPromise()
                        .then((defaultFavoriteRooms: Array<FavoriteRoom>) => {
                            if (defaultFavoriteRooms.length !== 0) {
                                this.favoriteRooms = defaultFavoriteRooms;
                            }
                        });
                } else {
                    this.favoriteRooms = favoriteRooms;
                }
            })
            .then(() => {
                this.indexedDBService.updateSettingJsonObject(this.indexedDBService.settingsFavoriteRoomsPrivateKey, this.favoriteRooms);
                return this.setFavoriteRooms();
            });
    }

    addToFavoriteRooms(roomId: number) {
        let room: Room = this.indexedDBService.Rooms.find(f => f.I === roomId);
        if (this.favoriteRooms) {
            if (this.favoriteRooms.find(f => f.OdaId === roomId) === undefined)
                this.favoriteRooms.push(<FavoriteRoom>{OdaAdi: room.N, OdaId: room.I});
        } else {
            this.favoriteRooms = new Array<FavoriteRoom>();
            this.favoriteRooms.push(<FavoriteRoom>{OdaAdi: room.N, OdaId: room.I});
        }

        this.indexedDBService.Rooms.find(f => f.I === roomId).F = true;
        return this.indexedDBService.updateSettingJsonObject(this.indexedDBService.settingsFavoriteRoomsPrivateKey, this.favoriteRooms);

    }

    removeFromFavoriteRooms(roomId) {
        if (this.favoriteRooms.find(f => f.OdaId === roomId) !== undefined)
            this.favoriteRooms = this.favoriteRooms.filter(f => f.OdaId !== roomId);

        this.indexedDBService.Rooms.find(f => f.I === roomId).F = false;
        return this.indexedDBService.updateSettingJsonObject(this.indexedDBService.settingsFavoriteRoomsPrivateKey, this.favoriteRooms);
    }

    //#region Ban mute operations

    getBanEndTime(startTime, banType) {
        if (banType === BanTypes.OneHour)
            this.utilService.addHourToDate(startTime, 1);
        else if (banType === BanTypes.OneDay)
            this.utilService.addDayToDate(startTime, 1);
        else if (banType === BanTypes.ThreeDay)
            this.utilService.addDayToDate(startTime, 3);
        else if (banType === BanTypes.SevenDay)
            this.utilService.addDayToDate(startTime, 7);
        else if (banType === BanTypes.OneMonth)
            this.utilService.addMonthToDate(startTime, 1);
        else if (banType === BanTypes.UnLimited)
            this.utilService.addYearToDate(startTime, 100);

        return startTime;
    }

    createBanInfo(roomId, startDate, endDate): BanInfo {
        return {RoomId: roomId, StartDate: startDate, EndDate: endDate};
    }

    isInVisibleModActive(): Promise<boolean> {
        return this.canIUseNotVisibleMod()
            .then(result => {
                if (result === true &&
                    (
                        this.indexedDBService.userFullInfo.StateId === StateIdList.Gorunmez ||
                        this.indexedDBService.userFullInfo.StateId === StateIdList.Cevrimdisi
                    )
                )
                    return true;
                else
                    return false;
            })
            .catch(error => {
                return false;
            });
    }

    canIUseNotVisibleMod(): Promise<boolean> {
        return Promise.resolve(this.indexedDBService.userFullInfo.RutbeFullInfo.GORUNMEZ_MOD);
    }

    //#endregion

    //#region room server operations
    setCurrentRoomServerInfoFromResponseJson(roomServerInfo, roomId) {
        try {

            this.roomServerInfoDic[roomServerInfo.ID] = roomServerInfo;
            this.roomServerInfoRoomRelation[roomId] = roomServerInfo.ID;
        } catch (error) {
            console.log(error);
        }
    }

    //#endregion

    //#region microphone operataions

    getMicInfoJsonFormServerAndDoWork() {
        if (this.currentRoom != undefined && this.currentRoom != null && this.currentRoom.Info != undefined && this.currentRoom.Info != null && this.currentRoom.Info.ID != null && this.currentRoom.Info.ID != 0) {
            this.getMicInfoJsonFormServer(this.currentRoom.Info.ID)
                .then((microphoneJsonStruct: MicrophoneJson) => {
                    this.doWorkWithMicInfo(microphoneJsonStruct);
                })
                .catch(error => {
                    console.log(error)
                    this.callErrorTracker(28, error); //ERRORTRACKER
                });
        }
    }

    micJsonOnReceive(microphoneJsonStruct: MicrophoneJson) {
        this.doWorkWithMicInfo(microphoneJsonStruct);
    }

    // #region DO Work With Json Mic Info

    doWorkWithMicInfo(microphoneJsonStruct: MicrophoneJson) {
        this.currentMicrophoneJsonStruct = microphoneJsonStruct;

        if (microphoneJsonStruct === null || microphoneJsonStruct === undefined)
            return;

        if (microphoneJsonStruct.R != this.currentRoom.Info?.ID.toString())
            return;


        var infoStruct = {
            refreshUserList: false,
            micPopupIsOpen: false
        };

        this.doWorkWithMicInfo_MainMicrophone(microphoneJsonStruct, infoStruct)
            .then(() => {
                this.doWorkWithMicInfo_SecondMicrophone(microphoneJsonStruct, infoStruct);
                this.doWorkWithMicInfo_HandList(microphoneJsonStruct, infoStruct);
            })
            // .then(() => {
            //     //bu kısım kaldırılacak
            //     if (infoStruct.refreshUserList === true) {
            //         this.roomUserListChangedSource.next();
            //     }
            // })
            .then(() => {
                // mic user çağrısı
                this.callGetMicUserList()
            })
            .then(() => {
                if (this.selectedUserListType == UserListType.REQUEST ) {
                    //hand user çağrısı
                    this.callGetHandRequestUserList()
                }
                else {
                    if (this.isUserAtMainMic()) {
                        this.checkHandUserList();
                    }
                }
            })
            .catch(error => {
                console.log("doWorkWithMicInfo_MainMicrophone error")
                console.log(error)
                this.callErrorTracker(56, error); //ERRORTRACKER
            });

    }

    doWorkWithMicInfo_MainMicrophone(microphoneJsonStruct: MicrophoneJson, infoStruct): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.oldmicrophoneJsonStruct.M == microphoneJsonStruct.M || (this.utilService.isNullOrEmtpyString(this.oldmicrophoneJsonStruct.M) && (this.utilService.isNullOrEmtpyString(microphoneJsonStruct.M)))) {
                resolve();
                return;
            }
            if (this.oldmicrophoneJsonStruct.M != null &&
                this.oldmicrophoneJsonStruct.M != undefined &&
                this.oldmicrophoneJsonStruct.M != "" &&
                (microphoneJsonStruct.M == null || microphoneJsonStruct.M == undefined || microphoneJsonStruct.M == "")) //mikrofon bırakıldıysa
            {
                this.stopMicrophoneResponse(parseInt(this.oldmicrophoneJsonStruct.M), microphoneJsonStruct.H, infoStruct);
                this.doWorkWithMicInfo_MainMicrophone_SetDatas(microphoneJsonStruct, infoStruct);
                resolve();
                return;
            } else if (
                (this.oldmicrophoneJsonStruct.M === null || this.oldmicrophoneJsonStruct.M === undefined || this.oldmicrophoneJsonStruct.M === "")
                &&
                (microphoneJsonStruct.M !== null && microphoneJsonStruct.M !== undefined && microphoneJsonStruct.M !== "")) //mikrofonu birisi almıştır
            {
                this.startMainMicrophoneResponse(microphoneJsonStruct.M, microphoneJsonStruct.R, false);
                this.doWorkWithMicInfo_MainMicrophone_SetDatas(microphoneJsonStruct, infoStruct);
                resolve();
                return;
            } else if (
                this.oldmicrophoneJsonStruct.M != microphoneJsonStruct.M
                && microphoneJsonStruct.M == this.indexedDBService.userFullInfo.KullaniciId.toString()
                && this.oldmicrophoneJsonStruct.M2.includes(microphoneJsonStruct.M)
            ) {
                this.doWorkWithMicInfo_MainMicrophone_SetDatas(microphoneJsonStruct, infoStruct);
                resolve();
                return;
            } else if (
                this.oldmicrophoneJsonStruct.M != microphoneJsonStruct.M
                && this.oldmicrophoneJsonStruct.M == this.indexedDBService.userFullInfo.KullaniciId.toString()
            ) {
                this.stopMicrophoneResponse(parseInt(this.oldmicrophoneJsonStruct.M), microphoneJsonStruct.H, infoStruct);
                this.doWorkWithMicInfo_MainMicrophone_SetDatas(microphoneJsonStruct, infoStruct);
                resolve();
                return;
            } else  //  oldmicrophoneJsonStruct.M != null && microphoneJsonStruct.M != null.... yani mikrofonu biri bırakmış bir diğeri almıştır bu sırada bıraktığı andaki json dosyasını client alamadan diğer dosya geldiği için bu şekilde olmuştur. o yüzden önce ilk mikrofondakini dinlemeyi bırakıp ikinciyi dinleyeme başlamalı
            {
                if (this.oldmicrophoneJsonStruct.M !== null && this.oldmicrophoneJsonStruct.M !== undefined && this.oldmicrophoneJsonStruct.M !== "") {
                    this.stopMicrophoneResponse(parseInt(this.oldmicrophoneJsonStruct.M), microphoneJsonStruct.H, infoStruct);

                    setTimeout(() => {
                        if (microphoneJsonStruct.M !== null && microphoneJsonStruct.M !== undefined && microphoneJsonStruct.M !== "")
                            this.startMainMicrophoneResponse(microphoneJsonStruct.M, microphoneJsonStruct.R, false);

                        this.doWorkWithMicInfo_MainMicrophone_SetDatas(microphoneJsonStruct, infoStruct);
                        resolve();
                        return;
                    }, 1500);
                } else {
                    resolve();
                    return;
                }
            }
        });
    }

    doWorkWithMicInfo_MainMicrophone_SetDatas(microphoneJsonStruct: MicrophoneJson, infoStruct) {
        try { //ERRORTRACKER

            this.oldmicrophoneJsonStruct.M = microphoneJsonStruct.M;

            infoStruct.refreshUserList = true;
            this.refreshMicIconsWithJsonValue();
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(37, err.message);
        }
    };

    doWorkWithMicInfo_HandList(microphoneJsonStruct: MicrophoneJson, infoStruct) {
        try {
            if (this.oldmicrophoneJsonStruct.H === null)
                this.oldmicrophoneJsonStruct.H = [];

            if (microphoneJsonStruct.H === null)
                microphoneJsonStruct.H = [];

            this.oldmicrophoneJsonStruct.H = microphoneJsonStruct.H;

            if (this.oldmicrophoneJsonStruct.H !== null &&
                this.oldmicrophoneJsonStruct.H !== undefined
            ) {
                if (this.oldmicrophoneJsonStruct.H[0] !== null &&
                    this.oldmicrophoneJsonStruct.H[0] !== undefined &&
                    this.oldmicrophoneJsonStruct.H[0] === this.indexedDBService.userFullInfo.KullaniciId.toString() &&
                    (
                        this.oldmicrophoneJsonStruct.M === null ||
                        this.oldmicrophoneJsonStruct.M === undefined ||
                        this.oldmicrophoneJsonStruct.M === ""
                    )
                ) {
                    setTimeout(() => {
                        this.onayAl();
                    });
                }
            }
            infoStruct.refreshUserList = true;
            this.refreshHandListWithJsonValue();
        } catch (err) {
            console.log(err);
            this.callErrorTracker(46, err.message);  //ERRORTRACKER
        }
    }

    doWorkWithMicInfo_SecondMicrophone(microphoneJsonStruct: MicrophoneJson, infoStruct) {
        try {
            if (JSON.stringify(this.oldmicrophoneJsonStruct.M2) === JSON.stringify(microphoneJsonStruct.M2))
                return;


            if (this.oldmicrophoneJsonStruct.M2 !== null &&
                this.oldmicrophoneJsonStruct.M2 !== undefined &&
                this.oldmicrophoneJsonStruct.M2.find(f => f === this.indexedDBService.userFullInfo.KullaniciId.toString()) !== undefined &&
                (
                    microphoneJsonStruct.M2 === null ||
                    microphoneJsonStruct.M2 === undefined ||
                    microphoneJsonStruct.M2.find(f => f === this.indexedDBService.userFullInfo.KullaniciId.toString()) === undefined
                )
                &&
                (
                    microphoneJsonStruct.M != null
                    && microphoneJsonStruct.M != undefined
                    && microphoneJsonStruct.M != ""
                    && microphoneJsonStruct.M != this.indexedDBService.userFullInfo.KullaniciId.toString()
                )

            ) {
                // ana mikrofondakinin süresi bitmiştir yada benim mikrofonuma son vermiştir yada admin benim mikrofona son vermiştir yada mute, ban, odadan atılma vs..
                this.stopJoinMicrophoneRepsonse();
            } else if (
                (
                    this.oldmicrophoneJsonStruct.M2 === null ||
                    this.oldmicrophoneJsonStruct.M2 === undefined ||
                    this.oldmicrophoneJsonStruct.M2.find(f => f === this.indexedDBService.userFullInfo.KullaniciId.toString()) === undefined
                ) &&
                microphoneJsonStruct.M2 !== null &&
                microphoneJsonStruct.M2 !== undefined &&
                microphoneJsonStruct.M2.find(f => f === this.indexedDBService.userFullInfo.KullaniciId.toString()) !== undefined
            ) {

                // ben ikinci mikrofonu aldıysam
                this.joinMicrophoneRepsonse(false);
            } else {
            }

            this.oldmicrophoneJsonStruct.M2 = microphoneJsonStruct.M2;

            infoStruct.refreshUserList = true;
            this.refreshGuestMicrophoneListWithJsonValue();
        } catch (err) {
            console.log(err);
            this.callErrorTracker(48, err.message);  //ERRORTRACKER
        }
    }

    // #endregion

    // #region Requests

    clickMicrophoneButton(fromHandButton) {
        if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
            this.panelManagerService.guestWarningPopupDisplay = true;
            return
        }
        if (this.indexedDBService.userFullInfo.StateId == 2) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Çevrim Dışı kullanıcı mikrofon alamaz', 3000);
            return
        }
        if (this.isCurentUserJoinedMessageReceived === false) {
            return
        }
        this.clickMicrophoneButtonOperations(fromHandButton);
    }

   async clickMicrophoneButtonOperations(fromHandButton) {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            this.micAccessButtonDisabled = true;

            //mikrofon boştaysa ve el sırasında kimse yoksa
            if (this.oldmicrophoneJsonStruct.M === "" && this.oldmicrophoneJsonStruct.H.length === 0) {
                this.startMicrophoneRequest().finally(() => {
                    this.micAccessButtonDisabled = false;
                });
                return;
            }
    
            //mikrofon bendeyse
            if (this.isUserAtMainMic() || this.isUserAtOtherMic()) {
                if (fromHandButton === false) {
                    this.stopMicrophoneRequest().then(() => {
                        this.micAccessButtonDisabled = false;
                        this.livekitService.isOnMic = false
                        this.livekitService.allSenderAudioTrackAreMuted = true
                        this.livekitService.isMicEnabled = false;
                    }).then(() => {
                        if (this.livekitService.isOnScreenSharing) {
                            this.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info?.ID, false, false);
                        }
                    })
                    .then(() => {
                        if (this.livekitService.isOnSharingTabMusic) {
                            this.livekitService.toggleShareScreenAudio(false);
                        }
                    }).then(() => {
                        if (this.livekitService.isCamOpen) {
                            this.stopCam(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info.ID, false, false)
                        }
                    })
                } else {
                    this.micAccessButtonDisabled = false;
                }
                return;
            }
    
            //el kaldırma
            if (this.isUserAtHands() === false) {
                this.handUpRequest().finally(() => {
                    this.micAccessButtonDisabled = false;
                });
                return;
            }
    
            //el indirme
            if (fromHandButton === true && this.isUserAtHands()) {
                this.handDownRequest().finally(() => {
                    this.micAccessButtonDisabled = false;
                });
                return
            }
    
            this.micAccessButtonDisabled = false;
        } catch (err) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Mikrofona çıkmak için mikrofon izinlerini vermeniz gerekiyor', 3000);
        }
    }

    startMicrophoneRequest(): Promise<any> {
        // return this.startMainMicrophone({
        //   'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
        //   'RoomId': this.currentRoom.Info?.ID.toString()
        // }).toPromise();
        const res = this.startMainMicrophone({
            'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
            'RoomId': this.currentRoom.Info?.ID.toString()
        }).toPromise().then((response) => {
            if (response == "OK") {
                this.livekitService.toggleAudio(true);
            }
        })
        return res;
    }

    stopMicrophoneRequest(): Promise<void> {
        //backend tarafında yapılması test edilecek
        //https://docs.livekit.io/realtime/server/managing-participants/
        // this.livekitService.toggleAudio(false);
        return this.stopMicrophone({
            'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
            'RoomId': this.currentRoom.Info?.ID.toString()
        }).toPromise()
    }

    inviteToMicrophoneRequest(inviteUserId) {
        try {
            this.inviteToJoinMicrophone({
                'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
                'RoomId': this.currentRoom.Info?.ID.toString(),
                'Session': this.indexedDBService.userFullInfo.Token,
                "InviteUserId": inviteUserId.toString()
            })
                .toPromise()
                .then(result => {
                    if (this.utilService.IsNullOrWhitespace(result) === false) {
                        // this.chatMessageService.sendUserSettingMessage(inviteUserId,
                        //   [this.dynamicMethodsSendService.InviteToMicrophone(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info?.ID, result)]
                        // );
                        let operationMessageForInviteToMicrophone = this.dynamicMethodsService.prepareUserSettingMessage(
                            inviteUserId,
                            [this.dynamicMethodsSendService.InviteToMicrophone(this.indexedDBService.userFullInfo.KullaniciId, this.currentRoom.Info?.ID, result)]
                        )
                        this.dynamicMethodsService.publishDynInviteToMicrophone(this.indexedDBService.userFullInfo.KullaniciId, inviteUserId, operationMessageForInviteToMicrophone);
                    } else {
                        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    }
                })
                .catch(error => {
                    this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                });
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }


    joinMicrophoneRequest(inviteKey) {
        this.joinMicrophone({
            'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
            'RoomId': this.currentRoom.Info?.ID.toString(),
            "InviteKey": inviteKey
        })
            .toPromise()
            .then(result => {
                if (this.utilService.isNullOrEmtpyObject(result) === true || result !== "OK") {
                    this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    return;
                } else {
                    this.livekitService.toggleAudio(true);
                }
            })
            .catch(error => {
                console.log(error);
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            });
    }

    handUpRequest(): Promise<any> {
        return this.handUp({
            'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
            'RoomId': this.currentRoom.Info?.ID.toString(),
            'Session': this.indexedDBService.userFullInfo.Token
        })
            .toPromise()
            .then(response => {
                if (this.utilService.isNullOrEmtpyObject(response) === true || response !== "OK") {
                    this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                } else {
                    this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                        this.localizationService.getKeyWithTag("HandUp"),
                        ChatType.RoomActionMessage
                    );
                }
            })
            .catch(error => {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            });
    }

    handDownRequest(): Promise<any> {
        return this.handDown({
            'UserId': this.indexedDBService.userFullInfo.KullaniciId.toString(),
            'RoomId': this.currentRoom.Info?.ID.toString(),
            'Session': this.indexedDBService.userFullInfo.Token
        })
            .toPromise()
            .then(response => {
                if (this.utilService.isNullOrEmtpyObject(response) === true || response !== "OK") {
                    this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                } else {
                    this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                        this.localizationService.getKeyWithTag("HandDown"),
                        ChatType.RoomActionMessage
                    );
                }
            })
            .catch(error => {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            });
    }

    stopUserMicrophoneRequest(stopMicrophoneUserId, roomId): Promise<any> {
        return new Promise((resolve, reject) => {
            // this.chatMessageService.sendUserSettingMessage(stopMicrophoneUserId, [this.dynamicMethodsSendService.DropMic()])
            let operationMessageForDropMic = this.dynamicMethodsService.prepareUserSettingMessage(
                stopMicrophoneUserId, [this.dynamicMethodsSendService.DropMic()]
            )
            this.dynamicMethodsService.publishDynDropMic(this.indexedDBService.userFullInfo.KullaniciId, stopMicrophoneUserId, operationMessageForDropMic)
                .then(() => {
                    return this.adminStopUserMicrophone({
                        'UserId': stopMicrophoneUserId.toString(),
                        'RoomId': roomId.toString(),
                        'Session': this.indexedDBService.userFullInfo.Token,
                        "RequesterUserId": this.indexedDBService.userFullInfo.KullaniciId.toString()
                    }).toPromise()
                })
                .then(response => {
                    /*if (this.utilService.isNullOrEmtpyObject(response) === true || response !== "OK") {
            //this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            return resolve(null);
          }*/
                    return resolve(null);
                })
                .catch(error => {
                    console.log(error)
                    //this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    return resolve(null);
                });
        })

    }

    stopSipUserMicrophoneRequest(stopMicrophoneUserId, roomId): Promise<any> {
        return new Promise((resolve, reject) => {
            // this.chatMessageService.sendUserSettingMessage(stopMicrophoneUserId, [this.dynamicMethodsSendService.DropMic()])
            let operationMessageForDropMic = this.dynamicMethodsService.prepareUserSettingMessage(
                stopMicrophoneUserId, [this.dynamicMethodsSendService.DropMic()]
            )
            this.dynamicMethodsService.publishDynDropMic(this.indexedDBService.userFullInfo.KullaniciId, stopMicrophoneUserId, operationMessageForDropMic)
                .then(() => {
                    return this.adminStopSipUserMicrophone({
                        'UserId': stopMicrophoneUserId.toString(),
                        'RoomId': roomId.toString(),
                        'Session': this.indexedDBService.userFullInfo.Token,
                        "RequesterUserId": this.indexedDBService.userFullInfo.KullaniciId.toString()
                    }).toPromise()
                })
                .then(response => {
                    /*if (this.utilService.isNullOrEmtpyObject(response) === true || response !== "OK") {
            //this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            return resolve(null);
          }*/
                    return resolve(null);
                })
                .catch(error => {
                    console.log(error)
                    //this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    return resolve(null);
                });
        })

    }

    // #endregion

    // #region After Response Actions

    joinMicrophoneRepsonse(reTry) {
        try { //ERRORTRACKER
            if (this.oldmicrophoneJsonStruct !== null &&
                this.oldmicrophoneJsonStruct !== undefined
            )
                this.changeMicrophoneState(true);
            //this.roomUserListChangedSource.next();

            this.startInvitedMicrophoneUserSenderAudio(reTry);
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(52, err.message);
        }
    }

    stopJoinMicrophoneRepsonse() {
        this.startReceiverAudio(this.currentRoom.Info?.ID);
        this.changeMicrophoneState(false);
    }

    startMainMicrophoneResponse(conferenceUserId: string, roomId: string, reTry) {
        if (conferenceUserId == this.indexedDBService.userFullInfo.KullaniciId.toString())
            this.startMainMicSenderAudio(roomId, reTry);
    };

    stopMicrophoneResponse(userId: number, handList: Array<string>, infoStruct) {
        if (userId == this.indexedDBService.userFullInfo.KullaniciId) {
            this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                this.localizationService.getKeyWithTag("DropedMicrophone"),
                ChatType.RoomActionMessage
            );
            this.stopMicrophoneResponseInternal(infoStruct, handList);
        }
    }

    stopMicrophoneResponseInternal(infoStruct, handList) {
        this.changeMicrophoneState(false);

        if (this.utilService.isNullOrEmtpyObject(handList) == true || handList.length == 0)
            return;

        if (handList[0].I == this.indexedDBService.userFullInfo.KullaniciId) {
            infoStruct.micPopupIsOpen = true;
            this.onayAl();
        }
    }

    changeMicrophoneState(isOnMe) {
        this.microphoneStateChangedSource.next(isOnMe === true ? MicrophoneState.Started : MicrophoneState.Stopped);
    }

    onayAl() {
        this.micAccessButtonDisabled = false;
        this.panelManagerService.roomMicrophoneAcceptPopupDisplay = true;
    }

    // #endregion

    // #region Stop

    finishAllConference() {
        this.changeMicrophoneState(false);
    }

    // #endregion

    // #region Start Microphone

    startMainMicSenderAudio(roomId: string, reTry) {
        if (reTry === false) {
            this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                this.localizationService.getKeyWithTag("TakedMicrophone"),
                ChatType.RoomActionMessage
            );
        }
        this.openAudioForm(
            roomId,
            true
        ).catch(error => {
            this.callErrorTracker(43, error);  //ERRORTRACKER
        })
    }


    startInvitedMicrophoneUserSenderAudio(reTry) {
        this.openAudioForm(
            this.currentRoom.Info?.ID,
            true
        ).catch(error => {
            console.log(error);
            this.callErrorTracker(54, error);  //ERRORTRACKER
        })
    }

    startReceiverAudio(roomId: number) {
        return this.openAudioForm(
            roomId.toString(),
            false
        );
    }

    openAudioForm(roomId, isClientSender) {
        if (isClientSender === true)
            this.changeMicrophoneState(true);

        return this.startAudioConference(
            roomId,
            isClientSender
        )
    }

    // #endregion

    // #region Helpers

    getMicInfoJsonFormServer(roomID: number): Promise<MicrophoneJson> {
        const endpoint = environment.apiUrl + "api/room/mic/" + roomID;

        return this.http.get<MicrophoneJson>(endpoint)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }


    refreshHandListWithJsonValue() {
        if (this.roomUserList === null)
            return;


        this.roomUserList.forEach(user => {
            user.HandUp = false;
        });

        if (this.oldmicrophoneJsonStruct.H === null ||
            this.oldmicrophoneJsonStruct.H === undefined ||
            this.oldmicrophoneJsonStruct.H.length <= 0)
            return;

        this.roomUserList.forEach(user => {
            if (this.oldmicrophoneJsonStruct.H.find(f => f === user.KullaniciId.toString()) !== undefined)
                user.HandUp = true;
        });
    };

    refreshGuestMicrophoneListWithJsonValue() {
        if (this.roomUserList === null || this.roomUserList === undefined)
            return;

        this.roomUserList.forEach(user => {
            user.OnSecondMic = false;
        });

        if (this.oldmicrophoneJsonStruct.M2 === null ||
            this.oldmicrophoneJsonStruct.M2 === undefined ||
            this.oldmicrophoneJsonStruct.M2.length <= 0)
            return;

        this.roomUserList.forEach(user => {
            if (this.oldmicrophoneJsonStruct.M2.find(f => f === user.KullaniciId.toString()) !== undefined)
                user.OnSecondMic = true;
        });
    }

    refreshMicIconsWithJsonValue() {
        if (this.roomUserList === null || this.roomUserList === undefined || this.roomUserList.length <= 0)
            return;

        this.roomUserList.forEach(user => {
            user.OnMainMic = false;
        });

        if (this.oldmicrophoneJsonStruct.M === null || this.oldmicrophoneJsonStruct.M === undefined || this.oldmicrophoneJsonStruct.M === "")
            return;

        this.roomUserList.forEach(user => {
            if (user.KullaniciId.toString() == this.oldmicrophoneJsonStruct.M) {
                user.OnMainMic = true;
            }
        });
    }

    // #endregion


    // #region Dynamic Methods For Received Command

    afterBanActionsForReceivedCommand(userId, roomId, banPlaces, banOrMute) {
        try {
            if (userId !== this.indexedDBService.userFullInfo.KullaniciId)
                return;

            if (banPlaces === BanPlaces.Room && roomId !== this.currentRoom.Info?.ID)//roomId=null is siteban
                return;

            this.panelManagerService.closeAdminPage();
            if (banOrMute === BanOrMute.Ban) {
                if (!this.indexedDBService.redisRootSettings.OdaBanGirisleri) { // oda ban girişleri kapalıysa kullanıcıyı odadan at
                    this.leaveFromRoomActions(null).then(() => {
                        return this.livekitService.disconnectRoom()
                    });
                } else {
                    this.stopMicrophoneAndCam();
                    this.stopAllCams();
                    let operationMessageForShowHideUserBanIconUserInList = this.dynamicMethodsService.prepareRoomSettingMessage(
                        this.currentRoom.Info?.ID,
                        [this.dynamicMethodsSendService.ShowHideUserBanIconUserInList(userId, banPlaces, true)]
                    )
                    this.dynamicMethodsService.publishDynShowHideUserBanIconUserInList(this.currentRoom.Info.ID, operationMessageForShowHideUserBanIconUserInList)
                }
            } else {
                this.stopMicrophoneAndCam();
                this.stopAllCams();
                let operationMessageForShowHideUserMuteIconUserInList = this.dynamicMethodsService.prepareRoomSettingMessage(
                    this.currentRoom.Info?.ID,
                    [this.dynamicMethodsSendService.ShowHideUserMuteIconUserInList(userId, banPlaces, true)]
                )
                this.dynamicMethodsService.publishDynShowHideUserMuteIconUserInList(this.currentRoom.Info.ID, operationMessageForShowHideUserMuteIconUserInList)
            }

            if (banPlaces === BanPlaces.Site) {
                this.signOut();
            }
            //update current user info
            this.callGetRoomUserByUserId()

        } catch (error) {
            // try to singout on every chance
            if (banPlaces === BanPlaces.Site) {
                this.signOut();
            }

            console.log(error);
        }
    }

    callLogout(): Promise<any> {
        const endpoint = environment.apiUrl + "api/logout";
        return this.http.post<any>(endpoint, null).toPromise();
    }

    connectionProblemAndReconnect() {
        this.confirmationService.confirm({
            message: this.translateService.instant('confirm-to-reconnect') ,
            header: this.translateService.instant('reconnect-room'),
            icon: null,
            accept: () => {
              this.reconnectRoom(this.currentRoom.Info?.ID);
            },
            reject: () => {
                this.leaveFromRoomActions(null).then(() => {
                    this.roomCameraUserList = []
                    return this.livekitService.disconnectRoom()
                  })
                    .catch(error => {
                      this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
                    })
            }
          });
    }
    
    signOut() {
        this.callLogout()
            .then(() => {
                this.panelManagerService.mainLoading = false;
                console.log(`LOG OUT WORKED`)

                // var existingSubscription = this.centrifugeClient.getSubscription(`user:userid#${this.indexedDBService.userFullInfo.KullaniciId}`)
                // if (this.natsConnection) {
                //   existingSubscription?.unsubscribe()
                //   this.centrifugeClient.removeSubscription(existingSubscription)
                // }
                // this.centrifugeClient.disconnect();
                this.natsConnection.close();


                if (this.currentRoom && this.currentRoom != null && this.currentRoom.Info && this.currentRoom.Info != null) {

                    var operationMessageForRefreshRoomUserListForAllUsersInRoom = this.dynamicMethodsService.prepareRoomSettingMessage(this.currentRoom.Info?.ID,
                        [this.dynamicMethodsSendService.RefreshRoomUserListForAllUsersInRoom()]
                    );
                    this.dynamicMethodsService.publishDynRefreshRoomUserListForAllUsersInRoom("room:" + this.currentRoom.Info?.ID, operationMessageForRefreshRoomUserListForAllUsersInRoom)
                }

                this.storageService.storageClear();
                document.location.reload();

                this.panelManagerService.mainLoading = true;
                this.panelManagerService.activeMainPanel = MainPanels.None;

                this.indexedDBService.clearUserLocalDb();
                this.indexedDBService.clearUserLastLoginInfo();

                this.panelManagerService.mainLoading = false;
                this.panelManagerService.mainLoading = false;

                if (this.roomListRefreshInterval)
                    clearInterval(this.roomListRefreshInterval);

                this.roomListRefreshInterval = undefined;

                if (this.heartbeatInterval != undefined) {
                    clearInterval(this.heartbeatInterval)
                    this.heartbeatInterval = undefined;
                }

                console.log(`LOG OUT DONE`)
            })
            .catch((err) => {
                console.log(`ERROR ON LOGOUT - ERROR: ${JSON.stringify(err)}`)
            })
            .finally(() => {
                this.indexedDBService.authenticated = false;
                this.panelManagerService.mainLoading = false;
                console.log(`LOG OUT FINALLY WORKED`)
            })
    }

    invalidateAuthenticationAndSignOut() {
        this.panelManagerService.mainLoading = false;
        console.log(`LOG OUT WORKED`)
/*
        var existingSubscription = this.centrifugeClient.getSubscription(`user:userid#${this.indexedDBService.userFullInfo.KullaniciId}`)
        if (existingSubscription) {
            existingSubscription?.unsubscribe()
            this.centrifugeClient.removeSubscription(existingSubscription)
        }
        this.centrifugeClient.disconnect();
  */
        this.storageService.storageClear();
        document.location.reload();

        this.panelManagerService.mainLoading = true;
        this.panelManagerService.activeMainPanel = MainPanels.None;

        this.indexedDBService.clearUserLocalDb();
        this.indexedDBService.clearUserLastLoginInfo();

        this.panelManagerService.mainLoading = false;
        this.panelManagerService.mainLoading = false;

        if (this.roomListRefreshInterval)
            clearInterval(this.roomListRefreshInterval);

        this.roomListRefreshInterval = undefined;

        if (this.heartbeatInterval != undefined) {
            clearInterval(this.heartbeatInterval)
            this.heartbeatInterval = undefined;
        }
    }

    afterRemoveBanMuteActionsForReceivedCommand(userId, roomId, banPlaces, banOrMute) {
        try {
            if (userId !== this.indexedDBService.userFullInfo.KullaniciId)
                return;

            if (banPlaces === BanPlaces.Room && roomId !== this.currentRoom.Info?.ID)//roomId=null is siteban
                return;

            var userSettingMessage;

            if (banOrMute === BanOrMute.Ban) {
                userSettingMessage = [this.dynamicMethodsSendService.ShowHideUserBanIconUserInList(userId, banPlaces, false)];
                // this.chatMessageService.sendRoomSettingMessage(this.currentRoom.Info?.ID, userSettingMessage);
                let operationMessageForShowHideUserBanIconUserInList = this.dynamicMethodsService.prepareRoomSettingMessage(
                    this.currentRoom.Info?.ID, userSettingMessage
                )
                this.dynamicMethodsService.publishDynShowHideUserBanIconUserInList(this.currentRoom.Info?.ID, operationMessageForShowHideUserBanIconUserInList)
            }
            this.callGetRoomUserByUserId()

        } catch (error) {
            console.log(error);
        }
    }

    finishCamForReceivedCommand(roomId) {
        if (roomId !== this.currentRoom.Info?.ID)
            return;

        this.stopCam(this.indexedDBService.userFullInfo.KullaniciId, roomId, false, true);
    }

    kickFromRoomReceivedCommand(userId, roomId, roomDeleted: boolean = false) {
        try {
            if (userId !== this.indexedDBService.userFullInfo.KullaniciId)
                return;

            if (!roomDeleted) {
                this.messagePanelService.ShowPopupMessageWithLocalization('UserKickedOut', 2000);
            }

            this.leaveFromRoomActions(null).then(() => {
                return this.livekitService.disconnectRoom()
            });
        } catch (error) {
            console.log(error);
        }
    }

    showHideUserBanMuteIconUserInListReceivedCommand(userId, banPlace, show, banOrMute) {
        try {
            // var user = this.getRoomUserById(userId);
            var user = this.getRoomUserFromActiveListByUserId(userId);
            if (user === undefined)
                return;

            if (banOrMute === BanOrMute.Ban) {
                if (banPlace === BanPlaces.Room)
                    user.BanOda = show;
                else if (banPlace === BanPlaces.Site)
                    user.BanSite = show;
            } else {
                if (banPlace === BanPlaces.Room)
                    user.MuteOda = show;
                else if (banPlace === BanPlaces.Site)
                    user.MuteSite = show;
            }

        } catch (error) {
            console.log(error);
        }
        // this.roomUserListChangedSource.next();
        this.refreshCurrentUserListPage();
    }

    showHideUserCamIconUserInList(userId, sessionId, contactType, show) {

        try {
            // var user = this.getRoomUserById(userId);
            var user = this.getRoomUserFromActiveListByUserId(userId);
            if (user === undefined)
                return;

            if (show === true) {
                if (this.utilService.IsNullOrWhitespace(user.RoomCamSession) === false && user.RoomCamSession !== Constants.guidEmpty)
                    return;

                user.RoomCamSession = sessionId;
            } else {
                if (this.utilService.IsNullOrWhitespace(user.RoomCamSession) === true || user.RoomCamSession === Constants.guidEmpty)
                    return;

                user.RoomCamSession = null;
            }

        } catch (error) {
            console.log(error);
        }
        // this.roomUserListChangedSource.next();
        this.refreshCurrentUserListPage();
    }

    getRoomUserById(userId) {
        return this.roomUserList.find(f => f.KullaniciId === userId);
    }

    createUserCamAndScreenShareList() {
        this.micUserList.forEach(roomUser => {
            const isUserAlreadyInList = this.roomCameraUserList.some(user => user.userId === roomUser.KullaniciId);
            if (!isUserAlreadyInList) {
                const roomCamera: RoomCamera = new RoomCamera();
                roomCamera.userId = roomUser.KullaniciId;
                roomCamera.userName = roomUser.KullaniciAdi;
                roomCamera.roomId = this.currentRoom.Info.ID || 0;
                roomCamera.videoContainer = document.createElement('video');
                roomCamera.isScreen = false;
                this.roomCameraUserList.push(roomCamera);
            }
        });
        this.joinRoomSource.next(this.roomCameraUserList)

    }

    removeRoomContentDiv() {
        this.panelManagerService.closeRoomPage();
        this.currentRoom = new CurrentRoom();
    }

    // #endregion

    //#region room camera operations

    openCameraNew(isScreen: boolean) {

        if (this.indexedDBService.userFullInfo.StateId == 2) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Çevrim Dışı kullanıcı kamera açamaz', 3000);
            return
        }
        if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
            this.panelManagerService.guestWarningPopupDisplay = true;
            return
        }
        // try {
        //     await navigator.mediaDevices.getUserMedia({ video: true });
        // } catch (error) {
        //     this.messagePanelService.ShowPopupMessageWithLocalization('Kameraya çıkmak için kamera izinlerini vermeniz gerekiyor', 3000);
        //     return;
        // }
        if (!(this.isUserAtMainMic() || this.isUserAtOtherMic())) {
            this.clickMicrophoneButton(false)
            return
        }
        if (this.cameraButtonClicked)
            return;

        this.cameraButtonClicked = true;

        setTimeout(() => {
            this.cameraButtonClicked = false;
        }, 1000);

        if (this.isMyCamOpened)
            this.focusMyCamera();
        else {
            this.startCam(isScreen)
        }

    }

    openScreenShare() {
        if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
            this.panelManagerService.guestWarningPopupDisplay = true;
            return
        }

        if (this.isUserAtMainMic() || this.isUserAtOtherMic()) {
            this.startScreenShare();
        } else {
            this.clickMicrophoneButton(false)
        }

    }

    focusMyCamera() {
        //TODO
    }

    startCam(isScreen: boolean) {
        var icelinkSession = this.utilService.guid();

        this.saveRoomCam({
            "ClientInfo": null,
            "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            "RoomId": this.currentRoom.Info?.ID,
            "Open": true,
            "IcelinkSession": icelinkSession
        })
            .toPromise()
            .then(response => {
                this.startCamSuccess(response, icelinkSession, isScreen);
            })
            .catch(error => {
                console.log(error)
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            });
    }

    startCamSuccess(response, icelinkSession, isScreen) {
        try {
            if (response !== true) {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                return;
            }


            // this.chatMessageService.sendRoomSettingMessage(this.currentRoom.Info?.ID,
            //   [
            //     this.dynamicMethodsSendService.ShowHideUserCamIconUserInList(
            //       this.indexedDBService.userFullInfo.KullaniciId,
            //       icelinkSession,
            //       this.utilService.getEnumKeyString(ContactType, ContactType.Video),
            //       true
            //     )
            //   ]
            // );

            // let operationMessageForShowHideUserCamIconUserInList = this.dynamicMethodsService.prepareRoomSettingMessage(
            //     this.currentRoom.Info?.ID,
            //     [
            //         this.dynamicMethodsSendService.ShowHideUserCamIconUserInList(
            //             this.indexedDBService.userFullInfo.KullaniciId,
            //             icelinkSession,
            //             this.utilService.getEnumKeyString(ContactType, ContactType.Video),
            //             true
            //         )
            //     ]
            // )
            // this.dynamicMethodsService.publishDynShowHideUserCamIconUserInList(this.currentRoom.Info.ID, operationMessageForShowHideUserCamIconUserInList)

            this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                this.localizationService.getKeyWithTag("OpenCam"),
                ChatType.RoomActionMessage
            );

            //TODO artık böyle kullanılacak
            this.roomCameraCountUpdatedSource.next(<RoomCameraCount>{I: this.currentRoom.Info?.ID, C: 1});


            if (this.livekitService.videoDevices.length == 0) {
                this.livekitService.handleDevicesChanged();
            }
            this.livekitService.toggleVideo(true).then((res: boolean) => {
                if (res) {
                    this.getRoomCameraComponentById(this.indexedDBService.userFullInfo.KullaniciId).then(element => {
                        element.classList.remove("hidden-component")
                    })
                }
            })

            this.isMyCamOpened = true;
        } catch (error) {
            console.log(error)
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
        }
    }

    startScreenShare() {
        if (this.indexedDBService.userFullInfo.StateId == 2) {
            this.messagePanelService.ShowPopupMessageWithLocalization('Çevrim Dışı kullanıcı ekran paylaşamaz', 3000);
            return
        }
        var icelinkSession = this.utilService.guid();
        this.saveRoomScreenShare({
            "ClientInfo": null,
            "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            "RoomId": this.currentRoom.Info?.ID,
            "Open": true,
            "IcelinkSession": icelinkSession
        })
            .toPromise()
            .then(response => {
                this.startScreenShareSuccess(response);
            })
            .catch(error => {
                console.log(error)
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            });
    }

    startScreenShareSuccess(response) {
        try {

            if (response !== true) {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                return;
            }
            this.livekitService.isOnScreenSharing = true
            this.roomCameraCountUpdatedSource.next(<RoomCameraCount>{I: this.currentRoom.Info?.ID, C: 1});

            this.livekitService.toggleScreenShare(true).then((res: boolean) => {
                if (res) {
                    // this.getRoomScreenShareComponentById(this.indexedDBService.userFullInfo.KullaniciId).then(element=>{
                    //   element.classList.remove("hidden-component")
                    // })
                    this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                        this.localizationService.getKeyWithTag("start-screen-share"),
                        ChatType.RoomActionMessage
                    );
                } else {
                    this.livekitService.isOnScreenSharing = false
                    this.finishScreenShare(this.currentRoom.Info?.ID, this.indexedDBService.userFullInfo.KullaniciId)
                }
            })
        } catch (error) {
            console.log(error)
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
        }
    }

    startAudioConference(roomId: string, isClientSender: boolean) {

        return this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsAudioDeviceIdPrivateKey)
            .then(deviceId => {

            })
    }

    getRoomCameraComponentById(userId: number): Promise<HTMLElement> {
        return new Promise((resolve, reject) => {
            let id = Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + userId
            let element = <HTMLElement>document.getElementById(id);
            if (element) {
                resolve(element)
            }
        })
    }

    getRoomScreenShareComponentById(userId: number): Promise<HTMLElement> {
        return new Promise((resolve, reject) => {
            let id = Constants.mainSwiperTag + '' + MainPanels.ScreenShare + '_' + userId
            let element = <HTMLElement>document.getElementById(id);
            if (element) {
                resolve(element)
            }
        })
    }

    stopCam(camUserId: number, roomId: number, camUserStopOwnCam: boolean, dropCamByAnotherUser: boolean) {
        if (this.indexedDBService.userFullInfo.KullaniciId === camUserId) {
            this.livekitService.toggleVideo(false)
            } 
        this.getRoomCameraComponentById(camUserId).then(element => {
            if (element !== undefined) {
                element.classList.add("hidden-component")
            }
        })
        this.panelManagerService.removeRoomVideoPanel(camUserId);

        if (this.utilService.isNullOrEmtpyObject(roomId) === true) {
            roomId = this.currentRoom.Info?.ID;
        }
        if (this.indexedDBService.userFullInfo.KullaniciId === camUserId) {
            this.finishCameraConference(roomId, camUserId);
        }

        if (camUserId === this.indexedDBService.userFullInfo.KullaniciId) {
            //odada kameramı izleyen video panelini kapatsın diye mesaj atılır

            // this.chatMessageService.sendRoomSettingMessage(this.currentRoom.Info?.ID,
            //   [
            //     this.dynamicMethodsSendService.ShowHideUserCamIconUserInList(
            //       camUserId,
            //       Constants.guidEmpty,
            //       this.utilService.getEnumKeyString(ContactType, ContactType.Video),
            //       false
            //     )
            //   ]
            // );

            // let operationMessageForShowHideUserCamIconUserInList = this.dynamicMethodsService.prepareRoomSettingMessage(
            //     this.currentRoom.Info?.ID,
            //     [
            //         this.dynamicMethodsSendService.ShowHideUserCamIconUserInList(
            //             camUserId,
            //             Constants.guidEmpty,
            //             this.utilService.getEnumKeyString(ContactType, ContactType.Video),
            //             false
            //         )
            //     ]
            // )
            // this.dynamicMethodsService.publishDynShowHideUserCamIconUserInList(this.currentRoom.Info.ID, operationMessageForShowHideUserCamIconUserInList)

            // this.chatMessageService.sendRoomSettingMessage(roomId,
            //   [this.dynamicMethodsSendService.CloseRoomVideoPanel(camUserId)]
            // );

            let operationMessageForCloseRoomVideoPanel = this.dynamicMethodsService.prepareRoomSettingMessage(
                roomId,
                [this.dynamicMethodsSendService.CloseRoomVideoPanel(camUserId)]
            )
            this.dynamicMethodsService.publishDynCloseRoomVideoPanel(roomId, operationMessageForCloseRoomVideoPanel)

            if (dropCamByAnotherUser !== true) {
                this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                    this.localizationService.getKeyWithTag("CloseCam"),
                    ChatType.RoomActionMessage
                );
            }

            //TODO artık böyle kullanılacak
            this.roomCameraCountUpdatedSource.next(<RoomCameraCount>{I: this.currentRoom.Info?.ID, C: -1});
        } else {
            if (camUserStopOwnCam === true)//kamerasını izlediğin kişi kamerasını kapattıysa
                return;

            // if (this.utilService.isNullOrEmtpyObject(conference) === true)
            //   return;

            // let camsession = this.roomUserList.find(f => f.KullaniciId === camUserId).RoomCamSession; __test amaçlı kapatıldı

            this.saveCamWathcerOnService({
                "ClientInfo": null,
                "UserId": this.indexedDBService.userFullInfo.KullaniciId,
                "CamUserId": camUserId,
                "RoomId": roomId,
                "Watch": false,
                "IcelinkSession": ""
            })
                .toPromise()
                .then(response => {
                    if (response === true) {
                        // this.chatMessageService.sendUserSettingMessage(camUserId,
                        //   [
                        //     this.dynamicMethodsSendService.AddRemoveCamWatcher(
                        //       this.indexedDBService.userFullInfo.KullaniciId,
                        //       this.indexedDBService.userFullInfo.KullaniciAdi,
                        //       this.indexedDBService.userFullInfo.StateId,
                        //       this.indexedDBService.userFullInfo.RutbeId,
                        //       false
                        //     )
                        //   ]
                        // );

                        let operationMessageForAddRemoveCamWatcher = this.dynamicMethodsService.prepareUserSettingMessage(
                            camUserId,
                            [
                                this.dynamicMethodsSendService.AddRemoveCamWatcher(
                                    this.indexedDBService.userFullInfo.KullaniciId,
                                    this.indexedDBService.userFullInfo.KullaniciAdi,
                                    this.indexedDBService.userFullInfo.StateId,
                                    this.indexedDBService.userFullInfo.RutbeId,
                                    false
                                )
                            ]
                        )
                        this.dynamicMethodsService.publishDynAddRemoveCamWatcher(this.indexedDBService.userFullInfo.KullaniciId, camUserId, operationMessageForAddRemoveCamWatcher);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        this.isMyCamOpened = false;
    }

    stopScreenShare(camUserId: number, roomId: number, camUserStopOwnCam: boolean, dropCamByAnotherUser: boolean) {
        this.livekitService.closeScreenSharefromComponent = true
        if (this.indexedDBService.userFullInfo.KullaniciId === camUserId) {
            this.finishScreenShare(roomId, camUserId).then(response => {
                if (response === true) {
                    if (this.indexedDBService.userFullInfo.KullaniciId === camUserId) {
                        this.livekitService.toggleScreenShare(false)
                        this.livekitService.isOnScreenSharing = false
                        this.livekitService.closeScreenSharefromComponent = false
                    }
                }
                this.stopScreenShareSuccess(response);
            });
        }

        this.getRoomScreenShareComponentById(camUserId).then(element => {
            if (element !== undefined) {
                element.classList.add("hidden-component")
            }
        })
        if (this.utilService.isNullOrEmtpyObject(roomId) === true) {
            roomId = this.currentRoom.Info?.ID;
        }
        if (camUserId === this.indexedDBService.userFullInfo.KullaniciId) {

            // this.chatMessageService.sendRoomSettingMessage(roomId,
            //   [this.dynamicMethodsSendService.CloseScreenShareVideoPanel(camUserId)]
            // );

            let operationMessageForCloseScreenShareVideoPanel = this.dynamicMethodsService.prepareRoomSettingMessage(
                roomId,
                [this.dynamicMethodsSendService.CloseScreenShareVideoPanel(camUserId)]
            )
            this.dynamicMethodsService.publishDynCloseScreenShareVideoPanel(roomId, operationMessageForCloseScreenShareVideoPanel)
            if (dropCamByAnotherUser !== true) {
                this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                    this.localizationService.getKeyWithTag("stop-screen-share"),
                    ChatType.RoomActionMessage
                );
            }
            this.roomCameraCountUpdatedSource.next(<RoomCameraCount>{I: this.currentRoom.Info?.ID, C: -1});
        } else {
            if (camUserStopOwnCam === true)
                return;
        }

        this.isMyCamOpened = false;
    }

    stopScreenShareFromLivekit(camUserId: number, roomId: number, camUserStopOwnCam: boolean, dropCamByAnotherUser: boolean) {
        if (this.indexedDBService.userFullInfo.KullaniciId === camUserId) {
            this.finishScreenShare(roomId, camUserId).then(response => {
                if (response === true) {
                    if (this.indexedDBService.userFullInfo.KullaniciId === camUserId) {
                        this.livekitService.isOnScreenSharing = false
                        this.livekitService.closeScreenSharefromComponent = false
                    }
                }
                this.stopScreenShareSuccess(response);
            });
        }

        this.getRoomScreenShareComponentById(camUserId).then(element => {
            if (element !== undefined) {
                element.classList.add("hidden-component")
            }
        })
        if (this.utilService.isNullOrEmtpyObject(roomId) === true) {
            roomId = this.currentRoom.Info?.ID;
        }
        if (camUserId === this.indexedDBService.userFullInfo.KullaniciId) {

            // this.chatMessageService.sendRoomSettingMessage(roomId,
            //   [this.dynamicMethodsSendService.CloseScreenShareVideoPanel(camUserId)]
            // );

            let operationMessageForCloseScreenShareVideoPanel = this.dynamicMethodsService.prepareRoomSettingMessage(
                roomId,
                [this.dynamicMethodsSendService.CloseScreenShareVideoPanel(camUserId)]
            )
            this.dynamicMethodsService.publishDynCloseScreenShareVideoPanel(roomId, operationMessageForCloseScreenShareVideoPanel)

            if (dropCamByAnotherUser !== true) {
                this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                    this.localizationService.getKeyWithTag("stop-screen-share"),
                    ChatType.RoomActionMessage
                );
            }
            this.roomCameraCountUpdatedSource.next(<RoomCameraCount>{I: this.currentRoom.Info?.ID, C: -1});
        } else {
            if (camUserStopOwnCam === true)
                return;
        }
    }

    finishCameraConference(roomId, camUserId) {
        this.stopCamera({
            "ClientInfo": null,
            "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            "RoomId": roomId,
            "ContacType": ContactType.VideoAndAudio,
        })
            .toPromise()
            .then(response => {
                this.stopCamSuccess(response);
            })
            .catch(error => {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            });

    }

    finishScreenShare(roomId, camUserId) {
        return this.stopUserScreenShare({
            "ClientInfo": null,
            "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            "RoomId": roomId
        })
    }

    stopCamSuccess(response) {
        if (response !== true) {
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            return;
        }
    }

    stopScreenShareSuccess(response) {
        if (response !== true) {
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            return;
        }
    }


    stopAllCams() {
        Object.keys(this.panelManagerService.roomVideoPanels).forEach(key => {
            let roomCamera = this.panelManagerService.roomVideoPanels[key] as RoomCamera;
            this.stopCam(roomCamera.userId, roomCamera.roomId, false, false);
        });
    }


    // #endregion

    // #region Start Video Conference Operations


    getUserNameForShow(roomUser) {

        if (this.utilService.IsNullOrWhitespace(roomUser.IkinciKullaniciAdi) === true)
            return roomUser.KullaniciAdi;

        if (roomUser.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId)
            return roomUser.IkinciKullaniciAdi + "(" + roomUser.KullaniciAdi + ")";

        if (roomUser.RutbeDerece < this.indexedDBService.userFullInfo.RutbeFullInfo.DERECE)
            return roomUser.IkinciKullaniciAdi + "(" + roomUser.KullaniciAdi + ")";

        return roomUser.IkinciKullaniciAdi;
    }

    removeVideoPanel(userId) {
        try { //ERRORTRACKER
            this.panelManagerService.removeRoomVideoPanel(userId);
        } catch (err) {
            console.log(err.message)
            this.callErrorTracker(62, err.message);  //ERRORTRACKER
        }
    }

    // #endregion

    saveBulkMessageCountAndControl(): Promise<void> {
        try {
            if (this.currentMessageSendingType === MessageSendingType.GenelAdmin || this.currentMessageSendingType === MessageSendingType.GenelHerkes) {
                this.panelManagerService.mainLoading = true;
                return new Promise((resolve, reject) => {
                    this.getGenerelMessageCount()
                        .toPromise()
                        .then(messageCount => {
                            if (messageCount >= 0) {
                                this.panelManagerService.mainLoading = false;
                                return this.askBeforeSendGeneralMessage(messageCount === 0 ? "∞" : messageCount)
                                    .then(() => {
                                        return this.controlRoomBulkMessageLimit({
                                            "ClientInfo": null,
                                            "UserId": this.indexedDBService.userFullInfo.KullaniciId
                                        })
                                            .toPromise();
                                    })
                                    .then(successResponse => {
                                        resolve();
                                        return successResponse;
                                    })
                                    .catch(error => {
                                        reject();
                                    });
                            } else {
                                this.messagePanelService.ShowPopupMessageWithLocalization('GeneralMessageLimit', 2000);
                                reject();
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            reject();
                        })
                        .finally(() => {
                            this.panelManagerService.mainLoading = false;
                        });
                })
            } else {
                return Promise.resolve();
            }
        } catch (error) {
            console.log(error);
        }

    }

    askBeforeSendGeneralMessage(messageLimit): Promise<void> {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: this.translateService.instant('SendGeneralMessageWarningMessage') + " " + messageLimit,
                header: this.translateService.instant("SendGeneralMessageWarningTitle"),
                icon: null,
                accept: () => {
                    resolve();
                },
                reject: () => {
                    reject();
                }
            });
        });

    }

    watchUserCam(camUserId: number) {
        if (camUserId === this.indexedDBService.userFullInfo.KullaniciId)
            return; //kendi kameranı izleyemezsin

        if (this.panelManagerService.roomVideoPanels.find(f => f.userId === camUserId) !== undefined)
            return; //kamera zaten izleniyor

        this.getRoomCameraLimitByUser({
            "ClientInfo": null,
            "UserId": this.indexedDBService.userFullInfo.KullaniciId
        })
            .toPromise()
            .then(camLimitResponse => {
                this.getRoomCameraLimitByUserSuccess(camLimitResponse, camUserId);
            })
            .catch(error => {
                console.log(error);
            });

    };

    watchUserScreenShare(camUserId: number) {
        this.getRoomScreenShareComponentById(camUserId).then(element => {
            element.classList.remove("hidden-component")
        })
    };

    getRoomCameraLimitByUserSuccess(camLimitResponse, camUserId) {
        this.getCamInfo({
            "ClientInfo": null,
            "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            "CamUserId": camUserId,
            "RoomId": this.currentRoom.Info?.ID,
        })
            .toPromise()
            .then(camInfoResponse => {
                this.getCamInfoSuccess(camInfoResponse, camUserId);
            })
            .catch(error => {
                console.log(error);
            });
    }

    getCamInfoSuccess(camInfoResponse, camUserId) {
        if (camInfoResponse.Result === true) {
            // let user = this.getRoomUserById(camUserId);
            let user = this.micUserList.find(u => u.KullaniciId === camUserId);

            // this.panelManagerService.openRoomVideoPanel(<RoomCamera>{
            //   userId: camUserId,
            //   icelinkSession: camInfoResponse.IcelinkSession,
            //   userName: user.KullaniciAdi,
            //   roomId: this.currentRoom.Info?.ID
            // });
            this.getRoomCameraComponentById(camUserId).then(element => {
                element.classList.remove("hidden-component")
            })

            //this.panelManagerService.cameraLoading = true;
            this.saveCamWathcer(camUserId, camInfoResponse.IcelinkSession);
        }
    }

    saveCamWathcer(camUserId: number, icelinkSession: string) {
        this.saveCamWathcerOnService({
            "ClientInfo": this.Get_ClientInfo(),
            "UserId": this.indexedDBService.userFullInfo.KullaniciId,
            "CamUserId": camUserId,
            "RoomId": this.currentRoom.Info?.ID,
            "Watch": true,
            "IcelinkSession": icelinkSession,
        })
            .toPromise()
            .then(response => {
                if (response === true) {
                    // return this.chatMessageService.sendUserSettingMessage(camUserId,
                    //   [
                    //     this.dynamicMethodsSendService.AddRemoveCamWatcher(
                    //       this.indexedDBService.userFullInfo.KullaniciId,
                    //       this.indexedDBService.userFullInfo.KullaniciAdi,
                    //       this.indexedDBService.userFullInfo.StateId,
                    //       this.indexedDBService.userFullInfo.RutbeId,
                    //       true
                    //     )
                    //   ]
                    // )

                    let operationMessageForAddRemoveCamWatcher = this.dynamicMethodsService.prepareUserSettingMessage(
                        camUserId,
                        [
                            this.dynamicMethodsSendService.AddRemoveCamWatcher(
                                this.indexedDBService.userFullInfo.KullaniciId,
                                this.indexedDBService.userFullInfo.KullaniciAdi,
                                this.indexedDBService.userFullInfo.StateId,
                                this.indexedDBService.userFullInfo.RutbeId,
                                true
                            )
                        ]
                    )
                    return this.dynamicMethodsService.publishDynAddRemoveCamWatcher(this.indexedDBService.userFullInfo.KullaniciId, camUserId, operationMessageForAddRemoveCamWatcher);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                //this.panelManagerService.cameraLoading = false;
            });
    }


    addRemoveCamWatcher(userId, add) {
        if (add)
            this.roomCameraWatcherAddedSource.next(userId);
        else
            this.roomCameraWatcherRemovedSource.next(userId);
    }

    isUserVisible(state, rutbeDerece, userId) {

        if (userId === this.indexedDBService.userFullInfo.KullaniciId || //kendisi herzaman görünür
            rutbeDerece === 0 ||
            (         //başkası ise
                rutbeDerece < this.indexedDBService.userFullInfo.RutbeFullInfo.DERECE || //rütbesi büyükse her zman görür

                //rütbesi küçükse     çevrimdışı yada görünmez degilse görür
                (state !== StateIdList.Cevrimdisi && state !== StateIdList.Gorunmez)
            )
        )
            return true;

        return false;
    }

    //#endregion

    banMuteControl(): Promise<any> {
        return this.isInVisibleModActive()
            .then((isInVisibleModActive) => {
                if (isInVisibleModActive === true) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('InvisibleModeWarning', 2000);
                    throw "InvisibleModeWarning";
                }
            })
            .then(() => {
                return this.saveBulkMessageCountAndControl();
            })
            .then(() => {
                console.log(`this.currentRoom.Info : ${JSON.stringify(this.currentRoom.Info)}`)
                console.log(`this.currentRoom.OwnerInfo : ${JSON.stringify(this.currentRoom.OwnerInfo)}`)
                console.log(`this.indexedDBService.userFullInfo : ${JSON.stringify(this.indexedDBService.userFullInfo)}`)
                if (this.currentRoom.Info?.SESSIZ === true) {
                    if (this.currentRoom.OwnerInfo === null ||
                        this.currentRoom.OwnerInfo === undefined ||
                        this.currentRoom.OwnerInfo.KullaniciId !== this.indexedDBService.userFullInfo.KullaniciId) {
                        if (this.indexedDBService.userFullInfo.RutbeFullInfo.SESSIZ_SESLI === false) {
                            this.messagePanelService.ShowPopupMessageWithLocalization('RoomIsQuiteWarning', 2000);
                            throw "RoomIsQuiteWarning";
                        }
                    }
                }
            })
            .then(() => {
                return true;
            });
    }

    //recording room area
    recordRoomTrack(isAudioOnly: boolean) {
        if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
            this.panelManagerService.guestWarningPopupDisplay = true;
            return
        }
        this.callStartRecording(isAudioOnly).then((response) => {
            this.isRoomRecordingStarted = true
        }).then(() => {
            this.messagePanelService.ShowPopupMessageWithLocalization('record-started-success', 2000);
        })
            .then(() => {
                this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                    this.localizationService.getKeyWithTag("record-started"),
                    ChatType.RoomActionMessage
                );
            })
            .catch(() => {
                this.messagePanelService.ShowPopupMessageWithLocalization('record-started-fail', 2000);
            }).finally(() => {
            this.panelManagerService.controlOfRecordingPopupDisplay = false
        })
    }

    stopRecordRoomTrack() {
        this.callListRecordings(true, 5, 1).then((response: RoomRecord[]) => {
            const lastRoom = response[0];
            const lastEgressId = lastRoom.EgressID;
            this.recordId = lastEgressId
        })
            .then(() => {
                this.callStopRecording(this.recordId).then((response) => {
                    this.messagePanelService.ShowPopupMessageWithLocalization('record-stopped-success', 2000);
                    this.isRoomRecordingStarted = false
                })
            })
            .then(() => {
                this.chatMessageService.sendAdminMessageToOneRoom(this.currentRoom.Info?.ID,
                    this.localizationService.getKeyWithTag("record-stopped"),
                    ChatType.RoomActionMessage
                );
            }).catch(() => {
            this.messagePanelService.ShowPopupMessageWithLocalization('record-stopped-fail', 2000);
        })
    }

    startCallPhoneNumber(phoneNumber: string) {
        this.callCreateSIPParticipant(phoneNumber).then((response) => {
            this.messagePanelService.ShowPopupMessageWithLocalization('start-calling', 2000);
        })
            .catch(() => {
                this.messagePanelService.ShowPopupMessageWithLocalization('calling-failed', 2000);
            }).finally(() => {
            this.panelManagerService.phoneCallingPopupDisplay = false
        })
    }

    generateParticipantId(phoneNumber: string): string {
        return 'sip_ ' + phoneNumber;
    }

    private generateGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    //SIP CALLING

    callCreateSIPParticipant(phoneNumber: string) {
        let body = {
            "callNumber": phoneNumber,
            "roomId": this.currentRoom.Info.ID.toString(),
            "participantId": this.generateParticipantId(phoneNumber)
        }
        return this.createSIPParticipant(body).toPromise()
    }

    //SIP CALLING END
    callStartRecording(isAudioOnly: boolean) {
        let body = {
            "roomId": this.currentRoom.Info.ID,
            "isAudioOnly": isAudioOnly,
        }
        return this.startRecording(body).toPromise()
    }

    callStopRecording(recordId: string) {
        let body = {
            "recordId": recordId
        }
        return this.stopRecording(body).toPromise()
    }

    callListRecordings(isActive: boolean, pageSize: number, pageIndex: number): Promise<RoomRecord[]> {
        let body = {
            "roomId": this.currentRoom.Info.ID,
            "isActive": isActive,
            "pageSize": pageSize,
            "pageIndex": pageIndex
        }

        return new Promise((resolve, reject) => {
            this.listRecording(body)
                .subscribe((response: RoomRecord[]) => {
                    if (this.utilService.isNullOrEmtpyString(response) === false) {
                        resolve(response);
                    } else
                        reject();
                }, (error: any) => {
                    reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    //recording room area
    sendMessageToRoom(messageHtml): Promise<any> {
        // if (this.roomUserList.find(user => user.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId)) {
        //     if (this.utilService.isNullOrEmtpyString(messageHtml))
        //         return Promise.reject("CannotSendEmptyMessage");

        //     if (this.currentMessageChatType !== ChatType.RoomActionMessage ||
        //         this.currentMessageSendingType === MessageSendingType.GenelHerkes ||
        //         this.currentMessageSendingType === MessageSendingType.GenelAdmin) {
        //         return this.saveBulkMessageCountAndControl()
        //             .then(() => {
        //                 return this.sendMessageToRoomOperations(messageHtml)
        //             })
        //     }

        // } else {
        //     this.messagePanelService.ShowPopupMessageWithLocalization("Odaya mesaj gönderebilmek için kullanıcı listenizi yenileyin!", 2000);
        //     return;
        // }
            if (this.utilService.isNullOrEmtpyString(messageHtml))
                return Promise.reject("CannotSendEmptyMessage");

            if (this.currentMessageChatType !== ChatType.RoomActionMessage ||
                this.currentMessageSendingType === MessageSendingType.GenelHerkes ||
                this.currentMessageSendingType === MessageSendingType.GenelAdmin) {
                return this.saveBulkMessageCountAndControl()
                    .then(() => {
                        return this.sendMessageToRoomOperations(messageHtml)
                    })
            }
    }
    sendFileMessageToRoom(messageHtml,fileObject:FileMessageData): Promise<any> {
            if (this.currentMessageChatType !== ChatType.RoomActionMessage ||
                this.currentMessageSendingType === MessageSendingType.GenelHerkes ||
                this.currentMessageSendingType === MessageSendingType.GenelAdmin) {
                return this.saveBulkMessageCountAndControl()
                    .then(() => {
                        return this.sendFileMessageToRoomOperations(messageHtml,fileObject)
                    })
            }
    }
    sendMessageToRoomOperations(messageHtml): Promise<any> {
        if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
            this.panelManagerService.guestWarningPopupDisplay = true;
            return
        }

        var chatMessage: ChatMessage;
        return Promise.resolve()
            .then(() => {
                this.chatPanelProperties.MessageSendingType = this.currentMessageSendingType;
                this.chatPanelProperties.ChatType = this.currentMessageChatType;
                chatMessage = this.chatService.getChatMessageForRoom(
                    this.chatPanelProperties,
                    messageHtml,
                    messageHtml,
                    this.currentRoom.Info?.ID,
                    this.indexedDBService.userFullInfo
                )
                chatMessage.MessageType = this.currentMessageType;
                chatMessage.RtfText = this.chatService.clearFakeSpans(chatMessage.RtfText);
                chatMessage.RtfText = this.chatService.replaceImageTagToImageSymbols(chatMessage.RtfText);
                chatMessage.RoomNameWhereTheMessageSent = this.currentRoom.Info?.NAME;
                chatMessage.RoomIdWhereTheMessageSent = this.currentRoom.Info?.ID;

                if (this.chatService.controlHTmlIsOk(chatMessage.RtfText) === false) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('RoomTextMessageLimitMessage', 2000);
                    throw "RoomTextMessageLimitMessage"
                }

                chatMessage.Time = new Date();
                // chatMessage.Time = this.utilService.addServerTimeDistanceToDate(chatMessage.Time, this.indexedDBService.serverTimeDistance); // mesajı gönerirken server time ile pc time arasındaki fark eklenerek time server time a ayarlanır ve giden mesajlar her zmaan server time olmalıdır. mesajı alan kullanıcı kendi pc timena göre yeniden set edip ekrana öyle basacaktır.

                if (this.indexedDBService.userFullInfo.ProfilResmiId && !this.utilService.isNullOrEmtpyString(this.indexedDBService.userFullInfo.ProfilResmiId))
                    chatMessage.UserPhotoId = this.indexedDBService.userFullInfo.ProfilResmiId.toString().toLocaleLowerCase();

                chatMessage.MessageSendingType = this.chatPanelProperties.MessageSendingType;

                if (chatMessage.MessageSendingType === MessageSendingType.GenelHerkes ||
                    chatMessage.MessageSendingType === MessageSendingType.GenelAdmin)
                    chatMessage.MessageBubbleType = MessageBubbleType.GeneralMessage;
                else {
                    if (chatMessage.ChatType === ChatType.RoomActionMessage)
                        chatMessage.MessageBubbleType = MessageBubbleType.RoomActionMessage;
                    else
                        chatMessage.MessageBubbleType = MessageBubbleType.RoomMe;
                }
                //  chatMessage.MessageBubbleType = this.chatService.setChatMessageBubbleType(chatMessage);
            })
            .then(() => {
                if (!this.indexedDBService.userFullInfo.NicktypeForRoom) {
                    this.indexedDBService.userFullInfo.NicktypeForRoom = new Nicktype();
                }
                chatMessage.NickType = this.indexedDBService.userFullInfo.NicktypeForRoom;
                chatMessage.MessageId = this.utilService.guid(); //this.chatService.getMessageId(chatMessage);
                //  this.chatService.setChatMessageLocalDbId(chatMessage);
            })
            .then(() => {
                let chatMessageForSend = Object.assign({}, chatMessage);

                if (chatMessageForSend.MessageSendingType === MessageSendingType.GenelAdmin ||
                    chatMessageForSend.MessageSendingType === MessageSendingType.GenelHerkes
                ) {
                    chatMessageForSend.MessageBubbleType = MessageBubbleType.GeneralMessage;
                    chatMessageForSend.Channelname = Constants.SYSTEMCHANNEL;
                    // chatMessageForSend.BulkMessageIdForGenerateChannell = Constants.BulkMessageUnnecessaryId;
                    chatMessageForSend.BulkMessageType = BulkMessageType.AllRooms;
                    this.natsService.publishPublicSettingMessage(chatMessageForSend);
                }

                var hasMute = false;
                if ((this.utilService.isNullOrEmtpyObject(this.indexedDBService.userFullInfo.MuteOdaList) === false &&
                        this.indexedDBService.userFullInfo.MuteOdaList.find(f => f === this.currentRoom.Info.ID)) ||
                    this.indexedDBService.userFullInfo.MuteSite || (this.isUserhasMuteInThisRoomOrSite())) {
                    hasMute = true;
                }

                if (hasMute && chatMessage.ChatType === ChatType.RoomActionMessage) {
                    this.natsService.publishRoomChatMessage(this.currentRoom.Info?.ID, chatMessageForSend);
                }
                if (!hasMute) {
                    if (chatMessageForSend.MessageSendingType !== MessageSendingType.GenelAdmin &&
                        chatMessageForSend.MessageSendingType !== MessageSendingType.GenelHerkes) {
                        this.natsService.publishRoomChatMessage(this.currentRoom.Info?.ID, chatMessageForSend);
                    }
                }
                if (this.currentMessageType == MessageType.Animation) {
                    this.setEditorAsDefault();
                }
                return Promise.resolve();
            })
            .catch(error => {
                console.log(error);
                this.callErrorTracker(9, error); //ERRORTRACKER
            })
    }

    sendFileMessageToRoomOperations(messageHtml,fileObject:FileMessageData): Promise<any> {
        if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
            this.panelManagerService.guestWarningPopupDisplay = true;
            return
        }

        var chatMessage: ChatMessage;
        return Promise.resolve()
            .then(() => {
                this.chatPanelProperties.MessageSendingType = this.currentMessageSendingType;
                this.chatPanelProperties.ChatType = this.currentMessageChatType;
                chatMessage = this.chatService.getChatMessageForRoom(
                    this.chatPanelProperties,
                    messageHtml,
                    messageHtml,
                    this.currentRoom.Info?.ID,
                    this.indexedDBService.userFullInfo
                )

                chatMessage.MessageType = this.currentMessageType;
                chatMessage.RtfText = this.chatService.clearFakeSpans(chatMessage.RtfText);
                chatMessage.RtfText = this.chatService.replaceImageTagToImageSymbols(chatMessage.RtfText);
                chatMessage.RoomNameWhereTheMessageSent = this.currentRoom.Info?.NAME;
                chatMessage.RoomIdWhereTheMessageSent = this.currentRoom.Info?.ID;
                //file properties
                chatMessage.IsDocs = fileObject.isFile
                chatMessage.IsImage = fileObject.isImage 
                chatMessage.URL = fileObject.url 
                chatMessage.RtfText = fileObject.path

                if (this.chatService.controlHTmlIsOk(chatMessage.RtfText) === false) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('RoomTextMessageLimitMessage', 2000);
                    throw "RoomTextMessageLimitMessage"
                }

                chatMessage.Time = new Date();
                // chatMessage.Time = this.utilService.addServerTimeDistanceToDate(chatMessage.Time, this.indexedDBService.serverTimeDistance); // mesajı gönerirken server time ile pc time arasındaki fark eklenerek time server time a ayarlanır ve giden mesajlar her zmaan server time olmalıdır. mesajı alan kullanıcı kendi pc timena göre yeniden set edip ekrana öyle basacaktır.

                if (this.indexedDBService.userFullInfo.ProfilResmiId && !this.utilService.isNullOrEmtpyString(this.indexedDBService.userFullInfo.ProfilResmiId))
                    chatMessage.UserPhotoId = this.indexedDBService.userFullInfo.ProfilResmiId.toString().toLocaleLowerCase();

                chatMessage.MessageSendingType = this.chatPanelProperties.MessageSendingType;

                if (chatMessage.MessageSendingType === MessageSendingType.GenelHerkes ||
                    chatMessage.MessageSendingType === MessageSendingType.GenelAdmin)
                    chatMessage.MessageBubbleType = MessageBubbleType.GeneralMessage;
                else {
                    if (chatMessage.ChatType === ChatType.RoomActionMessage)
                        chatMessage.MessageBubbleType = MessageBubbleType.RoomActionMessage;
                    else
                        chatMessage.MessageBubbleType = MessageBubbleType.RoomMe;
                }
                //  chatMessage.MessageBubbleType = this.chatService.setChatMessageBubbleType(chatMessage);
            })
            .then(() => {
                if (!this.indexedDBService.userFullInfo.NicktypeForRoom) {
                    this.indexedDBService.userFullInfo.NicktypeForRoom = new Nicktype();
                }
                chatMessage.NickType = this.indexedDBService.userFullInfo.NicktypeForRoom;
                chatMessage.MessageId = this.utilService.guid(); //this.chatService.getMessageId(chatMessage);
                //  this.chatService.setChatMessageLocalDbId(chatMessage);
            })
            .then(() => {
                let chatMessageForSend = Object.assign({}, chatMessage);

                if (chatMessageForSend.MessageSendingType === MessageSendingType.GenelAdmin ||
                    chatMessageForSend.MessageSendingType === MessageSendingType.GenelHerkes
                ) {
                    chatMessageForSend.MessageBubbleType = MessageBubbleType.GeneralMessage;
                    chatMessageForSend.Channelname = Constants.SYSTEMCHANNEL;
                    // chatMessageForSend.BulkMessageIdForGenerateChannell = Constants.BulkMessageUnnecessaryId;
                    chatMessageForSend.BulkMessageType = BulkMessageType.AllRooms;
                    this.natsService.publishPublicSettingMessage(chatMessageForSend);
                }

                var hasMute = false;

                if ((this.utilService.isNullOrEmtpyObject(this.indexedDBService.userFullInfo.MuteOdaList) === false &&
                        this.indexedDBService.userFullInfo.MuteOdaList.find(f => f === this.currentRoom.Info.ID)) ||
                    this.indexedDBService.userFullInfo.MuteSite) {
                    hasMute = true;
                }

                if (hasMute && chatMessage.ChatType === ChatType.RoomActionMessage) {
                    this.natsService.publishRoomChatMessage(this.currentRoom.Info?.ID, chatMessageForSend);
                }
                if (!hasMute) {
                    if (chatMessageForSend.MessageSendingType !== MessageSendingType.GenelAdmin &&
                        chatMessageForSend.MessageSendingType !== MessageSendingType.GenelHerkes) {
                        this.natsService.publishRoomChatMessage(this.currentRoom.Info?.ID, chatMessageForSend);
                    }
                }
                if (this.currentMessageType == MessageType.Animation) {
                    this.setEditorAsDefault();
                }
                return Promise.resolve();
            })
            .catch(error => {
                console.log(error);
                this.callErrorTracker(9, error); //ERRORTRACKER
            })
    }

    setEditorAsDefault() {
        this.currentMessageSendingType = MessageSendingType.Normal;
        this.messageSendingTypeImgUrl = MessageSendingTypeImgUrl.Normal;
        this.currentMessageChatType = ChatType.SingleChat;
        this.currentMessageType = MessageType.Text;
    }

    // #region Open Admin Panel From Context Menu Operations

    getOpenableAdminPanelTabList(): Promise<any> {
        return new Promise((resolve, reject) => {
          let tabs = {};
          const userRole = this.indexedDBService.userFullInfo.RutbeAdi;
      
          if (this.utilService.isNullOrEmtpyObject(this.indexedDBService.userFullInfo.RutbeFullInfo)) {
            resolve(tabs);
            return;
          }
          this.roleAccessService.loadPermissions().subscribe(
            (tabPermissions) => {
              const availableTabs = tabPermissions[userRole] || [];
              availableTabs.forEach((tab) => {
                if (this.shouldIncludeTab(tab) && this.roleAccessService.canOpenTab(userRole, tab)) {
                  tabs[tab] = true;
                }
              });
              this.controlForUserIsAnyRoomOwner(tabs);
              resolve(tabs);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      
     shouldIncludeTab(tab: string): boolean {
        if (tab === 'adminTab_roomAITemplate' || tab === 'adminTab_roomAIPrompts') {
          if (!this.environmentVariable.IsAiActive) {
            return false;
          }
        }
        if (tab === 'adminTab_subAuthors') {
          if (this.indexedDBService.userFullInfo.AuthorizableUserLimit <= 0) {
            return false;
          }
        }
        return true;
      }

     controlForUserIsAnyRoomOwner(tabs) {
        if (this.indexedDBService.UserIsAnyRoomOwner) {
            tabs['adminTab_rooms'] = true;
        }
      }

    // #endregion


    setRoomSetting(roomId: number) {
        let oldOwnerId = this.currentRoom.Info?.OWNER;
        this.getRoomById(roomId)
            .toPromise()
            .then(data => {
                var newRoomInfo = data;
                newRoomInfo.BASLANGIC = this.utilService.newDate(newRoomInfo.BASLANGIC);
                newRoomInfo.BITIS = this.utilService.newDate(newRoomInfo.BITIS);

                // this.currentRoom.OwnerInfo = newRoomInfo["OWNERINFO"];

                // delete newRoomInfo["OWNERINFO"];
                // delete newRoomInfo["HESAP"];
                let newOwnerId = newRoomInfo.OWNER;
                if (oldOwnerId !== newOwnerId)
                    this.roomOwnerChangedSource.next(newOwnerId);

                this.currentRoom.Info = newRoomInfo;
                console.log(`setRoomSetting worked data : ${JSON.stringify(data)}`)
                // this.currentRoom.OwnerInfo = newRoomInfo["OWNERINFO"];
                if (this.currentRoom != null && this.currentRoom.Info != null) {
                    if (this.currentRoom.OwnerInfo == null)
                        this.currentRoom.OwnerInfo = new UserFullInfo();
                    this.currentRoom.OwnerInfo.KullaniciId = data.OWNERINFO.KullaniciId; // this.currentRoom.Info.OWNER;
                    this.currentRoom.OwnerInfo.KullaniciAdi = data.OWNERINFO.KullaniciAdi; // this.currentRoom.Info.OwnerName;
                    this.currentRoom.OwnerInfo.ProfilResmiId = data.OWNERINFO.ProfilResmiId;
                    this.currentRoom.OwnerInfo.RutbeDerece = data.OWNERINFO.RutbeDerece;
                    this.currentRoom.OwnerInfo.RutbeId = data.OWNERINFO.RutbeId;
                    this.currentRoom.OwnerInfo.RutbeAdi = data.OWNERINFO.RutbeAdi;
                }

                this.roomInfoUpdatedSource.next(this.currentRoom);
                //  RoomOwnerInfoHelper.SetRoomOwnerInfo(RoomHelper.CurrentRoomInfo.OWNERINFO, roomId);

                this.setAllRoomInfo();

            })
            .catch(error => {
                console.log(error);
            });

    }

    setDefaultFavoriteButton(roomId: number, roomName?: string) {
        this.getRoomInfoJson(roomId, false)
            // .then((roominfo) => {
            //   return this.getBeforeEnterRoomData(roominfo, false);
            // })
            .then((roomInfo: RoomInfo) => {
                this.indexedDBService.RoomFavoriteButtonListDictionary[roomId] = {
                    'color': roomInfo.BUTTON_FONTCOLOR,
                    'font-family': roomInfo.BUTTON_FONT,
                    'background-color': '#3e3d43',
                    'background-image': (roomInfo.BUTTON_BACK_IMAGE_ID === '00000000-0000-0000-0000-000000000000' ? '' : 'url(' + Constants.veri24MessengerRoomavoriteButtonsUrl + (roomInfo.BUTTON_BACK_IMAGE_ID).toLowerCase() + '.jpg' + ')')
                };

                let favoriteButton = this.indexedDBService.RoomFavoriteButtonList.find(f => f.R === roomId);
                if (favoriteButton !== undefined && favoriteButton !== null) {
                    favoriteButton.C = roomInfo.BUTTON_FONTCOLOR;
                    favoriteButton.I = roomInfo.BUTTON_BACK_IMAGE_ID;
                    favoriteButton.F = roomInfo.BUTTON_FONT;
                    this.indexedDBService.RoomFavoriteButtonList[this.indexedDBService.RoomFavoriteButtonList.findIndex(f => f.R === roomId)] = favoriteButton;

                    this.indexedDBService.UpdateRoomFavoriteButtonList(this.indexedDBService.RoomFavoriteButtonList);

                    this.favoriteRoomUpdatedSource.next([roomId, roomName]);
                }
            });
    }

    setAllRoomInfo(): boolean {
        try {

            if (this.utilService.isNullOrUndefined(this.currentRoom.Info))
                return false;

            if (this.controlRoomIsActive() === false) {

                this.leaveFromRoomActions(this.currentRoom.Info?.ID).then(() => {
                    return this.livekitService.disconnectRoom()
                })
                    .then(() => {
                        this.removeRoomContentDiv();
                    });

                return false;
            }
            return true;
        } catch (error) {
            console.log(error)
        }
    }

    controlRoomIsActive(): boolean {
        if (this.currentRoom.Info?.KIMLER_GIREBILIR === WhoEnterRoomTypes.AuthoritiesOnly && this.indexedDBService.userFullInfo.RutbeId === Constants.UYERUTBEID) {
            this.messagePanelService.ShowPopupMessageWithLocalization("roomOpenOnlyAdmins", 2000);
            return false;
        } else if (this.currentRoom.Info?.KIMLER_GIREBILIR === WhoEnterRoomTypes.UsersOnly && this.indexedDBService.userFullInfo.RutbeId !== Constants.UYERUTBEID) {
            this.messagePanelService.ShowPopupMessageWithLocalization("roomOpenOnlyUsers", 2000);
            return false;
        }
            // else if (this.currentRoom.Info?.BITIS < new Date()) {
            //   this.messagePanelService.ShowPopupMessageWithLocalization("RoomEndDateExired", 2000);
            //   return false;
        // }
        else if (this.currentRoom.Info?.AKTIF === false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("RoomIsPassive", 2000);
            return false;
        }

        return true;
    }

    updateUserPhotoInList(userId: number, imageId: string) {
        try {
            // var user = this.roomUserList.find(f => f.KullaniciId === userId);
            var user = this.getRoomUserFromActiveListByUserId(userId);
            if (this.utilService.isNullOrUndefined(user))
                return;

            user.ProfilResmiId = imageId;

            this.utilService.getProfilePhotoUrl(imageId, false);

            //this.roomUserListService.roomUserUpdatedSource.next(user);
        } catch (error) {
            console.log(error)
        }

    }

    updateUserAllInfo(userFullInfo?: UserFullInfo, userId?: number, rutbeDerece?: number, rutbeName?: string) {
        // var user = this.roomUserList.find(f => f.KullaniciId === (userFullInfo ? userFullInfo.KullaniciId : userId));
        var user = this.getRoomUserFromActiveListByUserId(userFullInfo ? userFullInfo.KullaniciId : userId);
        if (this.utilService.isNullOrUndefined(user))
            return;
        user.RutbeDerece = userFullInfo ? userFullInfo.RutbeDerece : rutbeDerece;
        user.RutbeAdi = userFullInfo ? userFullInfo.RutbeAdi : rutbeName;

        if (this.roomUserListService.userViewInRoom[user.KullaniciId].showJustSecondNick === false) {
            this.roomUserListService.userViewInRoom[user.KullaniciId].rutbeDerece = user.RutbeDerece
            this.roomUserListService.userViewInRoom[user.KullaniciId].rutbeAdi = user.RutbeAdi;
        }

        this.roomUserListService.roomUserUpdatedSource.next(user);

        var friend = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === (userFullInfo ? userFullInfo.KullaniciId : userId));
        if (!this.utilService.isNullOrUndefined(friend)) {
            friend.RutbeDerece = userFullInfo ? userFullInfo.RutbeDerece : rutbeDerece;
            friend.RutbeAdi = userFullInfo ? userFullInfo.RutbeAdi : rutbeName;
            this.indexedDBService.friendsChangedSource.next(friend.KullaniciId);
        }
    }

    updateUserStateInListDynamic() {
        var stateNumber;

        stateNumber = this.indexedDBService.userFullInfo.StateId;
        // send message to room users
        if (this.panelManagerService.roomOpened && this.currentRoom.Info !== null) {
            let operationMessageForUpdateUserStateInList = this.dynamicMethodsService.prepareRoomSettingMessage(
                this.currentRoom.Info?.ID,
                [this.dynamicMethodsSendService.UpdateUserStateInList(this.indexedDBService.userFullInfo.KullaniciId, stateNumber)]
            )
            this.dynamicMethodsService.publishDynUpdateUserStateInListPublish("room:" + this.currentRoom.Info?.ID, operationMessageForUpdateUserStateInList)

        }

        this.dynamicMethodsService.publishDynUpdateUserStateInListFriend(
            this.indexedDBService.userFullInfo.KullaniciId,
            {
                data: JSON.stringify([this.dynamicMethodsSendService.UpdateUserStateInList(this.indexedDBService.userFullInfo.KullaniciId, stateNumber)]),
                userId: this.indexedDBService.userFullInfo.KullaniciId
            }
        )
    }

    updateUserStateInRoomList(userId, stateNumber) {
        var user =this.getRoomUserFromActiveListByUserId(userId);

        if (!this.utilService.isNullOrUndefined(user) && user.StateId !== stateNumber) {
            user.StateId = stateNumber;
            this.roomUserListService.roomUserUpdatedSource.next(user);
        } else
            return;
    }

    getRoomUserFromActiveListByUserId(userId: number) {
        let userListType = this.selectedUserListType;
        let user;
        switch (userListType) {
            case UserListType.VIP:
                if (this.vipUserList.length > 0) {
                    user = this.vipUserList.find(f => f.KullaniciId === userId);
                    break;
                }
            case UserListType.ONLINE:
                if (this.onlineUserList.length > 0) {
                    user = this.onlineUserList.find(f => f.KullaniciId === userId);
                    break;
                }
            case UserListType.REQUEST:
                if (this.handUserList.length > 0) {
                    user = this.handUserList.find(f => f.KullaniciId === userId);
                    break;     
                }
            default:
                 break;
        }
        return user
    }

    SendMessageToFriendForOnlineInfo(stateNumber: number) {
        if (stateNumber != UserStateType.Cevrimdisi) {

            this.dynamicMethodsService.publishDynShowBalloonTipForFriendActions(
                this.indexedDBService.userFullInfo.KullaniciId,
                {
                    userId: this.indexedDBService.userFullInfo.KullaniciId,
                    data: JSON.stringify([
                        this.dynamicMethodsSendService.ShowBalloonTipForFriendActions(
                            this.indexedDBService.userFullInfo.KullaniciId,
                            this.indexedDBService.userFullInfo.KullaniciAdi,
                            this.utilService.getKeyWithTag("PersonOnlineMessage")
                        )
                    ])
                }
            );
        }
    }

    RefreshMyPhotoInRoom(imageId) {
        try {
            if (this.utilService.isNullOrEmtpyObject(this.currentRoom.Info) === true ||
                this.utilService.isNullOrUndefined(this.currentRoom.Info?.ID) === true)
                return;

            // this.chatMessageService.sendRoomSettingMessage(this.currentRoom.Info?.ID,
            //   [this.dynamicMethodsSendService.UpdateUserImageInList(this.indexedDBService.userFullInfo.KullaniciId, imageId)]
            // );
            let operationMessageForUpdateUserImageInList = this.dynamicMethodsService.prepareRoomSettingMessage(
                this.currentRoom.Info?.ID,
                [this.dynamicMethodsSendService.UpdateUserImageInList(this.indexedDBService.userFullInfo.KullaniciId, imageId)]
            )
            this.dynamicMethodsService.publishDynUpdateUserImageInList(this.currentRoom.Info.ID, operationMessageForUpdateUserImageInList)
        } catch (error) {
            console.log(error);
        }
    }

    refreshUserList(userId?: number, user?: UserFullInfo) {
        if (this.currentRoom.Info === null) {
            return
        }
        this.roomUserList = [];
        this.panelManagerService.roomUserListLoading = true;
        this.getRoomUserList(false, this.currentRoom.Info.ID)
            .then(() => {
                this.panelManagerService.roomUserListLoading = false;
            })
            .catch(error => {
                console.log(error);
                this.callErrorTracker(66, error);  //ERRORTRACKER
            });

        if (userId && user) {
            var friend = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === userId);
            if (!this.utilService.isNullOrUndefined(friend)) {
                friend = user;
                this.indexedDBService.friendsChangedSource.next(friend.KullaniciId);
            }
        }
    }

    removeBulkBanMute(banType: number, userRutbe: number) {
        let banName: string = "";
        if (this.panelManagerService.roomPageOpened) {
            switch (banType) {
                case BanMuteKaraListeType.BanOda:
                    banName = "BanOda";
                    if (this.indexedDBService.userFullInfo.BanOdaList.length > 0) {
                        this.indexedDBService.userFullInfo.BanOdaList = [];
                        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
                        this.messagePanelService.ShowPopupMessageWithLocalization("RemovedRoomBan", 2000);
                    }
                    break;
                case BanMuteKaraListeType.BanSite:
                    banName = "BanSite";
                    if (this.indexedDBService.userFullInfo.BanSite) {
                        this.indexedDBService.userFullInfo.BanSite = false;
                        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
                        this.messagePanelService.ShowPopupMessageWithLocalization("RemovedSiteBan", 2000);
                    }
                    break;
                /*case BanMuteKaraListeType.Karaliste:
          banName = "Karaliste";
          if (this.indexedDBService.userFullInfo.Karaliste) {
            this.indexedDBService.userFullInfo.Karaliste = false;
            this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
            this.messagePanelService.ShowPopupMessageWithLocalization("RemovedBlackList", 2000);
          }
          break;*/
                case BanMuteKaraListeType.MuteOda:
                    banName = "MuteOda";
                    if (this.indexedDBService.userFullInfo.MuteOdaList.length > 0) {
                        this.indexedDBService.userFullInfo.MuteOdaList = [];
                        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
                        // this.messagePanelService.ShowPopupMessageWithLocalization("RemovedRoomMute", 2000);
                    }
                    break;
                case BanMuteKaraListeType.MuteSite:
                    banName = "MuteSite";
                    if (this.indexedDBService.userFullInfo.MuteSite) {
                        this.indexedDBService.userFullInfo.MuteSite = false;
                        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
                        // this.messagePanelService.ShowPopupMessageWithLocalization("RemovedSiteMute", 2000);
                    }
                    break;
            }
            //Sorun çıkması halinde kontrol edilecek
            // this.roomUserList.forEach(roomUser => {
            //     if (userRutbe > roomUser.RutbeDerece)
            //         roomUser[banName] = false;
            // })
            // this.roomUserListChangedSource.next();
            this.refreshCurrentUserListPage();
        }
    }

    getRoomAuthorizedUserSlider(endpoint: string): Observable<Blob> {
        return this.http.get(endpoint, {responseType: 'blob'});
    }

    selectedSendingType(messageSendingType: MessageSendingType) {
        if (messageSendingType === MessageSendingType.Normal) {
            this.currentMessageSendingType = MessageSendingType.Normal;
            this.currentMessageChatType = ChatType.SingleChat;
        } else if (messageSendingType === MessageSendingType.GenelHerkes) {
            this.currentMessageSendingType = MessageSendingType.GenelHerkes;
            this.currentMessageChatType = ChatType.RoomActionMessage;
        } else {
            this.currentMessageSendingType = MessageSendingType.GenelAdmin;
            this.currentMessageChatType = ChatType.RoomActionMessage;
        }

        this.selectedSendingMessageTypeSource.next();
    }

    callHeartBeat() {
        const endpoint = `${environment.apiUrl}api/heartbeat`;
        return this.http.post<CheckBanResponse>(endpoint, null).toPromise();
    }


    expandUserList(pageIndex:number){
        this.isClickedPaginationElement = true
        //burada pageIndex * pageSize değerine bakılacak ve bu değerle odadaki toplam kullanıcı sayısı kıyaslanacak 
        if (this.selectedUserListType == UserListType.VIP) {
            //vip kullanıcı listesi
            this.callGetVipUserList(pageIndex)
        } 
        if (this.selectedUserListType == UserListType.ONLINE) {
            //online kullanıcı listesi
            this.callGetOnlineUserList(pageIndex)
        }
        if (this.selectedUserListType == UserListType.REQUEST) {
            //hand list
            this.callGetHandRequestUserList(pageIndex)
        }
    }

    checkUserTypeAndAddUserToList(rutbeDerece:number,userId:number) {
        if(this.deviceService.isMobile && !this.panelManagerService.showedRoomUserList){
            return
        }
        if (rutbeDerece > 0 && this.selectedUserListType == UserListType.VIP ) { //gelen kullanıcı vip kullanıcı ise
            this.checkVipUserListAndAddNewUsers(userId)
        }
        if (this.selectedUserListType == UserListType.ONLINE) {
            this.checkOnlineUserListAndAddNewUsers(userId)
        }

    }

    checkOnlineUserListAndAddNewUsers(userId:number) {
        //en sondaki pagede değilsem ve yeni biri geliyorsa
        //yeni gelen kullanıcının listeye eklenip eklenmeyeceğinin kontrolü
        // if (this.pageIndex != Math.floor(this.roomUserCount / this.pageSize) +1) {
        //     return;
        // }
        // pagede yer varsa ekle 
        if (this.onlineUserList.find(f => f.KullaniciId === userId)) {
            return
        }
        if (this.onlineUserList.length != 0) {
            if (this.onlineUserList.length % this.pageSize == 0) {
                return
            }
        }
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":this.pageIndex
          } 
        this.getRoomOnlineUserlist(body).then((response: UserListData) => {
            if (this.utilService.isNullOrEmtpyObject(response) === false) {
                let roomUser = response.data.find(f => f.KullaniciId === userId)
                if (roomUser) {
                    this.setCurrentRoomUserInfos(roomUser)
                    this.onlineUserList.push(roomUser)
                    this.roomUserListService.roomOnlineUserListAddedSource.next(roomUser)
                }
                if (this.isEmptyOnlineUserList) {
                    this.isEmptyOnlineUserList = false
                }
            }
        });
    }

    checkVipUserListAndAddNewUsers(userId:number) {
        //en sondaki pagede değilsem ve yeni biri geliyorsa
        //yeni gelen kullanıcının listeye eklenip eklenmeyeceğinin kontrolü, vip için farklı bir kontrol gerekecek
        // if (this.pageIndex != Math.floor(this.vipUserCount / this.pageSize) +1) {
        //     return;
        // }
        // pagede yer varsa ekle 
        if (this.vipUserList.find(f => f.KullaniciId === userId)) {
            return
        }
        if (this.vipUserList.length != 0) {
            if (this.vipUserList.length % this.pageSize == 0) {
                return
            }
        }
        let body = {
            "pageSize":this.pageSize,
            "pageIndex":this.pageIndex
          } 
        this.getRoomVipUserlist(body).then((response:VipUserData) => {
            if (this.utilService.isNullOrEmtpyObject(response.data) === false) {
                let roomUser = response.data.find(f => f.KullaniciId === userId)
                if (roomUser) {
                    this.setCurrentRoomUserInfos(roomUser)
                    this.vipUserList.push(roomUser)
                    this.roomUserListService.roomVipUserListAddedSource.next(roomUser)
                }
                if (this.isEmptyVipUserList) {
                    this.isEmptyVipUserList = false
                }
            }
        });
    }

    checkUserTypeAndProcessRemovedUsers(rutbeDerece:number,senderId:number) {
        if(this.deviceService.isMobile && !this.panelManagerService.showedRoomUserList){
            return
        }
        if (rutbeDerece > 0 && this.selectedUserListType == UserListType.VIP ) { //ayrılan kullanıcı vip kullanıcı ise
            this.checkVipUsersAndRemovedUserFromList(senderId)
        }
        if (this.selectedUserListType == UserListType.ONLINE) {
            this.checkOnlineUsersAndRemovedUserFromList(senderId)
        }
    }
    
    checkVipUsersAndRemovedUserFromList(senderId:number){
        //kullanıcı aktif listede yoksa işlem yapmasın
        if (!this.vipUserList.find(f => f.KullaniciId === senderId)) {
            return
        }
        else{
            let userIds:number[] = [];
            userIds.push(senderId)
            this.roomUserListService.roomVipUserListRemovedSource.next(userIds)
            this.vipUserList = this.vipUserList.filter(f => f.KullaniciId !== senderId);
            //çıkan eleman pagedeki son elemansa bir önceki pageye geç
            
            if (this.vipUserList.length == 0) {
                if (this.pageIndex == 1) {
                    this.isEmptyVipUserList = true
                }
                else{
                    let pageIndex = this.pageIndex - 1
                    this.callGetVipUserList(pageIndex);
                }
            }
            //liste boyutu pagesizeden bir küçükse o anki sayfayı yeniden çeksin  
            if (this.vipUserList.length > 0 && (this.pageSize % this.vipUserList.length == 1)) {
                this.refreshCurrentUserListPage();
            }
        }
    }
    checkOnlineUsersAndRemovedUserFromList(senderId:number){
        //kullanıcı aktif listede yoksa işlem yapmasın
        if (!this.onlineUserList.find(f => f.KullaniciId === senderId)) {
            return
        }
        else{
            let userIds:number[] = [];
            userIds.push(senderId)
            this.roomUserListService.roomOnlineUserListRemovedSource.next(userIds)
            this.onlineUserList = this.onlineUserList.filter(f => f.KullaniciId !== senderId);
            //çıkan eleman pagedeki son elemansa bir önceki pageye geç
            if (this.onlineUserList.length == 0) {
                if (this.pageIndex == 1) {
                    this.isEmptyOnlineUserList = true
                }
                else{
                    let pageIndex = this.pageIndex - 1
                    this.callGetOnlineUserList(pageIndex);
                }
            }
            //liste boyutu pagesizeden bir küçükse o anki sayfayı yeniden çeksin  
            if (this.onlineUserList.length > 0 && (this.pageSize % this.onlineUserList.length == 1)) {
                this.refreshCurrentUserListPage();
            }
        }
    }

    refreshCurrentUserListPage(){
        if(this.deviceService.isMobile && !this.panelManagerService.showedRoomUserList){
            return
        }
        let userListType = this.selectedUserListType
        switch (userListType) {
            case UserListType.VIP:
                 this.callGetVipUserList();
                 break;
            case UserListType.ONLINE:
                 this.callGetOnlineUserList();
                 break;
            case UserListType.REQUEST:
                 this.callGetHandRequestUserList();
                 break;     
            default:
                 break;
        }
     }
    natsConnection: NatsConnection;
    js: JetStreamClient;

    async connectToNats(userJwt: string) {
        // Sadece natsConnection yoksa veya kapalıysa bağlantı kur
        if (!this.natsConnection || this.natsConnection.isClosed()) {
            let streamName = `users${environment.customerName}`;
            let channel = streamName+"." + this.indexedDBService.userFullInfo.KullaniciId;

            const reconnect = async () => {
                try {
                    this.natsConnection = await connect({
                        servers: environment.natsUrl,
                        token: userJwt,
                        reconnect: true, // Otomatik yeniden bağlanmayı etkinleştir
                        maxReconnectAttempts: -1, // Sınırsız yeniden bağlanma denemesi
                        reconnectTimeWait: 500 // Yeniden bağlanma girişimleri arasındaki süre (ms),
                    });
                    console.log('NATS bağlantısı başarılı');
                    await this.startConsumer(channel, this.indexedDBService.userFullInfo.KullaniciId);
                } catch (error) {
                    console.error('NATS bağlantı hatası:', error);
                    setTimeout(reconnect, 1000); // 10 saniye sonra yeniden deneyin
                }
            };

            await reconnect();
        }
    }

    async startConsumer(channel: string, userID: number) {
        const sc = StringCodec();
        const js = await this.natsConnection.jetstream();
        const jsm = await this.natsConnection.jetstreamManager();
        // const si= await jsm.streams.add({name:"users", subjects:[channel],storage:StorageType.Memory});
        let streamName = `users${environment.customerName}`;
        let ci;
        // let nano = Nanos
        try {
            ci = await jsm.consumers.info(streamName, userID.toString());
            if (ci == null) {
                // Tüketici mevcut değilse, oluştur
                ci = await jsm.consumers.add(streamName, {
                    filter_subject: channel,
                    name: userID.toString(),
                   // durable_name: userID.toString(),
                    ack_policy: AckPolicy.Explicit,
                    inactive_threshold: nanos(1000*60*60*24)//1 gün  
                });
            }
        } catch (error) {
            ci = await jsm.consumers.add(streamName, {
                name: userID.toString(),
                //durable_name: userID.toString(),
                filter_subject: channel,
                ack_policy: AckPolicy.Explicit,
                inactive_threshold: nanos(1000*60*60*24) 
            });
            console.log('NATS bağlantı hatası:', error);
        }
        const consumer = await js.consumers.get(streamName, userID.toString());
        const iter = await consumer.consume();
        for await (const m of iter) {
            // console.log(`Received message ${m.seq}: ${m.data}`);
            this.processMessage((m));
            m.ack();
        }


    }
    checkEnteredRoomMessageReceivedForCurrentUser(){
        if (this.isUserListDataLoaded) {
            this.isFirstJoiningMessageReceived = true
            this.isUserListDataLoaded = false; 
            return; 
        }
        if (this.isFirstJoiningMessageReceived) {
            this.changeMicrophoneState(false);
            this.getMicInfoJsonFormServerAndDoWork();
            this.callGetOnlineUserList(1,true);
        }    
    }
    
    startCountdown() {
        this.panelManagerService.roomAIChatRemainingTimePopupDisplay = true;
    
        const totalSeconds = this.userAIChatRemainingTime;
        this.remainingHours = Math.floor(totalSeconds / 3600);
        this.remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
        this.remainingSeconds = totalSeconds % 60;
    
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    
        this.countdownInterval = setInterval(() => {
            if (this.remainingHours === 0 && this.remainingMinutes === 0 && this.remainingSeconds === 0) {
                this.panelManagerService.roomAIChatRemainingTimePopupDisplay = false;
                clearInterval(this.countdownInterval);
                this.leaveFromRoomAfterAIChatLimitFinished();
            } else {
                if (this.remainingSeconds > 0) {
                    this.remainingSeconds -= 1;
                } else if (this.remainingSeconds === 0) {
                    if (this.remainingMinutes > 0) {
                        this.remainingMinutes -= 1;
                        this.remainingSeconds = 59;
                    } else if (this.remainingMinutes === 0 && this.remainingHours > 0) {
                        this.remainingHours -= 1;
                        this.remainingMinutes = 59;
                        this.remainingSeconds = 59;
                    }
                }
            }
        }, 1000);
    }

    processMessage(message) {
        let data = JSON.parse(this.sc.decode(message.data));
        // Burada data ile ilgili işlemleri yapabilirsiniz.
        // Örnek olarak bir kontrol ve metod çağrısı:
        if (data.RtfText === '<LANG>EnteredRoom</LANG>') {
            if (this.currentRoom.Info != null && this.currentRoom.Info.ID == data.RoomId) {
                if (data.UserCount != undefined) {
                    this.roomUserCount = data.UserCount
                }
                // liste kontrolü
                //yeni listeyi çek ve farklı olanları listeye ekle.
                if (data.SenderId !== this.indexedDBService.userFullInfo.KullaniciId) {
                    this.checkUserTypeAndAddUserToList(data.RutbeDerece,data.SenderId);
                }
                if (data.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
                   this.checkEnteredRoomMessageReceivedForCurrentUser()
                   this.isCurentUserJoinedMessageReceived = true
                   if (data.RemainingTime != undefined && this.environmentVariable.IsAiActive) {
                    this.userAIChatRemainingTime = data.RemainingTime
                    this.startCountdown() 
                    }
                }
               
            }
        } else if (data.RtfText === '<LANG>LeavedRoom</LANG>') {
            if (this.currentRoom.Info != null && this.currentRoom.Info.ID == data.RoomId) {
                //yeni listeyle eskisini kontol edip farklı olanları çıkar
                // this.getMicInfoJsonFormServerAndDoWork();
                // this.refreshUserList();
                if (data.UserCount != undefined) {
                    this.roomUserCount = data.UserCount
                }
                this.checkUserTypeAndProcessRemovedUsers(data.RutbeDerece,data.SenderId)
            }
        } else if (data.RtfText === '<LANG>Logout</LANG>') {
            if (this.currentRoom.Info != null && this.currentRoom.Info.ID == data.RoomId) {
                 this.getMicInfoJsonFormServerAndDoWork();
                // this.refreshUserList();
                if (data.UserCount != undefined ) {
                    this.roomUserCount = data.UserCount
                }
                this.checkUserTypeAndProcessRemovedUsers(data.RutbeDerece,data.SenderId)
            }
        } else if (JSON.stringify(data).startsWith('"[') && JSON.stringify(data).endsWith(']"')) {
            var methodArray = JSON.parse(data)
            for (let index = 0; index < methodArray.length; index++) {
                const mthd = methodArray[index];
                if (mthd.MethodName === 'UpdateUserStateInList') {
                    this.updateUserStateInRoomList(mthd.Parameters[0], mthd.Parameters[1])
                }
            }
            // message.ack();
            return
        } else if (Array.isArray(data) === false && ("M" in data) && ("M2" in data) && ("H" in data)) {
            if (this.currentRoom.Info != null && this.currentRoom.Info.ID.toString() == data.R) {
                this.doWorkWithMicInfo(data);
            }
            // message.ack();
            return
        } else if (data.MethodName === 'leaveFromRoomActions') {
            this.oldmicrophoneJsonStruct = new MicrophoneJson();
            this.leaveFromRoomActions(null).then(() => {
                return this.livekitService.disconnectRoom()
            });
            //message.ack();
            return
        }

        if (data.MethodNames !== undefined && data.MethodNames !== null) {
            if (data.MethodNames.indexOf('RefreshRoomUserListForAllUsersInRoom') > -1) {
                //another way for refresh
                // this.refreshUserList();
                this.refreshCurrentUserListPage()
            }

            let operationMessage = data as OperationMessage;
            this.natsService.dynamicMethodsReceivedSource.next(operationMessage);
        } else {
            let chatMessage = data as ChatMessage;
            chatMessage.Time = new Date(chatMessage.Time); // serverda time setlendiğinde string olarak gelecek
            if (data.MessageBubbleType === 'PtoPMe') {
                console.log(`PtoPMe worked`)
                this.chatService.textChatOnReceivePtoP(Object.assign({}, chatMessage));
                //message.ack();
                return
            } else if (data.RtfText !== '<LANG>Logout</LANG>') {
                message.ack();
                this.natsService.roomMessagesReceivedSource.next(chatMessage);
            }
        }
    }


}
